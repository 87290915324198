import {
  ChainFactoryConfigs,
  ChainFactory,
  Chain,
  AppConfigs,
  Web3Helper,
  web3HelperFactory,
  Web3Params,
} from "xp.network";
import contracts from "../../lib/contracts";
import { getChain } from "../../utils/handleschains";
import { GET } from "../../utils/axios.http";
import { RootState, store } from "../../store/store";
import { setSelectedCharacter } from "../../store/slices/nftCharacter.slice";
import { Web3Service } from "../../services/web3.service";
import { ApiService } from "../../services/apis.service";
import { ContractsEnum, RaritiesEnum } from "../../utils/enums";
import { Chest } from "../../utils/types";
import { chest1, chest2, chest3 } from "../../services/assets.service";
import { typeToUrlMap } from "../../lib/MainNetTestNet/values";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import { useSelector } from "react-redux";
import _ from "lodash";
import { setOpenLoader } from "../../store/slices/general.slice";
import { toast } from "react-toastify";
import { dark } from "@mui/material/styles/createPalette";
import { error } from "console";
import { usegetUserCharacters } from "../Homepage/utils/usegetUserCharacters";
import { resolve } from "path";

const web3Service = new Web3Service();
function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}
export function useGMTTime(date = new Date()) {
  return [
    padTo2Digits(date.getUTCHours()),
    padTo2Digits(date.getUTCMinutes()),
    padTo2Digits(date.getUTCSeconds()),
  ].join(":");
}

export { padTo2Digits };
/**
 * Transfer Wrapped NFT From Current Chain To Original Chain
 * @param walletAddress
 * @param currentchain
 * @param tokenId 
 */
export const StopQuest = async ({
  walletAddress,
  _toChain,
  nftTokenId,
  startHash,
}: {
  walletAddress: string;
  _toChain: string;
  nftTokenId: string;
  startHash: string;
}) => {
  store.dispatch(setOpenLoader(true));
  try {
    const listOfWrappedNfts = store.getState().nftCharacter.listOfWrappedNfts;
    await new Promise((resolve) => setTimeout(resolve, 3000));
    const usercharacters: number[] = await usegetUserCharacters(walletAddress);
    var tonounce: number;
    await getChain(_toChain).then(async () => {
      for (let i = 0; i < contracts.length; i++) {
        if (contracts[i].NAME === _toChain) {
          tonounce = contracts[i].NOUNCE;
        }
      }
      const ethers = require("ethers");
      const mainnetConfig = await ChainFactoryConfigs.TestNet();
      const mainnetFactory = ChainFactory(AppConfigs.TestNet(), mainnetConfig);
      const signer = new ethers.providers.Web3Provider(
        window.ethereum,
      ).getSigner();
      async function Chaininner(_chain: string) {
        if (_chain === "POLYGON") {
          return await mainnetFactory.inner(Chain.POLYGON);
        }
      }
      console.log((Chain as any)[_toChain], "Chain is")
      const mychain: any = await mainnetFactory.inner((Chain as any)[_toChain]);

      let originalCain = Number(_.find(listOfWrappedNfts, { Token: nftTokenId })?.Original_chain) ?? 2;
      const chainId = _.find(Chain, (value) => value == originalCain) ?? 4;
      const destinationChain: any = await mainnetFactory.inner(chainId)
      if (mychain) {
        const characters: any = await mainnetFactory.nftList(mychain, walletAddress);
        let selected: number;
        console.log("characters are : ", characters);
        console.log("Token id : ", nftTokenId);
        for (const element of characters) {
          if (element.native && element.native.uri.includes("herokuapp") && element.native.tokenId.toString() === nftTokenId.toString()) {
            selected = characters.indexOf(element);
          }
        }
        const selectedCharacter = characters[selected!];
        console.log("Selected Character", selectedCharacter);
        if (selectedCharacter == null || selectedCharacter == undefined) {
          toast("Destination chain is not yet Updated! Retry manually ", { theme: "dark" });
        }
        const unfreeze = await mainnetFactory.isWrappedNft(selectedCharacter, tonounce);
        if (!!unfreeze) {
          await GET(selectedCharacter.native.uri).then(async (result) => {
            console.log("result : ", result);
            if (result.wrapped.source_token_id === nftTokenId.toString()) {
              console.log("before fee", nftTokenId, "Source", mychain, "destination", destinationChain, "selected", selectedCharacter);
              const web3Result = await mainnetFactory.transferNft(
                mychain, // The Source   Chain.
                destinationChain, // The Destination Chain.
                selectedCharacter, // The NFT object
                signer as any,
                walletAddress,
                .5,
                // undefined,
                //"0x95cb12e452c65b4f6c4af7628b0c08e7dec696d7",
                //undefined,
                undefined,
                undefined,
                5_000_000,
              ).then((response: any) => {
                // console.log(response);
                toast("Successfully Claimed!", { theme: "dark" });
                store.dispatch(setOpenLoader(false));
              }).catch((error) => {
                console.log(error)
              });
              console.log("Tried Unfreeze");
              return;
              console.log(web3Result);
              const fee = await mychain.estimateValidateUnfreezeNft(
                walletAddress,
                selectedCharacter,
                "",
              );
              console.log("Fee is : ", fee);
              mychain.approveForMinter(selectedCharacter, signer, fee)
                .then((result: any) => {
                  mychain
                    .unfreezeWrappedNft(
                      signer,
                      walletAddress,
                      selectedCharacter,
                      fee,
                      4,
                      undefined,
                      undefined,
                    )
                    .catch((e: any) => console.log("error in unfreeze", e))
                    .then(async () => {
                      // await ApiService.QuestsApi.end({
                      //   nftTokenId,
                      //   walletAddress,
                      //   startHash,
                      // });
                    }).then((response: any) => {
                      console.log({ response });
                    });
                })
                .catch((error: any) => {
                  //console.log("error in  stop quest helper", error);
                });
            }
          });
        }
      }
    });
  } catch (error) {
    toast("Error in Get back Character", { theme: "dark" });
    store.dispatch(setOpenLoader(false));
    return;
  }
};

export const startQuest = async ({
  walletAddress,
  nftTokenId,
  toChain,
  requiredTime,
}: {
  walletAddress: string;
  nftTokenId: string;
  toChain: string;
  requiredTime: string;
}) => {
  // const currentchain = await web3Service.getChainId({ initializer: "https://data-seed-prebsc-1-s1.binance.org:8545" });
  const provider = new Web3(window.ethereum);
  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  const currentchain = await provider.eth.getChainId();
  return await new Promise(async (resolve, reject) => {
    //console.log({ currentchain });
    if (Number(currentchain) == 97) {
      const result = await start();
      resolve(result);
    } else if (Number(currentchain) != 97) {
      getChain("BSC").then(async () => {
        const result = await start();
        resolve(result);
      });
    }
  })
  async function start() {
    store.dispatch(setOpenLoader(true));
    try {
      var selected: number;
      const mainnetConfig = await ChainFactoryConfigs.TestNet();
      //const mainnetConfig = await ChainFactoryConfigs.TestNet();

      const mainnetFactory = ChainFactory(AppConfigs.TestNet(), mainnetConfig);

      const ethers = require("ethers");
      const signer = new ethers.providers.Web3Provider(
        window.ethereum,
      ).getSigner();
      const contract = await ApiService.ContractsApi.getOne({
        forWhat: ContractsEnum.CHARACTERS,
      });
      //console.log("CHAIN", Chain.BSC);
      const bsc: any = await mainnetFactory.inner(Chain.BSC);
      //console.log("bsc", bsc);
      const characters: any = await mainnetFactory.nftList(bsc, walletAddress);
      for (const element of await characters) {
        if (
          element.native.tokenId === nftTokenId
          // &&
          // element.collectionIdent === contract?.contract.CONTRACT
        ) {
          selected = characters.indexOf(element);
        }
      }

      const selectedCharacter = characters[selected!];
      //console.log(nftTokenId);
      //console.log({ characters });
      //console.log({ selectedCharacter });
      const fee = await bsc.estimateValidateTransferNft(
        walletAddress,
        selectedCharacter,
        "",
      );
      //console.log("fee is " + fee);
      const isApprovedEVM = await bsc.approveForMinter(
        selectedCharacter,
        signer as any,
        fee,
      );
      //console.log("Is approved for minter " + isApprovedEVM);
      const chain: any = await mainnetFactory.inner((Chain as any)[toChain]);
      let r: any;
      // const feeInGwei = Web3.utils.fromWei(800, '')
      console.log({ chain })
      const fee2 = new BigNumber("1");
      //console.log("before transfer", fee2);
      const web3Result = await mainnetFactory
        .transferNft(
          bsc, // The Source   Chain.
          chain, // The Destination Chain.
          selectedCharacter, // The NFT object
          signer as any,
          walletAddress,
          fee,
          // undefined,
          //"0x95cb12e452c65b4f6c4af7628b0c08e7dec696d7",
          //undefined,
          undefined,
          undefined,
          5_000_000,
        ).then(async (response: any) => {
          store.dispatch(setOpenLoader(false));
          toast("Character Transfered Successsfully !", { theme: "dark" })
          // console.log("transfer");
          //console.log(response);
          // (await ChainFactoryConfigs.TestNet()).polygonParams?.notifier.notifyWeb3(bsc, response.hash);
          // const notifier_1 = require("../services/notifier");
          // await notifier_1.notifyWeb3("https://testnet-notifier.xp.network/notify-test/");
          // const savetodb = await fetch(env.HOST + "/quests/start/"+selectednft+"?walletaddress="+_walletaddress+"&starthash="+p.hash+"&tochain="+toChain.toUpperCase()+"&requiredtime="+timerequired);
          // await ApiService.QuestsApi.start({
          //   nftTokenId,
          //   walletAddress,
          //   startHash: response?.hash,
          //   toChain: toChain.toUpperCase(),
          //   requiredTime,
          // }).then((response) => {
          //   //console.log({ response });
          //   r = response;
          // });
          // store.dispatch(setSelectedCharacter(+""));
          r = 1;
        })
        .catch((error) => {
          console.log(error)
          store.dispatch(setOpenLoader(false));
        })
        .finally(() => {
          //console.log("fin");
        });
      return r;
    } catch (error) {
      console.log(error);
      toast("Error in Character Transfer !", { theme: "dark" })
      store.dispatch(setOpenLoader(false));
    }
  }

};

export const filterChests = ({
  chests,
  rarity,
}: {
  chests: Array<Chest>;
  rarity: RaritiesEnum;
}): Array<Chest> => {
  return chests.filter((element) => element.rarity === rarity);
};

export const imageAssigner = (rarity: RaritiesEnum) => {
  switch (rarity) {
    case RaritiesEnum.common: {
      return chest1;
    }
    case RaritiesEnum.epic: {
      return chest2;
    }
    case RaritiesEnum.legendary: {
      return chest3;
    }
    case RaritiesEnum.rare: {
      return chest1;
    }
    case RaritiesEnum.unique: {
      return chest1;
    }
  }
};
