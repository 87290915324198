interface envtype {
  HOST: string;
}

export const local: envtype = {
  // HOST: "https://rush-testnet.herokuapp.com",
  // HOST: "http://138.201.202.51:8000"
  // HOST: "http://192.168.18.199:8000"
  //HOST: "http://localhost:8000",
  HOST: "https://tools.xp.network/game",
};
