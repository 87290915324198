import { Web3Service } from "../services/web3.service";
import { ChainIdEnum } from "./enums";
declare global {
  interface Window {
    ethereum: any;
  }
}

//TEST NET START

export const getChain = async (_chain: string) => {
  const web3Service = new Web3Service();
  if (_chain === "BSC") {
    let chainIdHex: string = web3Service.toHex(ChainIdEnum.BSC);
    await window.ethereum.request({
      id: 1,
      jsonrpc: "2.0",
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: chainIdHex,
          chainName: "Smart Chain - Testnet",
          nativeCurrency: { name: "tBNB", symbol: "tBNB", decimals: 18 },
          rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
          blockExplorerUrls: ["https://testnet.bscscan.com"],
        },
      ],
    });
  } else if (_chain === "POLYGON") {
    let chainIdHex: string = web3Service.toHex(ChainIdEnum.POLYGON);
    await window.ethereum.request({
      id: 1,
      jsonrpc: "2.0",
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: chainIdHex,
          chainName: "Mumbai Testnet",
          nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
          rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
          blockExplorerUrls: ["https://polygonscan.com/"],
        },
      ],
    });
  } else if (_chain === "FANTOM") {
    let chainIdHex: string = web3Service.toHex(ChainIdEnum.FANTOM);
    await window.ethereum.request({
      id: 1,
      jsonrpc: "2.0",
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: chainIdHex,
          chainName: "Fantom testnet",
          nativeCurrency: { name: "FTM", symbol: "FTM", decimals: 18 },
          rpcUrls: ["https://rpc.testnet.fantom.network/"],
          blockExplorerUrls: ["https://testnet.ftmscan.com/"],
        },
      ],
    });
  } else if (_chain === "AVALANCHE") {
    let chainIdHex: string = web3Service.toHex(ChainIdEnum.AVALANCHE);
    await window.ethereum.request({
      id: 1,
      jsonrpc: "2.0",
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: chainIdHex,
          chainName: "Fuji (C-Chain)",
          nativeCurrency: { name: "AVAX", symbol: "AVAX", decimals: 18 },
          rpcUrls: ["https://api.avax-test.network/ext/C/rpc"],
          blockExplorerUrls: ["https://testnet.snowtrace.io"],
        },
      ],
    });
  }
};
