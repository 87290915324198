import React, { useState } from "react";
import "./MintItemOptionsModal.css";
import Dropdown from "react-bootstrap/Dropdown";
import { RootState } from "../../store/store";
import { mintNewSword } from "../../pages/Inventory/mintNewSword";
import { ContractsEnum, ItemsEnum, RaritiesEnum } from "../../utils/enums";
import { cross } from "../../services/assets.service";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { ApiService } from "../../services/apis.service";
import { useDispatch } from "react-redux";
import { setDataRefetch, setOpenLoader } from "../../store/slices/general.slice";
import { toast } from "react-toastify";
import { getXPNETTokensBalance } from "../../utils/API";
import Box from "@mui/material/Box";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
type Props = {
  showMintOptions: any;
};
export default function MintItemOptionsModal({ showMintOptions }: Props) {
  const dispatch = useDispatch();
  const address = useSelector((state: RootState) => state.user.address);
  const selectedCharacter = useSelector(
    (state: RootState) => state.nftCharacter.selectedCharacter,
  );
  const [item, SetItem] = useState<string | null>(null);
  const [rarity, SetRarity] = useState<string>();
  const [requiredXpnets, SetRequiredXpnets] = useState(0);
  const options: Array<ItemsEnum> = Object.values(ItemsEnum);
  const SelectItem = async (item: string) => {
    if (item == null || item == undefined) {
      return;
    }
    await SetItem(item);
    //Show Message
    //console.log("You have selected ", item, " For Minting");
  };
  const StartMint = async () => {
    const selectdItem = item ? item : "sword";
    const selectedRarity = rarity ? rarity : "common";
    dispatch(setOpenLoader(true));
    const contract = await ApiService.ContractsApi.getOne({
      forWhat: ContractsEnum.CHARACTERS,
    });
    if (contract == null || contract == undefined) {
      dispatch(setOpenLoader(false));
      toast("Unable to find Items Contract!", { theme: "dark" });
      return;
    }
    if (!(await CheckMintPrereqs(contract, selectdItem))) {
      //Show Message Not Have Enough Xpnets
      dispatch(setOpenLoader(false));
      toast("Unable to get required data!", { theme: "dark" });
      return;
    }
    //console.log("Requirements Met Starting Mint be With Us");
    try {
      const possessedXpnets = await getXPNETTokensBalance(address);
      if (possessedXpnets >= requiredXpnets) {
        toast.success("Fulfilled all requirements! Staring Mint", { theme: "dark" });
        if (selectdItem.toLowerCase() == "special") {
          //const response = await mintSpecial();
        } else {
          const response = await mintNewSword(address, selectdItem, selectedRarity);
        }
      }
      else {
        dispatch(setOpenLoader(false));
        toast("You dont have enough xpnets", { theme: "dark" });
      }
      //console.log("Sword mint response ", response);
      //if (!(await ChargeMintCost(contract))) {
      //Show Message
      //console.log("cost Not charged");
      //}
    } catch (error) {
      console.log(error);
    }
    dispatch(setDataRefetch());
    dispatch(setOpenLoader(false));
  };
  const CheckMintPrereqs = async (contract: any, selectedItem: string) => {
    if (selectedCharacter == null || selectedCharacter == undefined) {
      return;
    }
    const reqs = await ApiService.CharactersApi.mintItemPrereqs({
      characterId:
        String(contract?.contract?.CONTRACT) + String(selectedCharacter),
      // owner: address,
      itemToMint: selectedItem,
    });
    //console.log(reqs.data);
    if (reqs.data) {
      await SetRequiredXpnets(reqs.data.requiredXpnets);
      await SetRarity(reqs.data.rarity);
      //console.log(reqs.data.rarity);
      return true;
    } else {
      //console.log("Doesnot met Requirements, stoping mint");
      return false;
    }
  };
  //const ChargeMintCost = async (contract: any) => {
  //Show Message
  // if (requiredXpnets == 0) {
  //   return;
  // }
  // const status = await ApiService.CharactersApi.chargeMintCost({
  //   characterId:
  //     String(contract?.contract?.CONTRACT) + String(selectedCharacter),
  //   // owner: address,
  //   rarity: rarity,
  //   xpnet: requiredXpnets,
  // });
  // //console.log(status);
  // if (status.data.success == true) {
  //   return true;
  // } else {
  //   return false;
  // }
  //};
  return (
    <>
      <div className="background d-flex container-fluid justify-content-center align-items-start">
        <div className="card d-flex text-white bg-dark ">
          <div className="d-flex flex-grow-1 justify-content-end mb-3">
            <img
              src={cross}
              width={25}
              height={25}
              alt=""
              className="cursor-pointer"
              // onClick={() => setShow(false)}
              onClick={() => {
                showMintOptions(false);
              }}
            />
          </div>
          <div className="card-title d-flex">
            <h4>Specify Mint Options</h4>
          </div>
          <div className=" card-body d-flex  flex-column gap-5 ">
            <Dropdown>
              <Dropdown.Toggle
                className="dropdownButton d-flex flex-grow-1 align-items-center mx-auto w-100 justify-content-center"
                id="dropdown-basic"
              >
                {(!item) ? "Select Item" : item}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {options.map((option: ItemsEnum) => (
                  <Dropdown.Item>
                    <div className="optionButton">
                      <button
                        className="fs-5 w-100 h-100"
                        key={option}
                        onClick={() => SelectItem(option)}
                      >
                        {option}{" "}
                      </button>
                    </div>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

          </div>
          <div className="questButton" onClick={() => StartMint()}>
            <button>Start Mint</button>
          </div>
        </div>
      </div>
    </>
  );
}
