import { url } from "inspector";
import { local } from "../lib/env";
import { ApiUrls, AxiosService } from "../services/axios.service";
import { GET, PATCH } from "../utils/axios.http";
class ChestsApiClient extends AxiosService {
  constructor() {
    super({ url: ApiUrls.Chests });
  }

  async drop(params: any): Promise<any> {
    return await PATCH(local.HOST + "/" + ApiUrls.Chests + "/drop", params);
  }

  async open(params: any): Promise<any> {
    return await PATCH(local.HOST + "/" + ApiUrls.Chests + "/open", params);
  }

  async getXpNets(params: any): Promise<any> {
    return await GET(local.HOST + "/" + ApiUrls.Chests + "/gexpnets", params);
  }

  async xpNetClaimed(params: any): Promise<any> {
    return await GET(local.HOST + "/" + ApiUrls.Chests + "/xpnetclaimed", params);
  }
}

const ChestsApi = new ChestsApiClient();
export { ChestsApi };
