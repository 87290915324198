import { PerksApi } from "../apis/perks.api";
import { QuestsApi } from "../apis/quests.api";
import { CharactersApi } from "../apis/characters.api";
import { InventoryApi } from "../apis/inventory.api";
import { EquipmentsApi } from "../apis/equipments.api";
import { FaqsApi } from "../apis/faqs.api";
import { ChestsApi } from "../apis/chests.api";
import { ContractsApi } from "../apis/contracts.api";

const ApiService = {
  PerksApi,
  QuestsApi,
  CharactersApi,
  InventoryApi,
  EquipmentsApi,
  FaqsApi,
  ChestsApi,
  ContractsApi,
};

export { ApiService };
