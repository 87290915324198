import React from "react";
import "./CharacterTile.css";
import { ProgressBar } from "react-bootstrap";
import { MintNFT, chfram, chsel, expIcon } from "../../services/assets.service";
import { PiEggCrackLight } from "react-icons/pi";
type Props = {
  handleClick: any;
  character: any;
  selectedImage: any;
};

export default function CharacterTile({
  handleClick,
  character,
  selectedImage,
}: Props) {
  let percentage = 63;
  return (
    <div
      key={character}
      className="image-wrapper-home d-flex flex-row justify-content-center align-items-center"
      style={{
        backgroundImage:
          selectedImage === character ? `url(${chsel})` : `url(${chfram})`,
      }}
    >
      <div className="image-container d-flex flex-row justify-content-center m-0 p-0">
        <img
          src={`https://nftgame-characters.s3.amazonaws.com/characters-images/${character}.png`}
          alt="characterimg"
          placeholder={MintNFT}
          onClick={() => handleClick(character)}
          className="char-image"
        />
        <div className="progressBarConH d-flex h-100 flex-column justify-content-end w-100 px-1">
          <div className="d-flex flex-row gap-2 w-100">
            <div className="d-flex flex-row justify-content-center w-25">
              <img
                src={expIcon}
                alt=""
                width={"100%"}
                height={"100%"}
                style={{ width: "100%" }}
                onError={(e: any) => {
                  //console.log("error");
                  e.target.src = <PiEggCrackLight size={40} color="darkgray" />;
                }}
              />
            </div>
            <div className="d-flex flex-row align-items-center w-75">
              <div className="progressBarH w-100">
                <ProgressBar now={percentage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
