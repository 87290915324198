import React, { PropsWithChildren, useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import "./Dropdown.css";
import { Collapse } from "react-bootstrap";

type Props = {
  title: string;
};

export default function Dropdown({
  title,
  children,
}: Props & PropsWithChildren) {
  const [flipped, setFlipped] = useState<boolean>(false);

  const handleClick = () => {
    flipped ? setFlipped(false) : setFlipped(true);
  };
  return (
    <div className="cursor-pointer">
      <div
        onClick={handleClick}
        className={`d-flex flex-row justify-content-between align-items-center`}
      >
        <h4 className="myfont-white">{title}</h4>
        <div className={`${flipped ? "flip-up" : "flip-down"}`}>
          <RiArrowDropDownLine color="white" size={40} />
        </div>
      </div>
      {flipped ? (
        <Collapse in={flipped}>
          <>{children}</>
        </Collapse>
      ) : null}
    </div>
  );
}
