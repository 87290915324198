import React, { useEffect, useState } from "react";
import { Navbar } from "../../components/Navbar/Navbar";
import { Question } from "./Question/Question";
import { Faq } from "../../utils/types";
import { ApiService } from "../../services/apis.service";
import "./FAQ.css";

export const FAQ: React.FC = () => {
  const [questions, setQuestions] = useState<Array<Faq>>([]);

  useEffect(() => {
    ApiService.FaqsApi.getList({}).then((response) => {
      //console.log({ response });
      setQuestions(response);
    });
  }, []);
  return (
    <div className="FAQPage">
      <Navbar />
      <div className="faqMain d-flex flex-column flex-grow-1">
        <div className="d-flex my-5 justify-content-center align-items-center w-100">
          <h1 className="text-center myfont-white">FAQs</h1>
        </div>

        <div className="d-flex flex-column gap-4 container px-5 align-items-start questions">
          {questions?.map((element, index) => {
            return (
              <div key={index} className="question w-100">
                <Question question={element} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
