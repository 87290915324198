import { Chain, ChainFactory } from "xp.network";
import { ChainFactoryConfig } from "../lib/MainNetTestNet/configs";
import { ChainIdEnum } from "../utils/enums";

const getNftList = async ({
  walletAddress,
  filterString,
  chain,
}: {
  walletAddress: string;
  chain: any;
  filterString?: "sword" | "armor" | "head";
}): Promise<Array<any>> => {
  // const mainnetFactory = await ChainFactoryConfig();
  let nfts: Array<any> = [];
  try {
    const mainnetFactory = await ChainFactoryConfig();
    const bsc = await mainnetFactory.inner(chain);
    nfts = await mainnetFactory.nftList(bsc, walletAddress);
    //console.log("nfts: ", await nfts);
    if (await nfts) {
      if (filterString) {
        // let filteredNfts: Array<any> = []
        // for (const element of await nfts) {
        //     //console.log("element.uri: ", element.uri);
        //     // const response = await GET(element.uri);
        //     // const response = (await axios.get(element.uri)).data
        //     const response = await mainnetFactory.isWrappedNft(element, 7)
        //     //console.log('element uri response', response)
        //     if (await response) {
        //         //console.log("wrappeduri: ", await response?.wrapped);
        //         if (
        //             response?.wrapped?.original_uri?.includes(filterString)
        //         ) {
        //             filteredNfts.push(element)
        //             // const theorignaljson = await GET(
        //             //     response?.wrapped?.original_uri
        //             // );
        //             // //console.log({ theorignaljson });
        //             // if (filterString) {
        //             //     nfts = nfts.filter((element) => element.wrapped.includes(filterString))
        //             //     //console.log('filtered nfts-> ', await nfts)

        //             // }
        //         }
        //     }
        // }
        // //console.log({ filteredNfts })
        // //console.log({ nfts })
        // nfts = filteredNfts
        ////console.log({ nfts });
        // nfts = await getFilteredMintedItems(nfts, filterString)
      }
    }
  } catch (error) {
    //console.log("error in get nfts");
  } finally {
    ////console.log("nfts-> ", await nfts);
    return await nfts;
  }
};
const getFilteredMintedItems = async (
  nfts: Array<any>,
  filterString: "sword" | "armor" | "head" | "herokuapp",
): Promise<Array<any>> => {
  let filteredNfts: Array<any> = [];

  try {
    const mainnetFactory = await ChainFactoryConfig();
    for (const element of await nfts) {
      //console.log("element.uri: ", element.uri);
      const response = await mainnetFactory.isWrappedNft(element, 7);
      // //console.log('element uri response', response)
      if (await response) {
        //console.log("wrappeduri: ", await response?.wrapped);
        if (response?.wrapped?.original_uri) {
          filteredNfts.push(element);
        }
      }
    }
  } catch (error) {
    //console.log(error);
  } finally {
    return await filteredNfts;
  }
};

// const filterArray = async (
//     array: Array<any>,
//     mainnetFactory: ChainFactory
// ): Promise<Array<any>> => {
//     let filteredArray: Array<any> = [];
//     for (const element of array) {
//         const resp = await mainnetFactory.isWrappedNft(element, 7);
//         if (resp.wrapped) {
//             filteredArray.push(element);
//         }
//     }
//     return await filteredArray;
// };
const BridgeService = {
  getNftList,
  getFilteredMintedItems,
};

export { BridgeService };
