enum RaritiesEnum {
  common = "common",
  rare = "rare",
  epic = "epic",
  legendary = "legendary",
  unique = "unique",
}

enum ItemsEnum {
  sword = "sword",
  shield = "shield",
  armor = "armor",
  helmet = "helmet",
  legging = "legging",
  amulet = "amulet",
  glove = "glove",
  special = "special"
}

enum ChainIdEnum {
  BSC = 97,
  POLYGON = 80001,
  FANTOM = 4002,
  AVALANCHE = 43113,
}

enum ContractsEnum {
  ARMORS = "ARMORS",
  CHARACTERS = "CHARACTERS",
  SWORDS = "SWORDS",
  SHIELD = "SHIELD",
  ARMOR = "ARMOR",
  HELMETS = "HELMETS",
  LEGGING = "LEGGING",
  AMULET = "AMULET",
  GLOVE = "GLOVE",
}

export { RaritiesEnum, ItemsEnum, ChainIdEnum, ContractsEnum };
