import React from "react";
import "./Timer.css";
import { questCardAssets } from "../../../../services/assets.service";

type Props = {
  time: number;
};

export default function Timer({ time }: Props) {
  return (
    <div
      className="d-flex flex-row justify-content-center align-items-center w-100 my-auto"
      style={{ height: `100%` }}
    >
      <div className="timer d-flex flex-column justify-content-center align-items-center">
        <img src={questCardAssets.timerIcon} alt="" />
        <p>{time}</p>
      </div>
    </div>
  );
}
