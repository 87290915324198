import { GET, PATCH } from "./axios.http";
import { local as env } from "../lib/env";
import {
  CharacterQuests,
  MYCONTRACT,
  CONTRACTS,
  UserQuest,
  Item,
  TransactionConfig,
  requiredtoken,
} from "./types";
import { getChain } from "./handleschains";
import { Web3Service } from "../services/web3.service";
import { Contracts, Urls } from "../services/contstants.service";
import { Abis } from "../services/abis.service";
import { ApiService } from "../services/apis.service";
import { ChainIdEnum, ContractsEnum } from "./enums";
import { Transaction } from "web3";
// import { Contract } from "web3";

const web3Service = new Web3Service();

//VERIFY USER FOR LOGIN -- START
export async function verification(
  walletAddress: string,
  signedHash: string,
): Promise<boolean> {
  return await GET(env.HOST + "/users/verify", {
    walletaddress: walletAddress,
    signedHash: signedHash,
  });
}

//VERIFY USER FOR LOGIN -- END
//GET ALL CONTRACTS
export async function getAllContracts(): Promise<MYCONTRACT[]> {
  return await GET(env.HOST + "/contracts/list");
}
export async function getItemMetaData(
  itemtype: string,
  itemtoken: number,
): Promise<Item> {
  return await GET(
    "https://nftgame-characters.s3.amazonaws.com/" + itemtype + itemtoken,
  );
}
//Get Contract Details From Backend -- START
export async function getHelmetContract(): Promise<CONTRACTS> {
  return await GET(env.HOST + "/contracts/get/", {
    forWhat: "HELMETS",
  });
}
export async function getArmorsContract(): Promise<CONTRACTS> {
  return await ApiService.ContractsApi.getOne({
    forWhat: ContractsEnum.ARMORS,
  });
}
export async function getSwordsContract(): Promise<CONTRACTS> {
  return await ApiService.ContractsApi.getOne({
    forWhat: ContractsEnum.SWORDS,
  });
}
//Get Contract Details From Backend -- END
//MINT NEW ITEM AND GET RARITY SELECTED FOR MINTED ITEM -- END
//UPDATE NEW MINTED CHARACTER QUESTS -- START
export async function updateCharacterQuests(
  transactionHash: string,
): Promise<CONTRACTS> {
  return await PATCH(env.HOST + "/quests/updateCharacterQuests", {
    hash: transactionHash,
  });
}
//UPDATE NEW MINTED CHARACTER QUESTS -- END

export async function mintNewCharacter(
  transactionHash: string,
): Promise<CONTRACTS> {
  return await GET(env.HOST + "/users/mintNewCharacter", {
    hash: transactionHash,
  });
}

//Check USER ACTIVE Quests -- END
//Check Character Available Quests -- START
export async function getCharacterQuests(
  _tokenid: string,
): Promise<CharacterQuests> {
  return await ApiService.QuestsApi.todayQuests({ nfttokenid: _tokenid });
}

//Check Character Available Quests -- END
//GET XPNET TOKENS BALANCE FOR CONNECTED USER
export async function getXPNETTokensBalance(_connectedwallet: string) {
  const contract: any = web3Service.getContract({
    abi: Abis.XPNET_TESTNET,
    contractId: Contracts.XPNET_TOKENS,
    initializer: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  });

  const mybalance: number = await contract.methods
    .balanceOf(_connectedwallet)
    .call();
  // //console.log("connected user balannce is " ,mybalance )
  return mybalance;
}
export async function getrequiredxpnetforupgrade(params: any): Promise<any> {
  return await GET(env.HOST + "/equipments/xpnetrequired", params);
}
export async function upgradeItem({
  type,
  tokenId,
  level,
  requiredXpnetForNextLevel,
  from,
  rarity,
}: {
  type: string;
  tokenId: number;
  level: number;
  rarity: string;
  requiredXpnetForNextLevel: number;
  from: string;
}): Promise<Boolean> {
  const xpnettokens: string = await GET(
    env.HOST + "/contracts/getxprewardscontract",
  );
  let booleanrewards: Boolean = false;
  let confirmedhash: string;
  const web3c = web3Service.getWeb3Instance({});
  const currentchain = await web3c.eth.getChainId();

  if (Number(currentchain) === ChainIdEnum.BSC) {
    console.log(
      "Calling XPNET CONTRACT TO ALLOW XPREWARDS CONTRACT TO GET XPNET TOKENS",
    );
    const increasedAllowance = await getAllowance(
      xpnettokens,
      requiredXpnetForNextLevel,
      from,
    );
    if (increasedAllowance === true) {
      //console.log("its true");
      //console.log("GOT THE ALLOWANCE NOW CALLING XPREWARDS CONTRACT");
      const functioncall = await upgradeitemXPrewards(
        xpnettokens,
        requiredXpnetForNextLevel,
        from,
        type,
        level,
        rarity,
      );
      booleanrewards = functioncall[0];
      if (booleanrewards === true) {
        confirmedhash = functioncall[1];
        console.log(
          "YUP THE CONTRACT GOT THE REQUIRED XPNETS FOR ITEM UPGRADE ",
        );
        GET(env.HOST + "/equipments/paymentconfirmend", {
          type,
          level,
          rarity,
          hash: confirmedhash,
          tokenId,
        }).then(() => { });
      } else {
        console.log(
          "SOME ERROR CONTRACT DIDNOT GET REQUIRED TOKENS FOR ITEM UPGRADE",
        );
      }
    } else {
      //console.log("its false");
    }
  } else if (Number(currentchain) !== ChainIdEnum.BSC) {
    getChain("BSC").then(async () => {
      console.log(
        "Calling XPNET CONTRACT TO ALLOW XPREWARDS CONTRACT TO GET XPNET TOKENS",
      );
      const increasedAllowance = await getAllowance(
        xpnettokens,
        requiredXpnetForNextLevel,
        from,
      );
      if (increasedAllowance === true) {
        //console.log("its true");
        //console.log("GOT THE ALLOWANCE NOW CALLING XPREWARDS CONTRACT");
        const functioncall = await upgradeitemXPrewards(
          xpnettokens,
          requiredXpnetForNextLevel,
          from,
          type,
          level,
          rarity,
        );
        booleanrewards = functioncall[0];
        if (booleanrewards === true) {
          confirmedhash = functioncall[1];
          console.log(
            "YUP THE CONTRACT GOT THE REQUIRED XPNETS FOR ITEM UPGRADE ",
          );
          GET(env.HOST + "/equipments/paymentconfirmend", {
            type,
            level,
            rarity,
            hash: confirmedhash,
            tokenId,
          }).then(() => { });
        } else {
          console.log(
            "SOME ERROR CONTRACT DIDNOT GET REQUIRED TOKENS FOR ITEM UPGRADE",
          );
        }
      } else {
        //console.log("its false");
      }
    });
  }
  return booleanrewards;
}
async function getAllowance(
  xpnettokens: string,
  requiredxpnetfornextlevel: number,
  from: string,
): Promise<Boolean> {
  // const web3 = web3Service.getWeb3Instance({});

  const tokenscontract: any = web3Service.getContract({
    abi: Abis.XPNET_TOKENS,
    contractId: Contracts.XPNET_TOKENS,
  });
  tokenscontract.methods.increaseAllowance("");

  const encoded: string = await tokenscontract.methods
    .increaseAllowance(
      xpnettokens,
      web3Service
        .getWeb3Instance({})
        .utils.toWei(
          web3Service
            .getWeb3Instance({})
            .utils.toNumber(requiredxpnetfornextlevel),
          "Gwei",
        ),
    )
    .encodeABI();
  var tx = {
    from: from,
    data: encoded,
    to: Contracts.XPNET_TOKENS,
  };
  const mygas = await web3Service.estimateGas({ transaction: tx });
  var txf = { gas: mygas, ...tx };
  const booleanv = await web3Service.sendTransaction({ transaction: txf });
  return booleanv.status;
}
async function upgradeitemXPrewards(
  xpnettokens: string,
  requiredxpnetfornextlevel: number,
  from: string,
  type: string,
  level: number,
  rarity: string,
): Promise<[boolean, string]> {
  const contract: any = web3Service.getContract({
    abi: Abis.XP_REWARDS,
    contractId: xpnettokens,
  });
  const signedhash = await ApiService.EquipmentsApi.sign({
    level,
    type,
    rarity,
  });
  const hash = signedhash.messagehash;
  const signature = signedhash.signature;
  const s = {
    hash,
    signature,
  };
  const encoded: string = await contract.methods
    .upgradeItem(requiredxpnetfornextlevel, s)
    .encodeABI();
  const tx: Transaction = {
    from: from,
    data: encoded,
    to: xpnettokens,
  };
  try {

    const booleanv = await web3Service.sendTransaction({ transaction: tx });
    const confirmhash = booleanv.transactionHash;
    return [booleanv.status, confirmhash];
  } catch (error) {
    return [false, ""];

  }
}
