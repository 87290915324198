import React, { useEffect } from "react";
import "../Inventory/showEquipmentModal.css";
import { EquipItem } from "./equipitem";
import { UnEquipItem } from "./unequipitem";
import { Rating } from "./Rating/Rating";
import { AxiosResponse } from "axios";

import { useDispatch } from "react-redux";
import { UseGetNFTS } from "../Homepage/Components/UseGetNFTs/useGetNFTS";
import { usegetUserCharacters } from "../Homepage/utils/usegetUserCharacters";
import { setCharacters } from "../../store/slices/nftCharacter.slice";
import { store } from "../../store/store";
import {
  attSymbol,
  closeIcon,
  metaMaskIcon,
} from "../../services/assets.service";
type Props = {
  equipped: any;
  setEquipped: any;
  // selectedItem: any;
  setShowSelected: React.Dispatch<React.SetStateAction<boolean>>;
  setEquippedResponse: React.Dispatch<React.SetStateAction<boolean>>;
  equipResponse: boolean;
};
export const ShowSelectedArmory: React.FC<Props> = ({
  equipped,
  setEquipped,
  // selectedArmor,
  setShowSelected,
  setEquippedResponse,
  equipResponse,
}) => {
  const { selectedItem } = store.getState().general;
  const { selectedCharacter } = store.getState().nftCharacter;
  const { address: walletAddress } = store.getState().user;

  const firstSevenChars = selectedItem.connectedwallet.slice(0, 7);
  const lastFourChars = selectedItem.connectedwallet.slice(-4);
  const address = `${firstSevenChars}...${lastFourChars}`;
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedItem.jsonnft) {
      if (selectedItem.jsonnft.equipped === 0) {
        setEquipped(false);
      }
      //console.log(selectedItem.jsonnft.equippedon === selectedCharacter);
      if (selectedItem.jsonnft.equippedon === selectedCharacter) {
        setEquipped(true);
      }
    }
  }, [equipped]);

  const handleClick = async () => {
    return;
    if (equipped) {
      //console.log("UNEQUIPPING");
      const response = await UnEquipItem({
        tokenId: selectedItem.token,
        shortcode: selectedItem.shortcode,
        _uri: selectedItem.uri,
        _connectedwallet: walletAddress,
        _selectedcharacter: selectedCharacter.toString(),
      }
      );
      //console.log("unequip response: ", await response);
      if ((await response.status) === 200) {
        setEquipped(!equipped);
      }
    } else if (!equipped) {
      //console.log("EQUIPPING");
      const response: AxiosResponse = await EquipItem({
        tokenId: selectedItem.token,
        _connectedwallet: walletAddress,
        _selectedcharacter: selectedCharacter.toString(),
        _uri: selectedItem.uri,
        shortcode: selectedItem.shortcode,
      });
      //console.log("equip response: ", await response);
      if ((await response.status) === 200) {
        //console.log("response.data: ", response.data);
        setEquipped(!equipped);
        setEquippedResponse(!equipResponse);
        UseGetNFTS(address).then(async (_nfts) => {
          const usercharacters: number[] = await usegetUserCharacters(address);
          dispatch(setCharacters(usercharacters));
        });
      }
    }

    handleClose();
  };
  const handleClose = () => {
    setShowSelected(false);
  };

  return (
    <>
      <div className="equipment-container">
        <div className="modal-container">
          <img
            className="close"
            src={closeIcon}
            onClick={handleClose}
            alt=""
          ></img>
          <div className="d-flex flex-row justify-content-evenly w-100 bg-primary">
            <div className="bg-warning">
              <div className="shortCodeDiv">
                <p className="selectedItemDesc">{selectedItem.shortcode}</p>
              </div>
              <div className="itemImg">
                <img
                  style={{ height: 100, width: 100 }}
                  //src={`${selectedItem.data.image}`}
                  alt="#"
                ></img>
              </div>
            </div>
            <div className="item2">
              <div className="itemDetail">
                <div>
                  <div className="levelUp">
                    <p>Level</p>
                    <button className="levelUpButton"></button>
                  </div>
                  <div className="ratingDiv">
                    {
                      /*selectedItem.data.attributes[0].value*/ (selectedItem.id
                        .value === 1 && (
                          <>
                            <Rating number={1} />
                            <p>Common</p>
                          </>
                        )) ||
                        /*selectedItem.data.attributes[0].value*/ (selectedItem
                        .id.value === 2 && (
                          <>
                            <Rating number={2} />
                            <p>Epic</p>
                          </>
                        )) ||
                        /*selectedItem.data.attributes[0].value*/ (selectedItem
                        .id.value === 3 && (
                          <>
                            <Rating number={3} />
                            <p>Unique</p>
                          </>
                        )) ||
                        /*selectedItem.data.attributes[0].value*/ (selectedItem
                        .id.value === 4 && (
                          <>
                            <Rating number={4} />
                            <p>Legendary</p>
                          </>
                        ))
                    }
                  </div>
                </div>
              </div>
              <div className="itemDetailDiv2">
                <div className="itemDetailSubDiv1">
                  <div className="itemClass">
                    <p>Class</p>
                  </div>
                  <div className="itemClassName">
                    <p>WeaponX</p>
                  </div>
                </div>
                <div className="itemDetailSubDiv2">
                  <div className="itemClass">
                    <p>Attribute</p>
                  </div>
                  <div className="itemClassName">
                    <img src={attSymbol} alt="att-symbol" />
                    {/* <p>{selectedItem.data.attributes[2].trait_type}</p> */}
                  </div>
                </div>
              </div>
              <div className="itemDetailDiv2">
                <div className="itemDetailSubDiv1">
                  <div className="itemClass">
                    <p>Color</p>
                  </div>
                  <div className="itemClassName">
                    <p>Gold</p>
                  </div>
                </div>
                <div className="itemDetailSubDiv2">
                  <div className="itemClass">
                    <p>Price</p>
                  </div>
                  <div className="itemClassName">
                    <p>32,445 XPNET</p>
                  </div>
                </div>
              </div>
              <div className="ownerAddress">
                <div className="transferOwner">
                  <p>Owner</p>
                  <button className="transferButton"></button>
                </div>
                <div className="metaMaskDetail">
                  <div>
                    <img src={metaMaskIcon} alt="metamask-icon" />
                  </div>
                  <div>
                    <p>{address}</p>
                  </div>
                </div>
              </div>
              {/* <button className="equipButton" onClick={handleClick}>
              {equipped ? "Unequip" : "Equip"}
            </button> */}

              {/* Temporary Commenting */}
              {selectedItem.jsonnft.equippedon !== selectedCharacter &&
                equipped && (
                  <h1 className="EquippedBySome">
                    Equipped by Dude {selectedItem.jsonnft?.equippedon}
                  </h1>
                )}
              {selectedItem.jsonnft && selectedItem.equipped === false && (
                <button className="equipButton" onClick={handleClick}>
                  Equip
                </button>
              )}
              {selectedItem.jsonnft != null &&
                selectedItem.jsonnft.equippedon === selectedCharacter ? (
                <button className="equipButton" onClick={handleClick}>
                  UnEquip
                </button>
              ) : (
                <h1 className="EquippedBySome">
                  Equipped by {selectedItem.jsonnft?.equippedon}
                </h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
