import React from "react";
import Spinner, { SpinnerProps } from "react-bootstrap/Spinner";

export interface LoaderProps extends SpinnerProps {}

export const Loader = (props: LoaderProps) => {
  return (
    <div>
      <Spinner {...props}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};
