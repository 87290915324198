import "./App.css";
import * as allChains from "@wagmi/chains";
import { MyRouter } from "./pages/Homepage/MyRouter";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useRef, useState } from "react";
import { Web3ReactProvider } from "@web3-react/core";
import { Contract, providers } from "web3";
import { Web3Service, injected } from "./services/web3.service";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "./store/store";
import {
  setMintedItems,
  setOnline,
  setSelectedItemData,
} from "./store/slices/general.slice";
import { QueryClient, QueryClientProvider } from "react-query";
import { trace } from "console";
import { ApiService } from "./services/apis.service";
import { setSelectedCharacterData } from "./store/slices/nftCharacter.slice";
import { ContractsEnum } from "./utils/enums";
import { BridgeService } from "./services/bridge.service";
import { Chain } from "xp.network";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from '@mui/material/CircularProgress';
import { updateCharacterQuests } from "./utils/API";
import { Box } from "@mui/material";
declare global {
  interface Window {
    ethereum: any;
  }
}

function App() {
  const dispatch = useDispatch();

  const queryClient = new QueryClient({});
  useEffect(() => {
    const handleOnline = () => {
      dispatch(setOnline(true));
      ////console.log("navigator.onLine: ", navigator.onLine);
    };

    const handleOffline = () => {
      dispatch(setOnline(false));
      ////console.log("navigator.onLine: ", navigator.onLine);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  let contractO: any = null;

  const selectedImage = useSelector(
    (state: RootState) => state.nftCharacter.selectedCharacter,
  );
  const open = useSelector((state: RootState) => state.general.openLoader);
  const selectedImageRef = useRef(selectedImage);
  const dataRefetch = useSelector((state: RootState) => state.general.dataRefetch);
  const selectedCharacterData = useSelector((state: RootState) => state.nftCharacter.selectedCharacterData);
  const address = useSelector((state: RootState) => state.user.address);
  const addressRef = useRef(address);

  useEffect(() => {
    selectedImageRef.current = selectedImage;
    addressRef.current = address;
  }, [address, selectedImage]);

  const selectedCharacter = useSelector(
    (state: RootState) => state.nftCharacter.selectedCharacter,
  );
  const selectedItem = useSelector(
    (state: RootState) => state.general.selectedItem,
  );
  const selectedItemData = useSelector(
    (state: RootState) => state.general.selectedItemData,
  );

  useEffect(() => {
    ///To manual trigger auto quest generation/update
    //const Run = async () => {
    //console.log("trying ...");
    //await updateCharacterQuests("gggh");
    //}
    //Run();
  }, [selectedCharacter]);

  useEffect(() => {
    if (selectedItem) {
      ApiService.EquipmentsApi.getAwsItem("sw", selectedItem).then(
        (response) => {
          //console.log(response);
          dispatch(setSelectedItemData(response));
        },
      );
    }
  }, [selectedItem]);

  let timerStarted: boolean = false;
  useEffect(() => {
    ApiService.ContractsApi.getOne({
      forWhat: ContractsEnum.CHARACTERS,
    }).then((contract) => {
      contractO = contract;
      if (!timerStarted) {
        timerStarted = true;
        StartTimer();
      }
    });
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }, []);
  const StartTimer = () => {
    let count: number = 0;
    var x = setInterval(function () {
      count += 1000;
      try {
        if (count / (16 * 60 * 1000) == 1 && count % (16 * 60 * 1000) == 0) {
          if (
            selectedImageRef.current != null ||
            selectedImageRef.current != undefined
          ) {
            toast("Common chest droped for Character " + selectedImageRef.current, { theme: "dark" });
            ApiService.ChestsApi.drop({
              walletAddress: addressRef.current,
              characterId: contractO?.contract?.CONTRACT + selectedImageRef.current,
              time: 16,
              rarity: "common",
              characterTokenId: selectedImageRef.current
            });
          }
        }
        if (count / (30 * 60 * 1000) == 1 && count % (30 * 60 * 1000) == 0) {
          if (
            selectedImageRef.current != null ||
            selectedImageRef.current != undefined
          ) {
            toast("Rare chest droped for Character " + selectedImageRef.current, { theme: "dark" });
            ApiService.ChestsApi.drop({
              walletAddress: addressRef.current,
              characterId: contractO?.contract?.CONTRACT + selectedImageRef.current,
              time: 30,
              rarity: "rare",
              characterTokenId: selectedImageRef.current
            });
          }
        }
        if (count / (60 * 60 * 1000) == 1 && count % (60 * 60 * 1000) == 0) {
          if (
            selectedImageRef.current != null ||
            selectedImageRef.current != undefined
          ) {
            toast("Epic chest droped for Character " + selectedImageRef.current, { theme: "dark" });
            ApiService.ChestsApi.drop({
              walletAddress: addressRef.current,
              characterId: contractO?.contract?.CONTRACT + selectedImageRef.current,
              time: 60,
              rarity: "epic",
              characterTokenId: selectedImageRef.current
            });
          }
        }
        if (count / (180 * 60 * 1000) == 1 && count % (180 * 60 * 1000) == 0) {
          if (
            selectedImageRef.current != null ||
            selectedImageRef.current != undefined
          ) {
            toast("Legendry chest droped for Character " + selectedImageRef.current, { theme: "dark" });
            ApiService.ChestsApi.drop({
              walletAddress: addressRef.current,
              characterId: contractO?.contract?.CONTRACT + selectedImageRef.current,
              time: 180,
              rarity: "legendary",
              characterTokenId: selectedImageRef.current
            });
          }
        }
        if (count / (360 * 60 * 1000) == 1 && count % (360 * 60 * 1000) == 0) {
          if (
            selectedImageRef.current != null ||
            selectedImageRef.current != undefined
          ) {
            toast("Unique chest droped for Character " + selectedImageRef.current, { theme: "dark" });
            ApiService.ChestsApi.drop({
              walletAddress: addressRef.current,
              characterId: contractO?.contract?.CONTRACT + selectedImageRef.current,
              time: 360,
              rarity: "unique",
              characterTokenId: selectedImageRef.current
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    }, 1000);
  };

  const { refreshMintedItems } = store.getState().refresh;

  const getNfts = async () => {
    const web3Service = new Web3Service();
    const currentChainId = await web3Service.getCurrentChainId();
    let chain;

    if (currentChainId === allChains.polygonMumbai.id) {
      chain = Chain.POLYGON;
    } else {
      chain = Chain.BSC;
    }
    BridgeService.getNftList({
      walletAddress: address,
      chain: chain,
    }).then((response) => {
      setMintedItems(response);
    });
  };
  useEffect(() => {
    const Run = async () => {
      try {
        if (selectedCharacterData?.tokenId == null || selectedCharacterData.tokenId == undefined) {
          return;
        }
        await ApiService.CharactersApi.getOne({
          tokenId: selectedCharacterData?.tokenId,
          characterId: selectedCharacterData?.characterId,
        }).then((response) => {
          //console.log(response);
          dispatch(setSelectedCharacterData(response));
        });
      } catch (error) {
        //console.log(error);
      }
    }
    Run();
  }, [dataRefetch]);
  useEffect(() => {
    if (address) {
      getNfts();
    }
  }, [refreshMintedItems]);
  // useEffect(() => {
  //   //console.log("navigator.onLine: ", navigator.onLine);
  //   dispatch(setOnline(navigator.onLine));
  // }, [navigator.onLine]);
  return (
    <QueryClientProvider client={queryClient}>
      {/* <Web3ReactProvider connectors={[]}> */}
      <div className="App">
        <ToastContainer />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <div className="backdrop_bg d-flex align-items-center justify-content-center">
            <div className="d-flex  align-text-center">
              <h3 className="myfont">Loading</h3>
            </div>
            {/* <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 300,
              width: 300,
              border: "4px solid #ccc",
              borderRadius: 36,
              backgroundColor: 'rgba(64, 64, 64, 0.5)'
            }}
          >
            <CircularProgress color="inherit" />
          </Box> */}
          </div>
        </Backdrop>
        <MyRouter />
      </div>
      {/* </Web3ReactProvider> */}
    </QueryClientProvider >
  );
}

export default App;
