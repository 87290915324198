import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Hometab } from "./Hometab";
import { setAddress } from "../../store/slices/user.slice";
import { Quests } from "../Quests/Quests";
import { LoginScreen } from "../LoginScreen/LoginScreen";
import { Inventory } from "../Inventory/Inventory";
import { FAQ } from "../FAQ/FAQ";
import Market from "../Market/Market";

export const MyRouter: React.FC = () => {
  const address = useSelector((state: RootState) => state.user.address);
  const dispatch = useDispatch();

  if (window.ethereum) {
    window.ethereum.on("accountsChanged", async () => {
      if (address !== "") {
        dispatch(setAddress(""));
      }
    });
    return (
      <>
        <Router>
          <Routes>
            <Route path="/" element={<LoginScreen />} />
            <Route path="/hometab" element={<Hometab />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/quests" element={<Quests />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/market" element={<Market />} />
          </Routes>
        </Router>
      </>
    );
  } else {
    return (
      <>
        <div>PLEASE INSTALL METAMASK</div>
      </>
    );
  }
};
