import { createSlice } from "@reduxjs/toolkit";
import { Item } from "../../utils/types";
type Initial = {
  equipped: boolean | undefined;
  filterModal: boolean;
  timeelapsed: any;
  remainingtime: any;
  selectedItem: any;
  selectedItemData: Item;
  showItemDetailModal: boolean;
  selectedQuest: number;
  mintedItems: any;
  online: boolean;
  dataRefetch: number;
  openLoader: boolean;
};

export const generalSlice = createSlice({
  name: "general",

  initialState: {

    equipped: undefined,
    filterModal: false,
    selectedItem: undefined,
    showItemDetailModal: false,
    selectedQuest: 0,
    online: true,
    mintedItems: [],
    dataRefetch: 0,
    openLoader: false,
  } as Initial,
  reducers: {
    setEquipped: (state, action) => {
      state.equipped = action.payload;
    },
    setFilterModal: (state, action) => {
      state.filterModal = action.payload;
    },
    setTimeElapsed: (state, action) => {
      state.timeelapsed = action.payload;
    },
    setRemainingTime: (state, action) => {
      state.remainingtime = action.payload;
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    setSelectedItemData: (state, action) => {
      state.selectedItemData = action.payload;
    },
    setShowItemDetailModal: (state, action) => {
      state.showItemDetailModal = action.payload;
    },
    setSelectedQuest: (state, action) => {
      state.selectedQuest = action.payload;
    },
    setOnline: (state, action) => {
      state.online = action.payload;
    },
    setMintedItems: (state, action) => {
      state.mintedItems = action.payload;
    },
    setDataRefetch: (state) => {
      state.dataRefetch += 1;
    },
    setOpenLoader: (state, action) => {
      state.openLoader = action.payload;
    }
  },
});

export const {
  setEquipped,
  setFilterModal,
  setTimeElapsed,
  setRemainingTime,
  setSelectedItem,
  setSelectedItemData,
  setShowItemDetailModal,
  setSelectedQuest,
  setOnline,
  setMintedItems,
  setDataRefetch,
  setOpenLoader
} = generalSlice.actions;

export default generalSlice.reducer;
