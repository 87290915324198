import React, { useEffect, useRef, useState } from "react";
import "./CharacterSlider.css";
import { arrowRightHover, indicator } from "../../services/assets.service";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import CharacterTile from "../CharacterTile/CharacterTile";

type Props = {
  characters: Array<any>;
  handleClick: any;
};

export default function CharacterSlider({ characters, handleClick }: Props) {
  const selectedImage = useSelector(
    (state: RootState) => state.nftCharacter.selectedCharacter,
  );
  const sliderRef: any = useRef(null);
  const selectedTileRef: any = useRef(null);
  const [isPressedL, SetPressedL] = useState(Boolean);
  const [isPressedR, SetPressedR] = useState(Boolean);
  const scrollLeft = () => {
    sliderRef.current.scrollLeft -= 200;
  };
  const scrollRight = () => {
    sliderRef.current.scrollLeft += 200;
  };
  const scrollToSelected = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000))
    if (selectedTileRef.current) {
      const tileContainer = selectedTileRef.current;
      tileContainer?.scrollIntoView({ behavior: 'smooth' });
    }
  }
  useEffect(() => {
    scrollToSelected();
  }, [selectedImage])
  return (
    <div className="sliderWraper d-flex justify-content-center px-2">
      <div className="sliderBackground d-flex flex-row justify-content-center align-items-center">
        <img
          src={isPressedL ? arrowRightHover : "arrow-right.png"}
          alt=""
          width={"30px"}
          height={"30px"}
          className={`flip-image cursor-pointer ${isPressedL ? "zoom" : undefined
            }`}
          onClick={scrollLeft}
          onMouseDown={() => SetPressedL(true)}
          onMouseUp={() => SetPressedL(false)}
        />
        <div
          className="imageSlider overflow-auto d-flex flex-row align-items-center"
          ref={sliderRef}
        >
          {characters?.map((element: any, index: number) => (
            <div
              key={index}
              ref={selectedImage === element ? selectedTileRef : null} // Set the ref for the selected tile
              className="tileWraper d-flex flex-column align-items-center justify-content-center"
            >
              <img
                className="diamond"
                src={indicator}
                alt=""
                width={50}
                height={50}
                style={{
                  // zIndex: 100,
                  opacity: selectedImage === +element ? 1 : 0,
                }}
              />
              <CharacterTile
                character={element}
                handleClick={handleClick}
                selectedImage={selectedImage}
              />
            </div>
          ))}
        </div>
        <img
          src={isPressedR ? arrowRightHover : "arrow-right.png"}
          alt=""
          width={"30px"}
          height={"30px"}
          className={`cursor-pointer ${isPressedR ? "zoom" : undefined}`}
          onClick={scrollRight}
          onMouseDown={() => SetPressedR(true)}
          onMouseUp={() => SetPressedR(false)}
        />
      </div>
    </div>
  );
}
