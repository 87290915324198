import { GET, PATCH } from "../utils/axios.http";
import { CheckNFTEquip } from "../utils/types";
import { ApiUrls, AxiosService } from "../services/axios.service";
import { local } from "../lib/env";

class InventoryApiClient extends AxiosService {
  constructor() {
    super({ url: ApiUrls.Perks });
  }

  async equip({
    id,
    shortname,
    uri,
    signednonce,
    walletaddress,
    tokenid,
  }: {
    id: string;
    shortname: string;
    uri: string;
    signednonce: string;
    walletaddress: string;
    tokenid: any;
  }): Promise<any> {
    try {
      return await PATCH(local.HOST + "/" + ApiUrls.Inventory + "/equip", {
        id,
        shortname,
        uri,
        signednonce,
        walletaddress,
        tokenid,
      });
    } catch (error) {
      //console.log(error);
    }
  }

  async allequipped({ connectedwallet }: { connectedwallet: string }) {
    try {
      return await GET(ApiUrls.Inventory + "/allequipped", {
        walletaddress: connectedwallet,
      });
    } catch (error) {
      //console.log(error);
    }
  }

  async checkEquip({
    shortcode,
  }: {
    shortcode: string;
  }): Promise<CheckNFTEquip | null> {
    try {
      return await GET(ApiUrls.Inventory + "/check/equip", {
        shortname: shortcode,
      });
    } catch (error) {
      //console.log(error);
      return null;
    }
  }

  async unequip({
    id,
    shortname,
    uri,
    signednonce,
    walletaddress,
    tokenid,
  }: {
    id: string;
    shortname: string;
    uri: string;
    signednonce: string;
    walletaddress: string;
    tokenid: any;
  }): Promise<any> {
    try {
      return await PATCH(ApiUrls.Inventory + "/unequip", {
        id,
        shortname,
        uri,
        signednonce,
        walletaddress,
        tokenid,
      });
    } catch (error) {
      //console.log(error);
    }
  }
}

const InventoryApi = new InventoryApiClient();
export { InventoryApi };
