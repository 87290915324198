import { ApiUrls, AxiosService } from "../services/axios.service";

class ContractsApiClient extends AxiosService {
  constructor() {
    super({ url: ApiUrls.Contracts });
  }
}

const ContractsApi = new ContractsApiClient();
export { ContractsApi };
