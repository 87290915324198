import { UnEquipItemAPI } from "../../utils/inventoryAPI";
import { Web3Service } from "../../services/web3.service";
import { getEquipItemHash } from "../../lib/MainNetTestNet/values";

export const UnEquipItem = async (
  {
    tokenId,
    shortcode,
    _uri,
    _connectedwallet,
    _selectedcharacter
  }: {
    tokenId: any,
    shortcode: string,
    _uri: string,
    _connectedwallet: string,
    _selectedcharacter: string,
  }
): Promise<any> => {
  const web3Service = new Web3Service();
  // const nonce = web3Service
  //   .getWeb3Instance({})
  //   .utils.utf8ToHex(
  //     "I Want To UN-Equip " +
  //     shortcode +
  //     " from my character nft " +
  //     _selectedcharacter +
  //     " using my account : " +
  //     _connectedwallet,
  //   );
  // const signednonce = await web3Service.sign({
  //   hash: nonce,
  //   address: _connectedwallet,
  // });
  const hash = getEquipItemHash(
    shortcode,
    _uri,
    _connectedwallet,
    _selectedcharacter,
  );
  //console.log("values are ", _selectedcharacter, shortcode, _uri, hash, _connectedwallet, tokenId);
  return await UnEquipItemAPI(
    _selectedcharacter,
    shortcode,
    _uri,
    hash,
    _connectedwallet,
    tokenId,
  );
};
