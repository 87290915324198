import { url } from "inspector";
import {
  questCardAssets,
  fireBorder,
} from "../../../../services/assets.service";
import { RootState, store } from "../../../../store/store";
import { StopQuest, startQuest } from "../../helpers";
import Badges from "../Badges/Badges";
import Timer from "../Timer/Timer";

import "./QuestCard.css";
import { setDataRefetch, setOpenLoader, setSelectedQuest } from "../../../../store/slices/general.slice";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Web3Service } from "../../../../services/web3.service";
import { typeToUrlMap } from "../../../../lib/MainNetTestNet/values";
import { QuestsApi } from "../../../../apis/quests.api";
import { ApiService } from "../../../../services/apis.service";
import { selectClasses } from "@mui/material";
import { toast } from "react-toastify";
import { resolve } from "path";
type Props = {
  reward: number | string;
  quest: any;
  index: any;
  handleQuestSelect: any;
  timer: any;
  escape: any;
  questRunning: any;
  questCompleted: any;
  updateQuests: any;
};

export default function QuestCard(
  this: any,
  {
    reward,
    quest,
    index,
    handleQuestSelect,
    timer,
    questRunning,
    questCompleted,
    escape,
    updateQuests,
  }: Props,
) {
  enum Chains {
    "POLYGON",
    "ETHEREUM",
    "FUSE",
    "FANTOM",
    "AVALANCHE",
  }
  const [questStarted, startTimer] = useState(false);
  const [startedQuest, SetStartedQuest] = useState(-1);
  const { address } = store.getState().user;
  // const { selectedQuest } = store.getState().general
  const selectedQuest: any = useSelector(
    (state: RootState) => state.general.selectedQuest,
  );
  const web3Service = new Web3Service();
  /**
   * Trigger Quest Start flow
   * @returns 
   */
  const setQuestStart = async () => {
    store.dispatch(setOpenLoader(true));
    updateQuests();
    const charcterChain = await web3Service.getChainId({
      initializer: typeToUrlMap["CHARACTERS"][0],
    });
    //console.log("character Chain " + String(charcterChain));
    //console.log("quest Chain " + String(quest.tochain));
    if (!questStarted) {
      if (quest.isPlayed) {
        //console.log("already played");
        toast("Quest is already Played", { theme: "dark" })
        await startTimer(false);
        store.dispatch(setOpenLoader(false));
        return;
      }
      try {
        await timer(
          quest.datestarted,
          quest.requiredtime,
          quest.tochain,
          quest.nfttokenid,
          quest.starthash,
          quest.no
        );
        SetStartedQuest(quest.no);
        await startTimer(true);
      } catch (error) {
        SetStartedQuest(-1);
      }

      //console.log(questStarted);
    } else {
      await store.dispatch(setDataRefetch());
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await startTimer(false);
      await updateQuests();
      if (quest.datestarted == null || quest.datestarted == undefined) {
        //console.log("quest is not started for some reason");
        toast("This Quest was not started", { theme: "dark" });
        store.dispatch(setOpenLoader(false));
        return;
      }
      escape(quest.requiredtime, quest.datestarted);
      await StopQuest({
        walletAddress: address,
        _toChain: quest.tochain,
        nftTokenId: quest.nfttokenid,
        startHash: quest.starthash,
      });
      ApiService.QuestsApi.setQuestClaimed({
        walletAddress: address,
        nftTokenId: String(quest.nfttokenid),
        starthash: String(quest.starthash),
        no: quest.no
      });
      store.dispatch(setOpenLoader(false));
      //console.log(questStarted);
    }
  };
  useEffect(() => {
    if (questCompleted) {
      startTimer(false);
    }
  }, [questCompleted]);
  useEffect(() => {
    startTimer(questRunning);
  }, [questRunning]);
  // useEffect(() => {
  //   // //console.log({ selectedQuest }, { index });
  // }, [selectedQuest]);

  /**
   * Initialize and Check if current quest is started already
   */
  useEffect(() => {
    if (localStorage.getItem("questData")) {
      try {
        if (quest.isPlayed) {
          //console.log("already played");
          return;
        }
        const parsedData = JSON.parse(
          localStorage.getItem("questData") as string,
        );
        timer(
          parsedData.datestarted,
          parsedData.questTime,
          parsedData.tochain,
          parsedData.questId,
          parsedData.starthash,
        );
        startTimer(true);
        if (localStorage.getItem("questIndex")) {
          const parsedIndex = JSON.parse(
            localStorage.getItem("questIndex") as string,
          );
          setSelectedQuest(Number(parsedIndex.selectedQuest));
          localStorage.removeItem("questIndex");
        }
        localStorage.removeItem("questData");
      } catch (error) {
        //console.log("Error in getting remaining Time" + error);
      }
    }
  }, []);
  return (
    <>
      <div
        className={`overlay ${selectedQuest === index ? "fireBg" : ""}`}
        style={{ position: "relative" }}
      >
        <div
          className={`d-flex flex-column flex-grow-1 justify-content-start quest-card overlay-content ${quest.tochain === Chains.POLYGON
            ? "quest-bg-1"
            : quest.tochain === Chains.ETHEREUM
              ? "quest-bg-2"
              : "quest-bg-3"
            }`}
          onClick={() => {
            handleQuestSelect(index);
          }}
        >
          <div className="reward">
            <div className="d-flex flex-column align-items-center m-0 p-0">
              <img
                loading="lazy"
                src={questCardAssets.xpCoin}
                width={"30px"}
                height={"auto"}
                alt="coin"
              />
              <p className="reward-value m-0 p-0">{reward}</p>
            </div>
          </div>
          {questRunning && startedQuest == quest.no ? (
            <div>
              <h1
                className="myfont-white"
                id="timer"
                style={{
                  position: "absolute",
                  left: "0",
                  right: "0",
                  translate: "0% -40%",
                }}
              ></h1>
            </div>
          ) : (
            <></>
          )}
          <div
            className={`d-flex flex-column justify-content-center align-items-center p-6 flex-wrap card-content`}
          >
            <p style={{ color: "white" }} className="m-0 p-o">
              {quest.tochain}
            </p>
          </div>
          <div
            className="d-flex flex-column justify-content-end align-items-center w-100"
            style={{ height: "60%" }}
          >
            {/* if there is time left in quest */}
            {quest.requiredtime > 0 ? (
              <Timer time={quest.requiredtime} />
            ) : (
              <div className="w-100">
                <div className="body-content">
                  <img
                    src={questCardAssets.lock}
                    alt=""
                    width={`25px`}
                    height={`25px`}
                  />
                </div>
                <Badges badges={["attack", "defence", "time"]} />
              </div>
            )}
          </div>

          {quest.requiredtime > 0 && (
            <div className="d-flex flex-row justify-content-center align-items-center w-100">
              <button
                className="btn-green"
                type="button"
                onClick={() => {
                  try {
                    // StopQuest({
                    //   walletAddress: address,
                    //   _toChain: quest.tochain,
                    //   nftTokenId: quest.nfttokenid,
                    //   startHash: quest.starthash,
                    // });
                    setQuestStart();
                  } catch (error) {
                    //console.log(error);
                  }
                }}
              >
                {(questStarted && questRunning && startedQuest == quest.no) ? "End Quest" : "Start Quest"}
              </button>
            </div>
          )}
          {/* <img
            className="fireBg d-flex"
            src={fireBorder}
          /> */}
        </div>
      </div>
    </>
  );
}
