import { rectangleHeader } from "../services/assets.service";

interface Props {
  text: string;
}

export const RectangleHeader: React.FC<Props> = ({ text }) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <img
        className="RectangleHeader"
        src={rectangleHeader}
        alt="Button"
        height={"75px"}
        width={"80%"}
      />
      <div className="RectangleHeaderText" style={{ marginTop: "-50px" }}>
        <p className="myfont">{text}</p>
      </div>
    </div>
  );
};
