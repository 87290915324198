import { LoginScreen } from "../LoginScreen/LoginScreen";
import { setAddress } from "../../store/slices/user.slice";
import { useDispatch } from "react-redux";
import { store } from "../../store/store";
import { useEffect, useState, memo } from "react";
import "./Quests.css";
import { Navbar } from "../../components/Navbar/Navbar";
import { chest3, fireIcon, xpcoin } from "../../services/assets.service";

import { useNavigate } from "react-router-dom";
import { ChestModal } from "./ChestModal/ChestModal";
import { setSelectedCharacter, setSelectedCharacterData } from "../../store/slices/nftCharacter.slice";
import CharacterSlider from "../../components/CharacterSlider/CharacterSlider";
import { QuestSlider } from "../../components/QuestSlider/QuestSlider";
import { ApiService } from "../../services/apis.service";
import Notice from "../../components/Notice/Notice";
import { Chest } from "../../utils/types";
import { ContractsEnum } from "../../utils/enums";
import { setDataRefetch, setOpenLoader } from "../../store/slices/general.slice";

export const Quests = memo(() => {
  const [showNotice, setShowNotice] = useState<boolean>(true);
  const [showSelected, setShowSelected] = useState<boolean>(false);
  const [chests, setChests] = useState<Array<Chest>>([]);
  const [value, setValue] = useState(0);
  const [quests, setQuests] = useState<Array<any>>([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const address = useSelector((state: RootState) => state.user.address);
  // const characters = useSelector(
  //   (state: RootState) => state.nftCharacter.characters
  // );
  // const refreshChests = useSelector(
  //   (state: RootState) => state.refresh.refreshChests
  // );

  const { selectedCharacter, characters } = store.getState().nftCharacter;
  const { refreshChests } = store.getState().refresh;
  const { address } = store.getState().user;

  const handleInternetConnection = (state: boolean) => {
    setShowNotice(state);
  };

  const handleChestClick = () => {
    if (chests != undefined && chests?.length > 0) {
      setShowSelected(true);
    }
  };

  const FetchQuests = async () => {
    await ApiService.QuestsApi.getList({
      walletAddress: address,
      nftTokenId: selectedCharacter as number,
    }).then((response: any) => {
      setQuests(response);
    });
  };

  const handleCharacterClick = async (characterTokenId: number) => {
    dispatch(setOpenLoader(true));
    if (characterTokenId != null) {
      dispatch(setSelectedCharacter(characterTokenId));
      //console.log("feching chests for " + characterTokenId);
      const contract = await ApiService.ContractsApi.getOne({
        forWhat: ContractsEnum.CHARACTERS,
      });
      if (contract == null || contract == undefined) {
        dispatch(setOpenLoader(false));
        return;
      }
      //console.log("Contract for clicked charcter is ");
      //console.log({ contract });
      if (
        contract != null &&
        contract != undefined &&
        characterTokenId != null &&
        characterTokenId != undefined
      ) {
        console.log(
          "getting data for characterID" +
          contract?.contract?.CONTRACT +
          characterTokenId,
        );
        try {
          await ApiService.CharactersApi.getOne({
            tokenId: characterTokenId,
            characterId: contract?.contract?.CONTRACT + characterTokenId,
          }).then(async (response) => {
            // //console.log(response);
            //if (response) PopulateCharacterData(response);
            await dispatch(setSelectedCharacterData(response));
          });
        } catch (error) {
          dispatch(setOpenLoader(false));
          //console.log(error);
        }
      }
      await dispatch(setDataRefetch());
      ApiService.ChestsApi.getList({
        characterTokenId: characterTokenId,
        owner: address,
        claimed: false,
      }).then((chests) => {
        //console.log({ chests });
        setChests(chests);
      });
    }
    dispatch(setOpenLoader(false));
  };
  // const handleChangesMade = () => {
  //   setValue((value) => value + 1);
  // };
  useEffect(() => {
    if (!address) {
      navigate("/");
    }
    if (address) {
      //console.log("fetching chests for" + selectedCharacter);
      if (selectedCharacter == null || selectedCharacter == undefined) {
        return;
      }
      ApiService.ChestsApi.getList({
        characterTokenId: selectedCharacter,
        owner: address,
        claimed: false,
      }).then((chests) => {
        //console.log({ chests });
        setChests(chests);
      });
    }
  }, [address, navigate, selectedCharacter, value, refreshChests]);

  useEffect(() => {
    if (address && selectedCharacter) {
      FetchQuests();
    }
  }, [address, selectedCharacter]);

  useEffect(() => {
    if (address !== null && address !== "") {
    } else {
      dispatch(setAddress(""));
    }
  }, [address, dispatch]);

  return (
    <>
      {address ? (
        <>
          <div className="questPage">
            <Navbar />
            <div className="questFrameDiv">
              <div className="questInfo">
                <div className="questInfoAttribute d-flex flex-row justify-content-center align-items-center">
                  <img loading="lazy" src={fireIcon} alt="fire-att" />
                  <p className="m-0">16</p>
                </div>
                <div className="questInfoXpCoin d-flex flex-row justify-content-center align-items-center">
                  <img src={xpcoin} alt="xp-coin" />
                  <p className="m-0">743</p>
                </div>
              </div>
              <div className="questFrame">
                <p>Today's Quests</p>
              </div>
              <div
                className="d-flex flex-row align-items-center justify-content-evenly"
                style={{ width: "400px" }}
              >
                <div
                  className="d-flex flex-column align-items-center"
                  onClick={() => handleChestClick()}
                >
                  <img
                    loading="lazy"
                    className={`chestImg ${chests.length > 0 ? "animateChest" : undefined
                      }`}
                    src={chest3}
                    alt="chest-3"
                    width={"70px"}
                    height={"auto"}
                  />
                  {chests ? (
                    <p style={{ color: "white" }} className="m-0">
                      {chests.length}
                    </p>
                  ) : (
                    <p style={{ color: "white" }} className="m-0">
                      0
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center questCardHolder">
              <div style={{ width: "70%" }}>
                <QuestSlider
                  quests={quests}
                  handleInternet={handleInternetConnection}
                  updateQuests={FetchQuests}
                />
              </div>

              {/* <div className="questButton">
                <button>Upgrade to start {selectedCharacter}</button>
              </div> */}

              <div className="d-flex row w-100 justify-content-center ">
                <div className="d-flex flex-row col-auto justify-content-center align-items-center">
                  <CharacterSlider
                    characters={characters}
                    handleClick={handleCharacterClick}
                  />
                </div>
              </div>
              {!showNotice && (
                <div className="">
                  <Notice text="You have Lost Internet Connection" />
                </div>
              )}
            </div>
          </div>
          { }
          {showSelected && (
            <div className="chestDiv">
              <ChestModal chests={chests} setShowSelected={setShowSelected} />
            </div>
          )}
        </>
      ) : (
        <>
          <LoginScreen />
        </>
      )}
    </>
  );
});
