import "./LoginScreen.css";
import { nounceforlogin } from "../../lib/MainNetTestNet/values";
import { verification } from "../../utils/API";
import { setAddress } from "../../store/slices/user.slice";
import { useDispatch } from "react-redux";
import {
  MetaMaskImage,
  WalletConnectImage,
  XPLOGO,
} from "../../services/assets.service";
import { Web3Service } from "../../services/web3.service";
import { Loader } from "../../components/Loader/Loader";
import { useEffect, useState } from "react";
import Notice from "../../components/Notice/Notice";
import { setOpenLoader } from "../../store/slices/general.slice";

export const LoginScreen: React.FC = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    MetaMaskConnect();
    setShow(true);
  };
  useEffect(() => {
    dispatch(setOpenLoader(false));
  }, [])
  const [show, setShow] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  async function MetaMaskConnect(): Promise<void> {
    try {
      const web3Service = new Web3Service();
      const account: string[] = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const walletAddress: string = account[0];

      // //console.log(nounceforlogin + walletAddress);
      // const hash = web3Service
      //   .getWeb3Instance({})
      //   .utils.keccak256(nounceforlogin + walletAddress);

      // const signedHash = await web3Service.sign({
      //   hash: hash as string,
      //   address: walletAddress,
      // });

      // const verified = await verification(walletAddress, signedHash);
      // if (verified === true) {
      dispatch(setAddress(walletAddress));
      window.location.href = "/hometab";
      // }
    } catch (error) {
      setError("Something went wrong. Try reloading page.");
      dispatch(setOpenLoader(false));
      //console.log(error);
    } finally {
      setShow(false);
    }
  }
  return (
    <>
      <div className="main-page d-flex flex-column justify-content-center align-items-center">
        <img className="logo" src={XPLOGO} alt="" />
        <div className="main-container px-5 d-flex flex-column justify-content-center align-items-center h-60">
          {show ? (
            <div className="w-100">
              <Loader animation="border" role="status" variant="warning" />
            </div>
          ) : (
            <div className="wallet-container myfont-white d-flex flex-column gap-3">
              Connect Your Wallet
              <div className="cursor-pointer" onClick={handleClick}>
                <img src={MetaMaskImage} alt="" width={"90%"} />
              </div>
              <div className="cursor-pointer" onClick={handleClick}>
                <img src={WalletConnectImage} width={"90%"} alt="" />
              </div>
            </div>
          )}
        </div>
        {error ? <Notice text={error} /> : <></>}
      </div>
    </>
  );
};
