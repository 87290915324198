import { getEquipItemHash } from "../../lib/MainNetTestNet/values";
import { equipItemAPI } from "../../utils/inventoryAPI";
import { Web3Service } from "../../services/web3.service";

const web3Service = new Web3Service();

export const EquipItem = async ({
  shortcode,
  _uri,
  _connectedwallet,
  _selectedcharacter,
  tokenId,
}: {
  tokenId: any;
  shortcode: string;
  _uri: string;
  _connectedwallet: string;
  _selectedcharacter: string;
}): Promise<any> => {
  console.log({
    shortcode,
    _uri,
    _connectedwallet,
    _selectedcharacter,
    tokenId,
  });
  const hash = getEquipItemHash(
    shortcode,
    _uri,
    _connectedwallet,
    _selectedcharacter,
  );
  try {
    // const signedHash = await web3Service.sign({
    //   hash: hash,
    //   address: _connectedwallet,
    //   initializer: window.ethereum,
    // });
    //console.log("_connectedwallet: ", _connectedwallet);
    return await equipItemAPI({
      id: _selectedcharacter,
      shortname: shortcode,
      uri: _uri,
      signednonce: hash,
      walletaddress: _connectedwallet,
      tokenid: tokenId,
    });
  } catch (err) {
    //console.log("error here: ", err);
    if (4100) {
      //console.log("4100 ERROR METAMASK NOT ACTIVE");
      if (typeof window.ethereum !== "undefined") {
        //console.log("MetaMask is installed!");
        await window.ethereum.request({
          method: "eth_requestAccounts",
        });
      }
    }
    //console.log("this is catch: ", err);
  }
};
