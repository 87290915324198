import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../store/store";
import { LoginScreen } from "../LoginScreen/LoginScreen";
import { Navbar } from "../../components/Navbar/Navbar";
import "../Inventory/Inventory.css";
import { Item } from "../../utils/types";
import { useEffect, useRef, useState } from "react";
import { RectangleHeader2 } from "../RectangleHeader2";
import { ItemSearchBar } from "../ItemSearchBar";
import { DropDownMenu } from "./DropdownMenu/DropDownMenu";
import { ShowSelectedArmory } from "./showEquipmentModal";
import { SearchBar } from "./SearchBar/SearchBar";
import {
  setSelectedCharacter,
  setMetadata,
  setSelectedCharacterData,
} from "../../store/slices/nftCharacter.slice";
import { FilterModal } from "./FilterModal/FilterModal";
import {
  MintNFT,
  bi_defence,
  bi_fire,
  smoke,
  characterLoading
} from "../../services/assets.service";
import "react-bootstrap";
import ItemContainer from "../../components/ItemContainer/ItemContainer";
import CharacterSlider from "../../components/CharacterSlider/CharacterSlider";
import ItemDetailModal from "../../components/ItemDetailModal/ItemDetailModal";
import { ApiService } from "../../services/apis.service";
import MintItemOptionsModal from "../../components/MintItemOptions/MintItemOptionsModal";
import { EquipmentsApi } from "../../apis/equipments.api";
import _ from "lodash";
import { toast } from "react-toastify";
import { setDataRefetch, setOpenLoader } from "../../store/slices/general.slice";
import { ContractsEnum } from "../../utils/enums";
import { resolve } from "path";
import { useNavigate } from "react-router-dom";
// import { GetSwords } from "./GetSwords/GetSwords";

export const Inventory: React.FC = () => {
  const dispatch = useDispatch();
  const address = useSelector((state: RootState) => state.user.address);

  const dataRefetch = useSelector((state: RootState) => state.general.dataRefetch);

  const metaData = useSelector(
    (state: RootState) => state.nftCharacter.metaData,
  );
  const _characters = useSelector(
    (state: RootState) => state.nftCharacter.characters,
  );
  const selectedCharacter = useSelector(
    (state: RootState) => state.nftCharacter.selectedCharacter,
  );
  const filterModal = useSelector(
    (state: RootState) => state.general.filterModal,
  );
  const showItemDetailModal = useSelector(
    (state: RootState) => state.general.showItemDetailModal,
  );
  const selectedCharacterData = useSelector(
    (state: RootState) => state.nftCharacter.selectedCharacterData
  );
  let url: string;
  const [loading, setLoading] = useState<boolean>(false);
  const [perks, setPerks] = useState<Array<any>>([]);
  const [quests, setQuests] = useState<Array<any>>([]);
  const [showAttributes, setShowAttributes] = useState<boolean>(true);
  const [showSelected, setShowSelected] = useState<boolean>(false);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [listOfEquipedItems, SetListofEquipedItems] = useState<Array<any>>([]);
  const [equipped, setEquipped] = useState<boolean>(false);
  const [equipResponse, setEquippedResponse] = useState<boolean>(false);
  const [showMintOptions, SetShowMintOptions] = useState<boolean>(false);
  const [attckItems, SetAttackItems] = useState<Array<any>>([]);
  const [defenceItems, SetDefenceItems] = useState<Array<any>>([]);
  const [imageUrl, SetImageUrl] = useState<string>();
  const imageRef = useRef(0);
  const handleCharacterSelect = async (tokenId: number) => {
    //console.log("clicked On Charcter" + tokenId);
    dispatch(setOpenLoader(true));
    await SetImageUrl(characterLoading);

    try {
      // //console.log(tokenId);
      if (selectedCharacter === tokenId) {
        dispatch(setSelectedCharacter(null));
        dispatch(setSelectedCharacterData(undefined));
        await SetListofEquipedItems([]);
        dispatch(setOpenLoader(false));
        await SetImageUrl(characterLoading);
        return;
      } else {
        //console.log("selected", tokenId);
        try {
          if (!tokenId) {
            dispatch(setOpenLoader(false));
            await SetImageUrl(characterLoading);
            return;
          }
          const meta: Item =
            await ApiService.CharactersApi.getCharacterMetaData({
              tokenId: tokenId,
            });
          if (meta !== undefined) {
            await dispatch(setSelectedCharacter(tokenId));
            await dispatch(setMetadata(meta));
            await SetImageUrl(characterLoading);
            await new Promise((resolve) => setTimeout(resolve, 200));

            //console.log("tokenid after meta dispatch Is " + tokenId);
          } else {
            dispatch(setOpenLoader(false));
            return;
          }
          const contract = await ApiService.ContractsApi.getOne({
            forWhat: ContractsEnum.CHARACTERS,
          });
          if (contract == null || contract == undefined) {
            dispatch(setOpenLoader(false));
            return;
          }
          //console.log("Contract for clicked charcter is ");
          //console.log({ contract });
          if (
            contract != null &&
            contract != undefined &&
            tokenId != null &&
            tokenId != undefined
          ) {
            console.log(
              "getting data for characterID" +
              contract?.contract?.CONTRACT +
              tokenId,
            );
            try {
              await ApiService.CharactersApi.getOne({
                tokenId: tokenId,
                characterId: contract?.contract?.CONTRACT + tokenId,
              }).then(async (response) => {
                // //console.log(response);
                //if (response) PopulateCharacterData(response);
                await dispatch(setSelectedCharacterData(response));
              });
            } catch (error) {
              dispatch(setOpenLoader(false));
              //console.log(error);
            }
          }
          await ApiService.PerksApi.getList({}).then((response) => {
            setLoading(true);
            setTimeout(() => {
              setPerks(response);
              setLoading(false);
            }, 3000);
          });
          await ApiService.QuestsApi.getList({
            walletAddress: address,
            nftTokenId: tokenId,
          }).then(async (response) => {
            await setQuests(response);
          });
          await dispatch(setDataRefetch());
        } catch (error) {
          //console.log("error in hometab: ", error);
        }
      }
    } catch (error) {
      //console.log(error);
    }
    dispatch(setOpenLoader(false));
  };
  useEffect(() => {
    const Run = async () => {
      dispatch(setOpenLoader(true));
      await new Promise((resolve) => setTimeout(resolve, 50));
      if (!selectedCharacterData?.tokenId) {
        dispatch(setOpenLoader(false));
        await SetImageUrl(characterLoading);
        return;
      }
      const meta: Item =
        await ApiService.CharactersApi.getCharacterMetaData({
          tokenId: selectedCharacterData?.tokenId ?? 0,
        });

      //console.log(meta);
      await dispatch(setMetadata(meta));
      await new Promise((resolve) => setTimeout(resolve, 200));
      const uniqueId = Math.random().toString(36).substring(20);
      // await SetImageUrl(`${meta.image}?timeStamp=${Date.now()}`);
      await SetImageUrl(characterLoading);
      //await new Promise((resolve) => setTimeout(resolve, 100));
      const imageUrl = meta.image + "?timeStamp=" + Date.now();
      // Create a new Request object with cache set to 'no-cache'
      const request = new Request(imageUrl, {
        cache: 'no-cache',
      });
      // Fetch the image with the cache set to 'no-cache'
      await fetch(request).then(async (response) => {
        if (response.ok) {
          // If the request is successful, set the image source to the fetched URL
          await SetImageUrl(imageUrl);
        }
      }).catch((error) => {
        console.error('Error fetching image:', error);
      });
      console.log("incrementing key", imageRef.current);
      imageRef.current += 3;
      dispatch(setOpenLoader(false));
      if (_characters.length <= 0) {
        navigate("/");
      }
    }
    Run();
  }, [dataRefetch])
  const navigate = useNavigate();
  const filterByType = (
    type: "attack" | "defence",
    array: Array<any>,
  ): Array<any> => {
    let arr: Array<any> = [];
    if (type === "attack") {
      array
        .filter((element) => element.includes("sw"))
        .forEach((element) => {
          arr.push(element);
        });
    } else if (type === "defence") {
      array
        .filter((element) => element.includes("he"))
        .forEach((element) => {
          arr.push(element);
        });
      array
        .filter((element) => element.includes("ar"))
        .forEach((element) => {
          arr.push(element);
        });
    }
    return arr;
  };

  const handleMint = () => {
    SetShowMintOptions(!showMintOptions);
    ////console.log("minting item");
    // mintNewSword(address).then((response) => {
    //   // //console.log({ response });
    // });
    // ApiService.EquipmentsApi.mint({ level });
  };
  useEffect(() => {
    SetImageUrl(characterLoading);
  }, [])
  useEffect(() => {
    const FetchAllEquipedItems = async () => {
      if (selectedCharacter === null || selectedCharacter == undefined) {
        dispatch(setOpenLoader(false));
        return;
      }
      const response = await EquipmentsApi.allEquipedItems(
        {
          ids: selectedCharacterData?.equipments as string[]
        });
      if (response) {
        await SetListofEquipedItems(response?.data);
      }
    }
    FetchAllEquipedItems();
  }, [selectedCharacterData]);
  useEffect(() => {
    const Run = async () => {
      const attackFiltered = await _.filter(listOfEquipedItems, x => x.shortname.includes("sw"))
      await SetAttackItems(attackFiltered);
      //console.log(attckItems);
      const defenceFiltered = await _.filter(listOfEquipedItems, x => !x.shortname.includes("sw"));
      await SetDefenceItems(defenceFiltered);
      //console.log(defenceItems);
    }
    Run();
  }, [listOfEquipedItems]);

  return (
    <>
      {address ? (
        <>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100vh",
              width: "100%",
            }}
          >
            {showItemDetailModal ? (
              <div className="">
                <ItemDetailModal />
              </div>
            ) : (
              <></>
            )}
          </div>
          {showMintOptions ? (
            <MintItemOptionsModal showMintOptions={handleMint} />
          ) : (
            <></>
          )}
          <div className="inventoryPage">
            <Navbar />
            <div className="fliterWraper">{filterModal && <FilterModal />}</div>
            <div className="inventoryPageSub row d-flex flex-row justify-content-evenly w-100 flex-wrap g-0 m-0">
              <div
                className="col-lg-6 col-md-12 col-sm-12 g-0 d-flex flex-column gap-2 justify-content-center align-items-center "
              // style={{ minWidth: "60vh" }}
              >
                <div className="row coverBg d-flex flex-grow-1 w-100">
                  <div className="col-3 d-flex flex-column align-items-center sideItems">
                    {attckItems ? (
                      attckItems.map((element: any, index: any) => {
                        //console.log("element is ", element);
                        return (
                          <div className="mb-3">
                            <ItemContainer
                              key={Date.now()}
                              height={"80px"}
                              width={"80px"}
                              element={element}
                              url={url}
                              setShowSelected={setShowSelected}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div className="d-flex flex-column col-6 gap-3">
                    <div className="characterSelect">
                      <RectangleHeader2
                        text={
                          selectedCharacter
                            ? "Dude # " + selectedCharacter?.toString()
                            : "Select Fighter"
                        }
                      />
                    </div>
                    <div className="charImg">
                      <img
                        className="modalCharacterImageInv"
                        src={((selectedCharacter < 0 || selectedCharacter === undefined || selectedCharacter === null) && imageUrl) ? MintNFT : imageUrl}
                        placeholder={characterLoading}
                        style={{ width: "300px", height: "auto" }}
                        alt="characterimg"
                      />
                      {selectedCharacter >= 0 ? (
                        <img
                          className="smoke d-flex flex-grow-1 h-100"
                          placeholder={characterLoading}
                          src={smoke || characterLoading}
                          alt="smoke"
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    {showAttributes && (
                      <div className="d-flex flex-row justify-content-center align-items-center gap-3">
                        <img
                          className="abilityImg"
                          src={bi_fire}
                          alt="attack"
                        />
                        <div className="abilityValue d-flex flex-column align-items-center justify-content-center">
                          <p className="attributeValue m-0 p-0">
                            {/* {metaData?.attributes[2] !== undefined ? (
                              metaData?.attributes[2].value
                            ) : (
                              <></>
                            )} */}
                            {selectedCharacterData?.attack ? (selectedCharacterData?.attack) : (<></>)}
                          </p>
                          <p className="m-0 p-0">
                            {/* {metaData?.attributes[2] !== undefined ? (
                              metaData?.attributes[2].trait_type
                            ) : (
                              <></>
                            )} */}
                            ATTACK
                          </p>
                        </div>
                        <img
                          className="abilityImg"
                          src={bi_defence}
                          alt="DEFENCE"
                        />
                        <div className="abilityValue d-flex flex-column align-items-center justify-content-center">
                          <p className="attributeValue m-0 p-0">
                            {/* {metaData?.attributes[3] !== undefined ? (
                              metaData?.attributes[3].value
                            ) : (
                              <></>
                            )} */}
                            {selectedCharacterData?.defence ? (selectedCharacterData?.defence) : (<></>)}
                          </p>
                          <p className="m-0 p-0">
                            {/* {metaData?.attributes[3] !== undefined ? (
                              metaData?.attributes[3].trait_type
                            ) : (
                              <></>
                            )} */}
                            DEFENCE
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-3 d-flex flex-column align-items-center sideItems">
                    {defenceItems ? (
                      defenceItems.map((element: any, index: any) => {
                        return (
                          <ItemContainer
                            height={"80px"}
                            width={"80px"}
                            key={Date.now()}
                            url={url}
                            element={element}
                            setShowSelected={setShowSelected}
                          />
                        );
                      })
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-center w-100">
                  <CharacterSlider
                    characters={_characters}
                    handleClick={handleCharacterSelect}
                  />
                </div>
              </div>

              <div className="px-4 col-sm-6 col-md-12 col-lg-6 d-flex flex-column justify-content-start align-items-center content-section">
                <div className="d-flex flex-row justify-content-center">
                  <ItemSearchBar setIsSearch={setIsSearch} text={"Items"} />
                </div>
                <div className="transparentBackground d-flex flex-row justify-content-center w-100">
                  {isSearch ? (
                    <div className="d-flex flex-row w-100">
                      <SearchBar close={setIsSearch} />
                    </div>
                  ) : (
                    <div className="dropdownDiv d-flex flex-column">
                      <div className="d-flex flex-row justify-content-end w-100 ">
                        <div className="questButton" onClick={handleMint}>
                          <button>Mint Options</button>
                        </div>
                      </div>
                      <DropDownMenu
                        text="Weapon"
                        setShowSelected={setShowSelected}
                        selectedArmory
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {showSelected && (
            <ShowSelectedArmory
              equipped={equipped}
              setEquipped={setEquipped}
              setEquippedResponse={setEquippedResponse}
              setShowSelected={setShowSelected}
              equipResponse={equipResponse}
            />
          )}
        </>
      ) : (
        <>
          <LoginScreen />
        </>
      )}
    </>
  );
};
