import React, { useEffect, useRef, useState } from "react";
import "./ItemContainer.css";
import { blankAbility, itemSearch } from "../../services/assets.service";
import { RootState } from "../../store/store";
import {
  setSelectedItem,
  setShowItemDetailModal,
} from "../../store/slices/general.slice";
import { Rating2 } from "../Rating2/Rating2";
import { useDispatch, useSelector } from "react-redux";
import { EquipmentsApi } from "../../apis/equipments.api";
import { ItemsEnum } from "../../utils/enums";
import _ from "lodash";
import axios from "axios";
import { GET } from "../../utils/axios.http";
type Props = {
  element: any;
  height: string;
  width: string;
  url: any;
  setShowSelected: any;
};

export default function ItemContainer({ element, height, width, url }: Props) {
  const dispatch = useDispatch();
  const dataRefetch = useSelector((state: RootState) => state.general.dataRefetch);
  const selectedItem = useSelector((state: RootState) => state.general.selectedItem);
  const address = useSelector((state: RootState) => state.user.address);
  const [level, SetLevel] = useState(0);
  const [imageUrl, SetImageUrl] = useState();

  const selectedItemData = useSelector(
    (state: RootState) => state.general.selectedItemData,
  );
  const handleClick = () => {
    if (element) {
      //console.log("Current Selected Item data = ", element);
      dispatch(setShowItemDetailModal(true));
      dispatch(setSelectedItem(element));
    }
  };

  useEffect(() => {
    //console.log({ selectedItem });
    //console.log({ selectedItemData });
  }, [selectedItem]);
  let tokenId: any;
  let itemDetails: any;
  const listOfItems: Array<ItemsEnum> = Object.values(ItemsEnum);
  useEffect(() => {
    const Start = async () => {
      const itemName: string =
        (await _.find(listOfItems, (x) =>
          String(element?.name)
            .toLowerCase()
            .includes(x),
        )) ?? "nan";
      //console.log(itemName);
      const number = element?.name.match(/\d+/g);
      if (number.length >= 1) {
        tokenId = number[0];
      }
      if (tokenId == null || tokenId < 0 || tokenId == undefined) {
        return;
      }
      try {
        itemDetails = await EquipmentsApi.itemDetails({
          walletaddress: address,
          shortname: element.shortname,
          tokenId: tokenId,
        });
        //console.log("Details are ", itemDetails?.data?.itemDetails?.level);
        try {
          const urlFixed = String(itemDetails?.data?.itemDetails?.uri).replace("https:nf", "https://nf");
          const { data } = await axios.get(urlFixed);
          if (data != null || data != undefined) {
            SetImageUrl(data.image);
          }
        } catch (error) {
          //console.log(error);
        }
        await SetLevel(
          itemDetails?.data?.itemDetails?.level == null || undefined
            ? 1
            : itemDetails?.data?.itemDetails?.level,
        );
      } catch (error) {
        //console.log(error);
      }
    };
    Start();
  }, [dataRefetch]);
  return (
    <>
      <div
        className={`cursor-pointer d-flex ${selectedItem?._id === element?._id ? "border-selected" : "border"
          }`}
        onClick={handleClick}
      >
        <div
          className="dropdown-item d-flex flex-column justify-content-end py-2"
          style={{
            backgroundImage: blankAbility,
            backgroundRepeat: "round",
            backgroundSize: "cover",
            height: `${height}`,
            width: `${width}`,
          }}
        >
          <img
            className="dropdown-img"
            src={imageUrl}
            alt=""
          />
          <Rating2 number={level} startHeight={12} startWidth={12} />
        </div>
      </div>
    </>
  );
}
