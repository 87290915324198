import { GET, PATCH, POST } from "../utils/axios.http";
import { local as env } from "../lib/env";
import { Urls } from "../services/contstants.service";
import { ApiUrls, AxiosService } from "../services/axios.service";

const base = env.HOST + "/characters";
class CharactersApiClient extends AxiosService {
  constructor() {
    super({ url: ApiUrls.Characters });
  }

  async update(
    data: { name: string; type: string; level: string },
    params: any,
  ): Promise<any> {
    try {
      return await PATCH(base + "/list", data, params);
    } catch (error) {
      //console.log(error);
    }
  }

  async addCharacterReward(
    data: { characterId: string },
    params: any,
  ): Promise<any> {
    try {
      return await PATCH(base + "/addCharacterReward", data, params);
    } catch (error) {
      //console.log(error);
    }
  }
  async nextLevelPreReq(params: any): Promise<any> {
    try {
      return await GET(base + "/nextLevelPreReq", params);
    } catch (error) {
      //console.log("error in getin next level reqs" + error);
    }
  }
  async incrementCharacterLevel(params: any): Promise<any> {
    try {
      return await PATCH(base + "/incrementCharacterLevel", params);
    } catch (error) {
      //console.log("error in increasing level");
    }
  }
  async mintItemPrereqs(params: any): Promise<any> {
    try {
      return await PATCH(base + "/mintItemPrereqs", params);
    } catch (error) {
      //console.log("error in getting requirements");
    }
  }
  async chargeMintCost(params: any): Promise<any> {
    try {
      return await PATCH(base + "/chargeMintCost", params);
    } catch (error) {
      //console.log("Error in charging for Mint");
    }
  }
  async create(data: {
    name: string;
    type: string;
    level: string;
  }): Promise<any> {
    try {
      return await POST(base + "/create", data);
    } catch (error) {
      //console.log(error);
    }
  }

  async getCharacterMetaData({ tokenId }: { tokenId: number }): Promise<any> {
    try {
      return await GET(Urls.AWS_BUCKET + tokenId + "?timeStamp=" + Date.now());
    } catch (error) {
      //console.log(error);
      return null;
    }
  }
}

const CharactersApi = new CharactersApiClient();
export { CharactersApi };
