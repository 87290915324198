import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type IRefreshSlice = {
  refreshChests: number;
  refreshCharacters: number;
  refreshMintedItems: number;
};
const initialState: IRefreshSlice = {
  refreshChests: 0,
  refreshCharacters: 0,
  refreshMintedItems: 0,
};

export const refreshSlice = createSlice({
  name: "refresh",
  initialState,
  reducers: {
    setRefreshChests: (state, action: PayloadAction<any>) => {
      state.refreshChests = action.payload;
    },
    setRefreshCharacters: (state, action: PayloadAction<any>) => {
      state.refreshCharacters = action.payload;
    },
    setRefreshMintedItems: (state, action: PayloadAction<any>) => {
      state.refreshMintedItems = action.payload;
    },
  },
});

export const { setRefreshChests, setRefreshCharacters, setRefreshMintedItems } =
  refreshSlice.actions;

export default refreshSlice.reducer;
