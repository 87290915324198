import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
// import { GetSwords } from "../GetSwords/GetSwords";
import { blankAbility } from "../../../services/assets.service";
import { noResult, metaMaskIcon } from "../../../services/assets.service";
import { Rating2 } from "../../../components/Rating2/Rating2";
import "./SearchBar.css";

type Prop = {
  close: React.Dispatch<React.SetStateAction<boolean>>;
};
export const SearchBar: React.FC<Prop> = ({ close }) => {
  const [swords, setSwords] = useState<any | null>(null);
  const [filteredSwords, setFilteredSwords] = useState<any | null>(null);
  const [focused, setFocused] = useState<boolean>(false);

  const address = useSelector((state: RootState) => state.user.address);

  const [searchValue, setSearchValue] = useState("");
  const handleClickClose = () => {
    close(false);
  };
  const handleClearSearch = () => {
    setSearchValue("");
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const filter = () => {
    const arr = swords?.filter((sw: any) =>
      sw.name.toLowerCase().includes(searchValue.toLowerCase()),
    );
    setFilteredSwords(arr);
  };

  useEffect(() => {
    //console.log("useeffect 2");
    filter();
  }, [searchValue]);
  useEffect(() => {
    //console.log("useeffect 1");
    if (!!address && swords === null) {
      (async function () {
        let _nfts: any;
        try {
          // _nfts = await GetSwords(address);
          //console.log({ _nfts });
        } catch (error) {
          //console.log(error);
        }
        _nfts = [
          { id: 0, name: "sx1" },
          { id: 0, name: "sw2" },
          { id: 0, name: "sw3" },
          { id: 0, name: "sw4" },
          { id: 0, name: "sw5" },
          { id: 0, name: "sw6" },
          { id: 0, name: "sw7" },
        ];
        setSwords(_nfts);
      })();
    }
  }, [address]);
  return (
    <>
      <div className="searchBar d-flex flex-column align-items-center gap-2">
        <div className="d-flex flex-row justify-content-center align-items-center gap-2">
          <div className={`${focused ? "focusedInput" : undefined}`}>
            <input
              className="inputDiv"
              type="text"
              value={searchValue}
              onChange={handleSearch}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            />
          </div>
          <span className="cross-icon" onClick={handleClearSearch}></span>

          <div className="cancelButtonDiv">
            <button className="cancelButton" onClick={handleClickClose}>
              Cancel
            </button>
          </div>
        </div>

        {swords ? (
          <>
            <div className="resultHeading d-flex">
              <p>Search Results</p>
            </div>
            <div className="searchResults image-grid-container  d-flex justify-content-center w-100 gap-2 ">
              {filteredSwords?.length > 0 ? (
                filteredSwords?.map((sw: any, index: any) => {
                  return (
                    <>
                      <div
                        className="image-wrapper-search d-flex flex-column justify-content-end align-items-center "
                        key={index}
                        style={{
                          backgroundImage: blankAbility,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          height: 80,
                          width: 80,
                        }}
                      >
                        <img
                          className="characterimg-search"
                          key={index}
                          src={metaMaskIcon}
                          alt=""
                        />
                        <Rating2 number={1} startHeight={12} startWidth={12} />
                      </div>
                    </>
                  );
                })
              ) : (
                <div className="noResult d-flex flex-column">
                  <div>
                    <img src={noResult} alt="" />
                  </div>
                  <div className="noResultButton">
                    <button className="backButton" onClick={handleClickClose}>
                      Back
                    </button>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
