import Web3, { Transaction } from "web3";
import { InjectedConnector } from "@web3-react/injected-connector";
type defaultParams = {
  initializer?: any;
};

// config({ debug: true });
class Web3Service {
  instance: Web3;
  constructor() {
    this.instance = new Web3(window.ethereum);
    // config({ debug: true });
  }

  /**
   *
   * @param initializer default initializer is window.ethereum
   * @returns Web3 instance
   */
  getWeb3Instance({ initializer }: defaultParams): Web3 {
    return this.chooseInstance(initializer);
  }

  private chooseInstance(initializer: any): Web3 {
    return initializer ? new Web3(initializer) : this.instance;
  }

  toHex(value: number): string {
    return Web3.utils.toHex(value);
  }

  async sign({
    hash,
    address,
    initializer,
  }: defaultParams & { hash: string; address: string }): Promise<string> {
    // configDotenv({ debug: true });
    const key = process.env.REACT_APP_PRIVATE_KEY as string;
    //console.log("key: ", key);
    //console.log({ hash });
    return (await this.chooseInstance(initializer).eth.sign(
      hash,
      address,
    )) as string;
  }

  utf8ToHex({ value, initializer }: defaultParams & { value: string }): string {
    return this.chooseInstance(initializer).utils.utf8ToHex(value);
  }

  async getChainId({ initializer }: defaultParams): Promise<any> {
    return await new Web3(initializer as any).eth.getChainId();
  }

  async sendTransaction({
    initializer,
    transaction,
  }: defaultParams & { transaction: Transaction }): Promise<any> {
    try {
      return await this.chooseInstance(initializer).eth.sendTransaction(
        transaction,
      );
    } catch (error) {
      //console.log("error sending transaction: ", error);
    }
  }

  async estimateGas({
    initializer,
    transaction,
  }: defaultParams & { transaction: any }): Promise<number | undefined> {
    const result =
      await this.chooseInstance(initializer).eth.estimateGas(transaction);
    return Number(await result);
  }

  getContract({
    initializer,
    abi,
    contractId,
  }: defaultParams & { abi: any; contractId: string | undefined }) {
    return new (this.chooseInstance(initializer).eth.Contract)(abi, contractId);
  }

  async getCurrentChainId(): Promise<number> {
    const currentChain: number = this.instance.utils.toNumber(
      await this.instance.eth.getChainId(),
    ) as number;
    return await currentChain;
  }
}
export { Web3Service };

// Connectors.tsx

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42],
});
