import { GET, PATCH } from "./axios.http";
import { local as env } from "../lib/env";
import { CheckNFTEquip } from "./types";
import { ApiService } from "../services/apis.service";

const BASE = env.HOST + "/inventory";
//EQUIP ITEM TO CHARACTER -- START
export async function equipItemAPI({
  id,
  shortname,
  uri,
  signednonce,
  walletaddress,
  tokenid,
}: {
  id: string;
  shortname: string;
  uri: string;
  signednonce: string;
  walletaddress: string;
  tokenid: any;
}): Promise<any> {
  return await ApiService.InventoryApi.equip({
    id,
    shortname,
    uri,
    signednonce,
    walletaddress,
    tokenid,
  });
}

//Check All User Equips
export async function getAllUserEquips(connectedwallet: string) {
  return await ApiService.InventoryApi.allequipped({
    connectedwallet: connectedwallet,
  });
}

//CHECK EQUIP ITEM VALUE IF ALREADY EQUIPPED OR NOT  -- START
export async function checkEquip(_shortcode: string): Promise<CheckNFTEquip> {
  return await GET(BASE + "/check/equip", { shortname: _shortcode });
}

export async function UnEquipItemAPI(
  id: string,
  shortname: string,
  uri: string,
  signednonce: string,
  walletaddress: string,
  tokenid: any,
): Promise<any> {
  return await PATCH(BASE + "/unequip", {
    id,
    shortname,
    uri,
    signednonce,
    walletaddress,
    tokenid,
  });
}
