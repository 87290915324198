import React from "react";
import Badge from "./Badge/Badge";

type Props = {
  badges: Array<"attack" | "defence" | "time">;
};

export default function Badges({ badges }: Props) {
  return (
    <div className="d-flex flex-row justify-content-evenly align-items-center w-100">
      {badges.map((badge, index) => {
        return (
          <div key={index}>
            <Badge badge={badge} text={"1"} />
          </div>
        );
      })}
    </div>
  );
}
