import { rectangleHeader2 } from "../services/assets.service";

interface Props {
  text: string;
}

export const RectangleHeader2: React.FC<Props> = ({ text }) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <img src={rectangleHeader2} alt="" />
      <div
        className="RectangleHeaderText2 myfont"
        style={{ marginTop: "-45px" }}
      >
        <p className="p-0 m-0">{text}</p>
      </div>
    </div>
  );
};
