import { Web3Service } from "../../services/web3.service";

export const typeToUrlMap: Record<string, string[]> = {
  CHARACTERS: ["https://bsc-testnet.publicnode.com"],
  SWORDS: ["https://polygon-mumbai-bor.publicnode.com"],
  ARMORS: [
    "https://endpoints.omniatech.io/v1/fantom/testnet/public",
    "https://rpc.ankr.com/fantom_testnet	",
  ],
  HELMETS: ["https://api.avax-test.network/ext/C/rpc"],
};

export const paymentForMint: number = 1;
export const nounceforlogin =
  "I Want To Login into The NFT Game using my account : ";
export const awsbaseuri = "https://nftgame-characters.s3.amazonaws.com/";
export const awsarmorsurl =
  "https://nftgame-characters.s3.amazonaws.com/armors-metadata/";
export const awshelmetsurl =
  "https://nftgame-characters.s3.amazonaws.com/helmets-metadata";
export const awsswordsurl =
  "https://nftgame-characters.s3.amazonaws.com/swords-metadata";

export function getEquipItemHash(
  shortcode: string,
  _uri: string,
  _connectedwallet: string,
  _selectedcharacter: string,
): string {
  const web3Service = new Web3Service();
  const nonceString =
    "I Want To Equip " +
    shortcode +
    " with my character nft " +
    _selectedcharacter +
    " using my account : " +
    _connectedwallet;

  //console.log({ nonceString });
  const hash = web3Service.getWeb3Instance({}).utils.keccak256(nonceString);
  //console.log("nonce generated here: ", hash);
  return hash;
}

//TEST NET

//TEST NET AND MAIN NET SAME //
export const awstype: Record<string, string> = {
  SWORDS: "swords-metadata/",
  ARMORS: "armors-metadata/",
  HELMETS: "helmets-metadata/",
};
