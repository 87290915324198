import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/user.slice";
import charactersSlice from "./slices/characters.slice";
import generalSlice from "./slices/general.slice";
import refreshSlice from "./slices/refresh.slice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import nftCharacterSlice from "./slices/nftCharacter.slice";

const persistConfig = {
  key: "root",
  storage,
};
const persistedUserReducer = persistReducer(persistConfig, userSlice);
const characterpersistedReducer = persistReducer(
  persistConfig,
  charactersSlice,
);
const generalReducer = persistReducer(persistConfig, generalSlice);
const nftCharacterReducer = persistReducer(persistConfig, nftCharacterSlice);
export const store = configureStore({
  reducer: {
    user: persistedUserReducer,
    updatecharacters: characterpersistedReducer,
    general: generalReducer,
    nftCharacter: nftCharacterReducer,
    refresh: refreshSlice,
  },
  middleware: [thunk],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
