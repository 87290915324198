import { store } from "../store/store";
import { setFilterModal } from "../store/slices/general.slice";
import {
  filterIcon,
  itemSearch,
  rectangleHeader,
} from "../services/assets.service";
interface Props {
  text: string;
  setIsSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ItemSearchBar: React.FC<Props> = ({ text, setIsSearch }) => {
  const handleClickFilterModal = () => {
    store.dispatch(setFilterModal(true));
  };
  const handleClickSearchBar = () => {
    setIsSearch(true);
  };
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <img className="" src={rectangleHeader} alt="Button" />
      <div
        className="bar-content myfont"
        style={{ backgroundImage: rectangleHeader, zIndex: 2 }}
      >
        <div className="itemDiv d-flex flex-row justify-content-evenly align-items-center gap-4">
          <img
            className="searchButton"
            src={itemSearch}
            alt="search-icon"
            onClick={handleClickSearchBar}
          />
          <p className="bar-text p-0 m-0">{text}</p>
          <img
            className="filterIcon"
            src={filterIcon}
            alt="filter"
            onClick={handleClickFilterModal}
          />
        </div>
      </div>
    </div>
  );
};
