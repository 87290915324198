import React from "react";
import { questCardAssets } from "../../../../../services/assets.service";
import "./Badge.css";
type Props = {
  text: string;
  badge: any;
};

export default function Badge({ badge, text }: Props) {
  return (
    <div className="d-flex flex-column">
      <div className="badge-background d-flex flex-row justify-content-center align-items-center">
        <img
          src={
            badge === "attack"
              ? questCardAssets.fire
              : badge === "defence"
              ? questCardAssets.sword
              : questCardAssets.timerIcon
          }
          alt=""
          width={`70%`}
          height={`70%`}
        />
      </div>
      <p className="badge-content">{text}</p>
    </div>
  );
}
