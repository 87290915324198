/* eslint-disable no-restricted-globals */
import React from "react";
import "./QuestSlider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import QuestCard from "../../pages/Quests/hooks/QuestCard/QuestCard";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedQuest } from "../../store/slices/general.slice";
import { RootState } from "../../store/store";

type Props = {
  quests: Array<object>;
  timer: any;
  escape: any;
  questRunning: any;
  questCompleted: any;
  updateQuests: any;
};

export const QuestSliderDesktop = ({
  quests,
  timer,
  escape,
  questCompleted,
  questRunning,
  updateQuests,
}: Props) => {
  const dispatch = useDispatch();
  const selectedCharacter = useSelector((state: RootState) => state.nftCharacter.selectedCharacter);
  const handleQuestSelection = (quest: number) => {
    dispatch(setSelectedQuest(quest));
    try {
      localStorage.setItem(
        "questIndex",
        JSON.stringify({
          selectedQuest: quest,
        }),
      );
    } catch (error) {
      //console.log(error);
    }
  };
  return (
    <Swiper
      modules={[EffectCoverflow]}
      grabCursor={true}
      effect="coverflow"
      centeredSlides={true}
      slidesPerView={3}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 10,
        modifier: 1,
        slideShadows: true,
      }}
      className="swiper my-4 py-4"
      onSlideChange={() => {
        //console.log("here");
      }}
      draggable={true}
    >
      {(quests && quests.length > 0) ? (
        quests.map((quest: any, index: number) => {
          return (
            <SwiperSlide>
              <QuestCard
                questCompleted={questCompleted}
                questRunning={questRunning}
                escape={escape}
                reward={10}
                quest={quest}
                handleQuestSelect={() => {
                  handleQuestSelection(index);
                }}
                index={index}
                timer={timer}
                updateQuests={updateQuests}
              />
            </SwiperSlide>
          );
        })
      ) : (
        <>
          {selectedCharacter ?
            <div>
              <h3 className="myfont-white">No Quests available for selected character !<br /> Check in 24 hours</h3>
            </div> : <></>}
        </>
      )}
    </Swiper>
  );
};
