// import fireSilver from "../assets/quests/fire-silver.png";
//Replacing local to bucket urls
import transferButton from "../assets/transferButton.png";
import levelUpButton from "../assets/levelUpButton.png";
import defaultitem from "../assets/shoulders-2-1.png";
import blankAbility from "../assets/blankAbility.png";
import charcaterBackground from "../assets/Characterbackground.png";
import characterLoading from "../assets/ch_loading.png";
import defaultimage from "../assets/default.png";

//import card1 from "../assets/quests/card1.png";
const card1 =
  "https://nftgame-characters.s3.amazonaws.com/assets/quests/card1.png";
//import card2 from "../assets/quests/card2.png";
const card2 =
  "https://nftgame-characters.s3.amazonaws.com/assets/quests/card2.png";
//import card3 from "../assets/quests/card3.png";
const card3 =
  "https://nftgame-characters.s3.amazonaws.com/assets/quests/card3.png";
//import sword from "../assets/quests/sword.png";
const sword =
  "https://nftgame-characters.s3.amazonaws.com/assets/quests/sword.png";
//import fire from "../assets/quests/fire.png";
const fire =
  "https://nftgame-characters.s3.amazonaws.com/assets/quests/fire.png";
//import lock from "../assets/quests/lock.png";
const lock =
  "https://nftgame-characters.s3.amazonaws.com/assets/quests/lock.png";
//import timerIcon from "../assets/quests/timer-icon.png";
const timerIcon =
  "https://nftgame-characters.s3.amazonaws.com/assets/quests/timer-icon.png";
//import fireRing from "../assets/quests/fire-ring.png";
const fireRing =
  "https://nftgame-characters.s3.amazonaws.com/assets/quests/fire-ring.png";
//import xpCoin from "../assets/quests/xpcoin.png";
const xpCoin = "https://nftgame-characters.s3.amazonaws.com/assets/xpcoin.png";
//import fireIcon from "../assets/fireVector.png";
const fireIcon =
  "https://nftgame-characters.s3.amazonaws.com/assets/fireVector.png";
//import xpcoin from "../assets/xpcoin.png";
const xpcoin = "https://nftgame-characters.s3.amazonaws.com/assets/xpcoin.png";
//import chfram from "../assets/framecharacter.png";
const chfram =
  "https://nftgame-characters.s3.amazonaws.com/assets/framecharacter.png";
//import chsel from "../assets/selectedChar.png";
const chsel =
  "https://nftgame-characters.s3.amazonaws.com/assets/selectedChar.png";
//import expIcon from "../assets/expIcon.png";
const expIcon =
  "https://nftgame-characters.s3.amazonaws.com/assets/expIcon.png";
//import barBg from "../assets/barBackground.png";
const barBg =
  "https://nftgame-characters.s3.amazonaws.com/assets/barBackground.png";
//import closeIcon from "../assets/closeIcon.svg";
const closeIcon =
  "https://nftgame-characters.s3.amazonaws.com/assets/closeIcon.svg";
//import rectangleHeader from "../assets/rectangleHeader.png";
const rectangleHeader =
  "https://nftgame-characters.s3.amazonaws.com/assets/rectangleHeader.png";
//import rectangleHeader2 from "../assets/SimpleFrame.png";
const rectangleHeader2 =
  "https://nftgame-characters.s3.amazonaws.com/assets/SimpleFrame.png";
//import itemSearch from "../assets/itemSearch.svg";
const itemSearch =
  "https://nftgame-characters.s3.amazonaws.com/assets/itemSearch.svg";
//import filterIcon from "../assets/filterIcon.svg";
const filterIcon =
  "https://nftgame-characters.s3.amazonaws.com/assets/filterIcon.svg";
//import collapsedArrow from "../assets/collapsedArrow.png";
const collapsedArrow =
  "https://nftgame-characters.s3.amazonaws.com/assets/collapsedArrow.png";
//const defaultimage =
//  "https://nftgame-characters.s3.amazonaws.com/assets/default.png";
//import XPLOGO from "../assets/xp-rush-logo.png";
const XPLOGO =
  "https://nftgame-characters.s3.amazonaws.com/assets/xp-rush-logo.png";
//import smallmeta from "../assets/smallmeta.png";
const smallmeta =
  "https://nftgame-characters.s3.amazonaws.com/assets/smallmeta.png";
//import xpnet from "../assets/xpnet.png";
const xpnet = "https://nftgame-characters.s3.amazonaws.com/assets/xpnet.png";
//import headermenu from "../assets/headerMenu.png";
const headermenu =
  "https://nftgame-characters.s3.amazonaws.com/assets/headerMenu.png";
//import MetaMaskImage from "../assets/MetaMaskButton.png";
const MetaMaskImage =
  "https://nftgame-characters.s3.amazonaws.com/assets/MetaMaskButton.png";
//import WalletConnectImage from "../assets/WalletConnectButton.png";
const WalletConnectImage =
  "https://nftgame-characters.s3.amazonaws.com/assets/WalletConnectButton.png";
//import bi_fire from "../assets/bi_fire.png";
const bi_fire =
  "https://nftgame-characters.s3.amazonaws.com/assets/bi_fire.png";
//import bi_defence from "../assets/bi_defence.png";
const bi_defence =
  "https://nftgame-characters.s3.amazonaws.com/assets/bi_defence.png";
//import unfilledStar from "../assets/unfilledStar.png";
const unfilledStar =
  "https://nftgame-characters.s3.amazonaws.com/assets/unfilledStar.png";
//import filledStar from "../assets/filledStar.png";
const filledStar =
  "https://nftgame-characters.s3.amazonaws.com/assets/filledStar.png";
//import noResult from "../assets/NoSearchResult.png";
const noResult =
  "https://nftgame-characters.s3.amazonaws.com/assets/NoSearchResult.png";
//import metaMaskIcon from "../assets/smallmeta.png";
const metaMaskIcon =
  "https://nftgame-characters.s3.amazonaws.com/assets/smallmeta.png";
//import coins from "../assets/coins.png";
const coins = "https://nftgame-characters.s3.amazonaws.com/assets/coins.png";
//import attSymbol from "../assets/attSymbol.png";
const attSymbol =
  "https://nftgame-characters.s3.amazonaws.com/assets/attSymbol.png";
//import weapon from "../assets/weapons.png";
const weapon = "https://nftgame-characters.s3.amazonaws.com/assets/weapons.png";
//import cross from "../assets/+.png";
const cross = "https://nftgame-characters.s3.amazonaws.com/assets/%2B.png";
//import attack from "../assets/hand.png";
const attack = "https://nftgame-characters.s3.amazonaws.com/assets/hand.png";
//import weaponQ from "../assets/weaponQ.png";
const weaponQ =
  "https://nftgame-characters.s3.amazonaws.com/assets/weaponQ.png";
//import armorQ from "../assets/armorQ.png";
const armorQ = "https://nftgame-characters.s3.amazonaws.com/assets/armorQ.png";
//import rect1 from "../assets/rect1.png";
const rect1 = "https://nftgame-characters.s3.amazonaws.com/assets/rect1.png";
//import rect2 from "../assets/rect2.png";
const rect2 = "https://nftgame-characters.s3.amazonaws.com/assets/rect2.png";
//import rect3 from "../assets/rect3.png";
const rect3 = "https://nftgame-characters.s3.amazonaws.com/assets/rect3.png";
//import info from "../assets/info.png";
const info = "https://nftgame-characters.s3.amazonaws.com/assets/info.png";
//import noticeBackground from "../assets/notice-background.png";
const noticeBackground =
  "https://nftgame-characters.s3.amazonaws.com/assets/notice-background.png";
//import arrowRight from "../assets/arrow-right.png";
const arrowRight =
  "https://nftgame-characters.s3.amazonaws.com/assets/arrow-right.png";
//import arrowRightHover from "../assets/arrow-right-hover.png";
const arrowRightHover =
  "https://nftgame-characters.s3.amazonaws.com/assets/arrow-right-hover.png";
//import smoke from "../assets/smoke.png";
const smoke = "https://nftgame-characters.s3.amazonaws.com/assets/smoke.png";
//import indicator from "../assets/indicator.png";
const indicator =
  "https://nftgame-characters.s3.amazonaws.com/assets/indicator.png";
//import blueRect from "../assets/BlueRectangle.png";
const blueRect =
  "https://nftgame-characters.s3.amazonaws.com/assets/BlueRectangle.png";
//import fireBorder from "../assets/fireBorder.png";
const fireBorder =
  "https://nftgame-characters.s3.amazonaws.com/assets/fireBorder.png";
//import MintNFT from "../assets/inventoryPageLock.png";
const MintNFT =
  "https://nftgame-characters.s3.amazonaws.com/assets/inventoryPageLock.png";

// import card1 from '../assets/quests/card1.png'
// import card1 from '../assets/quests/card1.png'

const chest1 = "https://nftgame-characters.s3.amazonaws.com/chests/chest1.png";
const chest2 = "https://nftgame-characters.s3.amazonaws.com/chests/chest2.png";
const chest3 = "https://nftgame-characters.s3.amazonaws.com/chests/chest3.png";

const questCard1 =
  "https://nftgame-characters.s3.amazonaws.com/cards/card1.png";
const questCard2 =
  "https://nftgame-characters.s3.amazonaws.com/cards/card2.png";
const questCard3 =
  "https://nftgame-characters.s3.amazonaws.com/cards/card3.png";

const questCards = [card1, card2, card3];

const questCardAssets = {
  card1,
  card2,
  card3,
  fire,
  sword,
  lock,
  timerIcon,
  fireRing,
  xpCoin,
};
export {
  charcaterBackground,
  characterLoading,
  blueRect,
  chest1,
  chest2,
  chest3,
  card1,
  card2,
  card3,
  fireBorder,
  questCards,
  questCardAssets,
  defaultimage,
  closeIcon,
  xpnet,
  noResult,
  metaMaskIcon,
  coins,
  weapon,
  cross,
  attSymbol,
  unfilledStar,
  filledStar,
  smallmeta,
  XPLOGO,
  headermenu,
  collapsedArrow,
  MintNFT,
  chsel,
  chfram,
  barBg,
  bi_defence,
  bi_fire,
  xpcoin,
  MetaMaskImage,
  WalletConnectImage,
  rectangleHeader,
  rectangleHeader2,
  itemSearch,
  filterIcon,
  fireIcon,
  expIcon,
  rect1,
  rect2,
  rect3,
  info,
  noticeBackground,
  smoke,
  arrowRight,
  arrowRightHover,
  indicator,
  attack,
  weaponQ,
  armorQ,
  transferButton,
  levelUpButton,
  defaultitem,
  blankAbility,
};
