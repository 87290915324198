interface CHAINS {
  NAME: string;
  CONTRACT: string;
  NOUNCE: number;
}

const ELROND: CHAINS = {
  NAME: "ELROND",
  CONTRACT: "erd1qqqqqqqqqqqqqpgq3y98dyjdp72lwzvd35yt4f9ua2a3n70v0drsfycvu8",
  NOUNCE: 2,
};
const BSC: CHAINS = {
  NAME: "BSC",
  CONTRACT: "0x0B7ED039DFF2b91Eb4746830EaDAE6A0436fC4CB",
  NOUNCE: 4,
};
const ETHEREUM: CHAINS = {
  NAME: "ETHEREUM",
  CONTRACT: "0x1cC24128C04093d832D4b50609e182ed183E1688",
  NOUNCE: 5,
};
const AVALANCHE: CHAINS = {
  NAME: "AVALANCHE",
  CONTRACT: "0xC254a8D4eF5f825FD31561bDc69551ed2b8db134",
  NOUNCE: 6,
};
const POLYGON: CHAINS = {
  NAME: "POLYGON",
  CONTRACT: "0x14CAB7829B03D075c4ae1aCF4f9156235ce99405",
  NOUNCE: 7,
};

const FANTOM: CHAINS = {
  NAME: "FANTOM",
  CONTRACT: "0x97dd1B3AE755539F56Db8b29258d7C925b20b84B",
  NOUNCE: 8,
};
const MOONBEAM: CHAINS = {
  NAME: "MOONBEAM",
  CONTRACT: "0x1694BcD5339C5120c7ee9427E48DCFEF2f0067D2",
  NOUNCE: 9,
}
const chains = [ELROND, BSC, ETHEREUM, AVALANCHE, POLYGON, FANTOM, MOONBEAM];
export default chains;
