import {
  AppConfigs,
  ChainFactory,
  ChainFactoryConfigs,
  ChainParams,
} from "xp.network";

//TESTNET

const ENVIROMENT: "TEST" | "PROD" = "TEST";
export async function mainnetConfigParams(): Promise<Partial<ChainParams>> {
  if (ENVIROMENT === "TEST") return await ChainFactoryConfigs.TestNet();
  else return await ChainFactoryConfigs.MainNet();
}

export async function ChainFactoryConfig(): Promise<ChainFactory> {
  if (ENVIROMENT === "TEST") {
    const mainnetConfig = await ChainFactoryConfigs.TestNet();
    return ChainFactory(AppConfigs.TestNet(), mainnetConfig);
  } else {
    const mainnetConfig = await ChainFactoryConfigs.MainNet();
    return ChainFactory(AppConfigs.MainNet(), mainnetConfig);
  }
}

//TESTNET

//MainNET

// export async function mainnetConfigParams():Promise<Partial<ChainParams>>{
//     return await ChainFactoryConfigs.MainNet();
// }
// export async function ChainFactoryConfig():Promise<ChainFactory>{
//     const mainnetConfig = await ChainFactoryConfigs.MainNet();
//     return ChainFactory(AppConfigs.MainNet(),mainnetConfig);
// }

//MainNet
