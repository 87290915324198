import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Character, Item, Nfts } from "../../utils/types";

type Initial = {
  characters: Array<any>;
  selectedCharacter: number | any;
  selectedCharacterData: Character | undefined;
  metaData: {
    name: string;
    description: string;
    image: string;
    attributes: Array<any>;
  };
  listOfWrappedNfts: Array<Nfts>;
};

export const nftCharacterSlice = createSlice({
  name: "nftCharacter",
  initialState: {
    characters: [],
    selectedCharacter: -1,
    selectedCharacterData: undefined,
    metaData: {
      name: "",
      description: "",
      image: "",
      attributes: [],
    },
    listOfWrappedNfts: []
  } as Initial,
  reducers: {
    setCharacters: (state, action: PayloadAction<number[]>) => {
      state.characters = action.payload;
    },
    setSelectedCharacterData: (state, action: PayloadAction<any>) => {
      state.selectedCharacterData = action.payload;
    },
    setMetadata: (state, action: PayloadAction<Item>) => {
      state.metaData = action.payload;
    },
    setSelectedCharacter: (
      state,
      action: PayloadAction<number | null | undefined>,
    ) => {
      state.selectedCharacter = action.payload;
    },
    SetListOfWrappedNft: (
      state,
      action: PayloadAction<Array<Nfts>>
    ) => {
      state.listOfWrappedNfts = action.payload
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setCharacters,
  setMetadata,
  setSelectedCharacter,
  setSelectedCharacterData,
  SetListOfWrappedNft,
} = nftCharacterSlice.actions;

export default nftCharacterSlice.reducer;
