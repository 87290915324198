import axios from "axios";
const instance = axios.create({ baseURL: "" });

async function GET(url: string, params?: any, headers?: any) {
  try {
    const { data } = await instance.get(url, { params, headers });
    return data;
  } catch (error) {
    //console.log(error);
    return null;
  }
}

async function POST(url: string, data?: any, headers?: any) {
  try {
    const { data: respData } = await instance.post(url, data, {
      headers,
      data: data,
    });
    return await respData;
  } catch (error) {
    //console.log(error);
    return null;
  }
}

async function PATCH(
  url: string,
  data?: any,
  params?: any,
  headers?: any
): Promise<any> {
  try {
    return await instance.patch(url, data, { headers, params });
  } catch (error) {
    //console.log(error);
    return null;
  }
}

async function DELETE(url: string, params?: any, headers?: any) {
  try {
    return await instance.delete(url, { params, headers });
  } catch (error) {
    //console.log(error);
    return null;
  }
}

export { GET, POST, PATCH, DELETE };
