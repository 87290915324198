import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: number[] = [4, 5, 6];

export const characterSlice = createSlice({
  name: "addcharacters",
  initialState,
  reducers: {
    updateCharacter: (state, action: PayloadAction<number[]>) => {
      // state.push(action.payload)
      state = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateCharacter } = characterSlice.actions;

export default characterSlice.reducer;
