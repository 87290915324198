import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import "./Question.css";
import { Faq } from "../../../utils/types";

type Props = {
  question: Faq;
};

export function Question({ question }: Props) {
  const [show, setShow] = useState<boolean>(false);
  return (
    <div className="cursor-pointer m-5" onClick={() => setShow(!show)}>
      <div className="d-flex w-100 ">
        <p className="myfont-white text-capitalize h4">{question.question}</p>
      </div>
      <Collapse in={show}>
        <div id="d-flex answer1">
          <p className="myfont text-start">{question.answer}</p>
        </div>
      </Collapse>
    </div>
  );
}
