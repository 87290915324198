import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import "./navbar.css";
import { Link, useLocation } from "react-router-dom";
import { getXPNETTokensBalance } from "../../utils/API";
import * as userSlice from "../../store/slices/user.slice";
import {
  XPLOGO,
  headermenu,
  smallmeta,
  xpnet,
} from "../../services/assets.service";
import _ from "lodash";
export const Navbar: React.FC = () => {
  const address = useSelector((state: RootState) => state.user.address);
  const selectedCharacterData = useSelector(
    (state: RootState) => state.nftCharacter.selectedCharacterData,
  );
  const dataRefetch = useSelector((state: RootState) => state.general.dataRefetch);
  const [userbalanceXPNET, setuserBalance] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const firstSevenChars = address.slice(0, 7);
  const lastFourChars = address.slice(-4);
  const dispatch = useDispatch();
  const formattedHexNumber = `${firstSevenChars}...${lastFourChars}`;

  function handleClick() {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    if (address) {
      getXPNETTokensBalance(address).then((v) => {
        setuserBalance(Number(v));
      });
    }
  }, [address, dataRefetch]);
  const location = useLocation();
  const path = location.pathname;
  const getNavClass = (path_: string) =>
    path === path_ ? "navlink navlink-active" : "navlink";

  const array = [
    {
      title: "Fighters",
      url: "/hometab",
    },
    {
      title: "Inventory",
      url: "/inventory",
    },
    {
      title: "Quests",
      url: "/quests",
    },
    {
      title: "Market",
      url: "/market",
    },
    {
      title: "FAQ",
      url: "/faq",
    },
  ];

  return (
    <>
      {address ? (
        <div className="mainnav">
          <div className="NavBar">
            <div className="nav_buttons" key={"nav_buttons"}>
              <img className="navLogo" src={XPLOGO} alt="" />
              <ul className="navigationbar" key={"navigationbar"}>
                {array.map((element, index) => {
                  return (
                    <li key={index}>
                      <Link
                        className={getNavClass(element.url)}
                        key={element.url}
                        to={element.url}
                      >
                        {element.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="xpnetmeta">
              <div className="xpnetssection">
                <img className="xpnetimage" src={xpnet} alt=""></img>
                <div className="XPNETS myfont">
                  {/* {(userbalanceXPNET / 1e18)
                    .toFixed()
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "} */}
                  {_.toSafeInteger(userbalanceXPNET)} XPNET
                </div>
              </div>
              <div className="metasection">
                <div>
                  <img className="smallmetaimage" src={smallmeta} alt=""></img>
                </div>
                <div className="connectedaddress myfont">
                  {formattedHexNumber}
                </div>
              </div>
              <div className="menuheader">
                <input
                  type="image"
                  onClick={handleClick}
                  src={headermenu}
                  alt="Submit"
                ></input>
              </div>
            </div>
          </div>
          {isOpen && (
            <div className="dropdown-menu-m">
              <ul className="dropdownitems">
                <li style={{ listStyle: "none" }}>
                  <div className="metasectiondrop" style={{ display: "none" }}>
                    <img
                      className="smallmetaimagedrop"
                      src={smallmeta}
                      alt=""
                    ></img>
                    <div className="connectedaddressdrop myfont">
                      {formattedHexNumber}
                    </div>
                  </div>
                </li>
                <li style={{ listStyle: "none" }}>
                  <div
                    className="xpnetssectiondrop"
                    style={{ display: "none" }}
                  >
                    <img className="xpnetimagedrop" src={xpnet} alt=""></img>
                    <div className="XPNETSdrop myfont">{_.toSafeInteger(userbalanceXPNET)} XPNET</div>
                  </div>
                  <hr
                    className="hrLoginScreen"
                    style={{ marginBottom: "10px" }}
                  />
                </li>

                {array.map((element, index) => {
                  return (
                    <li key={index} style={{ listStyle: "none" }}>
                      <div
                        className="xpnetssectiondrop-nav"
                        style={{ display: "none" }}
                      >
                        <div className="XPNETSdrop myfont">
                          <Link
                            className={getNavClass(element.url)}
                            key={element.url}
                            to={element.url}
                          >
                            {element.title}
                          </Link>
                        </div>
                      </div>
                    </li>
                  );
                })}
                <li className="listforheader">
                  <div
                    className="disconnectButton"
                    onClick={() => {
                      //console.log("to disconnect");
                      dispatch(userSlice.setAddress(""));
                    }}
                  >
                    Disconnect
                  </div>
                </li>
              </ul>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
