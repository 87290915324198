/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./FilterModal.css";
import { Rating } from "../Rating/Rating";
import { store } from "../../../store/store";
import { setFilterModal } from "../../../store/slices/general.slice";
import { closeIcon } from "../../../services/assets.service";
type Prop = {
  // close: React.Dispatch<React.SetStateAction<boolean>>;
};
export const FilterModal: React.FC<Prop> = ({ }) => {
  // const [show, setShow] = useState<boolean>(false)
  const [classFilter, setClassFilter] = useState<any>();
  const [attribute, setAttribute] = useState<any>();
  const [level, setLevel] = useState<any>();

  const { filterModal } = store.getState().general;

  const classes = [
    {
      title: "Head",
    },
    {
      title: "Weapon",
    },
    {
      title: "Upper Body",
    },
    {
      title: "Lower Body",
    },
  ];
  const attributes = [
    {
      title: "Just Defence",
    },
    {
      title: "Just Attack",
    },
  ];
  const levels = [
    {
      title: "Common",
      rating: 1,
    },
    {
      title: "Epic",
      rating: 2,
    },
    {
      title: "Unique",
      rating: 3,
    },
    {
      title: "Legendary",
      rating: 4,
    },
  ];

  const handleClose = () => {
    store.dispatch(setFilterModal(false));
  };
  const handleApply = () => {
    const filters = {
      class: classFilter,
      attribute: attribute,
      level: level,
    };
    //console.log("filters: ", filters);
  };

  useEffect(() => {
    handleApply();
  }, [level, classFilter, attribute]);

  return (
    <>
      <div className="containerX">
        <div className="container-top d-flex flex-column justify-content-between">
          <div>
            <div className="filterTop">
              <span className="filterModalText myfont ">Filters</span>
              <img
                className="closeIcon"
                src={closeIcon}
                alt="close"
                onClick={handleClose}
              />
            </div>
            <hr className="hr" />
            <div>
              <h3 className="filterModalText myfont">Class</h3>
              <form>
                {classes.map((element: { title: string }, index: number) => {
                  return (
                    <div key={index} className="innerDiv py-2 text-primary">
                      <input
                        type="radio"
                        id="RadioButton"
                        name="Button"
                        value={element.title}
                        onChange={(e) => setClassFilter(e.target.value)}
                      />
                      <h2
                        className={`filterModalText myfont upperCase m-0 p-0 ${classFilter === element.title ? "text-white" : ""
                          }`}
                      >
                        {` ${element.title}`}
                      </h2>
                    </div>
                  );
                })}
              </form>
            </div>
            <hr className="hr"></hr>
            <div>
              <h2 className="filterModalText myfont">Attribute</h2>
              <form>
                {attributes.map((element: { title: string }, index: number) => {
                  return (
                    <div key={index} className="innerDiv py-2">
                      <input
                        type="radio"
                        id="RadioButton"
                        name="Button"
                        value={element.title}
                        onChange={(e) => setAttribute(e.target.value)}
                      />
                      <h2
                        className={`filterModalText myfont upperCase m-0 p-0 ${attribute === element.title ? "text-white" : ""
                          }`}
                      >
                        {` ${element.title}`}
                      </h2>
                    </div>
                  );
                })}
              </form>
            </div>
            <hr className="hr"></hr>
            <div>
              <h3 className="filterModalText myfont">Level</h3>
              <form>
                {levels.map(
                  (
                    element: { rating: number; title: string },
                    index: number,
                  ) => {
                    return (
                      <div
                        key={index}
                        className="innerDiv flex flex-row align-items-center py-2"
                      >
                        <input
                          type="radio"
                          id="RadioButton"
                          name="Button"
                          value={element.title}
                          onChange={(e) => setLevel(e.target.value)}
                        />
                        <Rating number={element.rating} />
                        <h2
                          className={`filterModalText myfont upperCase px-2 m-0 p-0 ${level === element.title ? "text-white" : ""
                            }`}
                        >
                          {element.title}
                        </h2>
                      </div>
                    );
                  },
                )}
              </form>
            </div>
          </div>
          <div className="buttonGroup">
            <button className="filterModalButton1" onClick={handleApply}>
              Apply
            </button>
            <button className="filterModalButton2" onClick={handleClose}>
              Discard
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
