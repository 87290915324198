import React from "react";
import "./QuestListItem.css";
import { expIcon, rect2 } from "../../../services/assets.service";
type Props = {
  quest: any;
  index: number;
};

export const QuestListItem: React.FC<Props> = ({ quest, index }: Props) => {
  return (
    <div
      className="d-flex flex-row justify-content-start align-items-start p-2 w-100 item cursor-pointer"
      style={{ backgroundImage: rect2 }}
    >
      <div className="pill d-flex flex-row align-items-center">
        <img src={expIcon} width="20px" height="20px" alt="" />
        <p className="myfont px-1 m-0">7%</p>
      </div>
    </div>
  );
};
