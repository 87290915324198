import React, {
  ComponentType,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { Loader } from "./Loader";
// import { Type } from "typescript";

// type Props = {
//   WrappedComponent: ComponentType;
// };

// function withLoader(props: Props & LoaderProps) {
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     // Simulate an asynchronous operation, like fetching data or any other async task
//     const fakeAsyncTask = setTimeout(() => {
//       setLoading(false);
//     }, 2000); // Simulating 2 seconds delay, replace this with your actual async task

//     return () => clearTimeout(fakeAsyncTask); // Clean up the timer on unmount
//   }, []);

//   return <>{loading ? <Loader /> : <props.WrappedComponent {...props} />}</>;
// }

// export { withLoader };

type Props = {
  loading: boolean;
};

export default function LoaderHOC({
  loading,
  children,
}: Props & PropsWithChildren) {
  return (
    <div className="m-0 p-0" style={{ width: "min-content" }}>
      {loading ? <Loader variant="warning" /> : <>{children}</>}
    </div>
  );
}
