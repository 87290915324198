import React, { useEffect, useState } from "react";
import "../../../Inventory/showEquipmentModal.css";
import "./ChestListComponent.css";
import { Chest } from "../../../../utils/types";
import { RaritiesEnum } from "../../../../utils/enums";
import { filterChests } from "../../helpers";
import { ChestList } from "../ChestList/ChestList";
type Props = {
  setSelected: any;
  chests: Array<Chest>;
};
export const ChestListComponent: React.FC<Props> = ({
  setSelected,
  chests,
}) => {
  const [commonChests, setCommonChests] = useState<Array<Chest>>([]);
  const [epicChests, setEpicChests] = useState<Array<Chest>>([]);
  const [rareChests, setRareChests] = useState<Array<Chest>>([]);
  const [legendaryChests, setLegendaryChests] = useState<Array<Chest>>([]);
  const [uniqueChests, setUniqueChests] = useState<Array<Chest>>([]);

  useEffect(() => {
    setCommonChests(
      filterChests({ chests: chests, rarity: RaritiesEnum.common }),
    );
    setEpicChests(filterChests({ chests: chests, rarity: RaritiesEnum.epic }));
    setLegendaryChests(
      filterChests({ chests: chests, rarity: RaritiesEnum.legendary }),
    );
    setRareChests(filterChests({ chests: chests, rarity: RaritiesEnum.rare }));
    setUniqueChests(
      filterChests({ chests: chests, rarity: RaritiesEnum.unique }),
    );

    //console.log({ chests });
    //console.log({ epicChests });
    //console.log({ legendaryChests });
    //console.log({ uniqueChests });
    //console.log({ rareChests });
    //console.log({ commonChests });
  }, []);

  return (
    <div className="d-flex flex-column align-items-center p-3 w-75">
      {commonChests.length > 0 ? (
        <ChestList
          rarity={RaritiesEnum.common}
          chests={commonChests}
          setSelected={setSelected}
        />
      ) : null}

      {epicChests.length > 0 ? (
        <ChestList
          chests={epicChests}
          rarity={RaritiesEnum.epic}
          setSelected={setSelected}
        />
      ) : null}

      {legendaryChests.length > 0 ? (
        <ChestList
          chests={legendaryChests}
          rarity={RaritiesEnum.legendary}
          setSelected={setSelected}
        />
      ) : null}

      {rareChests.length > 0 ? (
        <ChestList
          chests={rareChests}
          rarity={RaritiesEnum.rare}
          setSelected={setSelected}
        />
      ) : null}

      {uniqueChests.length > 0 ? (
        <ChestList
          chests={uniqueChests}
          rarity={RaritiesEnum.unique}
          setSelected={setSelected}
        />
      ) : null}

      {/* //   {commonChests.map((element: Chest, index: number) => {
      //     return (
      //       <>
      //         <ChestListItem
      //           chests={commonChests}
      //           element={element}
      //           key={index}
      //           setSelected={setSelected}
      //         />
      //       </>
      //     );
      //   })}
      // </> */}
      {/* 
      {epicChests.length > 0 ? (
        <>
          {epicChests.map((element: Chest, index: number) => {
            return (
              <>
                <ChestListItem
                  chests={epicChests}
                  element={element}
                  key={index}
                  setSelected={setSelected}
                />
              </>
            );
          })}
        </>
      ) : null}

      {legendaryChests.length > 0 ? (
        <>
          {legendaryChests.map((element: Chest, index: number) => {
            return (
              <>
                <ChestListItem
                  chests={legendaryChests}
                  element={element}
                  key={index}
                  setSelected={setSelected}
                />
              </>
            );
          })}
        </>
      ) : null}

      {rareChests.length > 0 ? (
        <>
          {rareChests.map((element: Chest, index: number) => {
            return (
              <>
                <ChestListItem
                  chests={rareChests}
                  element={element}
                  key={index}
                  setSelected={setSelected}
                />
              </>
            );
          })}
        </>
      ) : null}

      {uniqueChests.length > 0 ? (
        <>
          {uniqueChests.map((element: Chest, index: number) => {
            return (
              <>
                <ChestListItem
                  chests={uniqueChests}
                  element={element}
                  key={index}
                  setSelected={setSelected}
                />
              </>
            );
          })}
        </>
      ) : null} */}
    </div>
  );
};
