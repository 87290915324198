/* eslint-disable no-restricted-globals */
import React, { useState, useRef, useEffect } from "react";
import "./QuestSlider.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination } from "swiper";
import { cross } from "../../services/assets.service";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import QuestCard from "../../pages/Quests/hooks/QuestCard/QuestCard";
import { IndentStyle } from "typescript";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../store/store";
import { setDataRefetch, setOpenLoader, setSelectedQuest } from "../../store/slices/general.slice";
import { QuestSliderMobile } from "./QuestSliderMobile";
import { QuestSliderDesktop } from "./QuestSliderDesktop";
import { escape } from "lodash";
import * as _ from "lodash";
import { ApiService } from "../../services/apis.service";
import { StopQuest, startQuest } from "../../pages/Quests/helpers";
import { ContractsEnum } from "../../utils/enums";
import { toast } from "react-toastify";
type Props = {
  quests: Array<object>;
  handleInternet: any;
  updateQuests: any;
};

export const QuestSlider = ({
  quests,
  handleInternet,
  updateQuests,
}: Props) => {
  const dispatch = useDispatch();

  // const arr = quests;
  // arr.forEach((element, index) => {
  //   quests.push(arr[index]);
  // });
  // const isOnline = useSelector((state: RootState) => state.general.online);
  // const isOnlineref = useRef(isOnline);

  // useEffect(() => {
  //   isOnlineref.current = isOnline;
  //   if (isOnline) {
  //     if (localStorage.getItem("questData")) {
  //       timerFunction(0);
  //     }
  //     handleInternet(true);
  //   } else {
  //     handleInternet(false);
  //   }
  // }, [isOnline]);
  const listOfRewards: Array<string> = [
    "Chest16",
    "Chest30",
    "Chest1h",
    "Chest3h",
    "Chest6h",
    "xps",
    "experience",
  ];
  const [questRunning, setQuestRunning] = useState(false);
  const [questCompleted, setQuestCompleted] = useState(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  let questRunningObserver: boolean = false;
  const handleQuestSelection = (quest: number) => {
    dispatch(setSelectedQuest(quest));
  };

  let days = 0;
  let hours = 0;
  let minutes = 0;
  let seconds = 0;
  let x: NodeJS.Timer | null = null;
  const xRef = useRef<NodeJS.Timer | null>(null);
  const remainingTimeOfQuest = useRef(0);
  const selectedImage = useSelector(
    (state: RootState) => state.nftCharacter.selectedCharacter,
  );
  const selectedImageRef = useRef(selectedImage);

  const address = useSelector((state: RootState) => state.user.address);
  const addressRef = useRef(address);

  /**
   * Start "Timer" function for quest play
   * @param datestarted 
   * @param questTime 
   * @param toChain 
   * @param nftTokenId 
   * @param starthash 
   * @param questNo 
   * @returns 
   */
  const timerFunction = async (
    datestarted: string,
    questTime: number,
    toChain: string,
    nftTokenId: string,
    starthash: any,
    questNo: any
  ) => {
    let starTimeOfQuest: Date;
    let timeToEndQuest: number;
    dispatch(setOpenLoader(true));
    if (datestarted === null || datestarted === undefined) {
      try {
        if (selectedImageRef.current != undefined && nftTokenId != selectedImageRef.current) {
          await setQuestRunning(true);
          await new Promise((resolve) => setTimeout(resolve, 100));
          await setQuestRunning(false);
          await new Promise((resolve) => setTimeout(resolve, 100));
          //console.log("Quest is not for this character");
          toast("Quest does not belong to this character!", { theme: "dark" });
          return;
        }
        const response = await startQuest({
          walletAddress: address,
          toChain: toChain,
          requiredTime: questTime.toString(),
          nftTokenId: selectedImageRef.current.toString(),
        });
        console.log("Start Quest Wait end", response);
        if (response == 1) {
          try {
            await ApiService.QuestsApi.setStartTime({
              walletAddress: address,
              nftTokenId: selectedImageRef.current.toString(),
              datestarted: new Date().toString(),
              no: questNo
            });
            updateQuests();
            dispatch(setDataRefetch());
          } catch (error) {
            dispatch(setOpenLoader(false));
            //console.log(error);
          }
        }
        else {
          setQuestRunning(false);
          dispatch(setOpenLoader(false));
          return;
        }
      } catch (error) {
        setQuestRunning(false);
        dispatch(setOpenLoader(false));
        return;
        //console.log(error);
      }
      // if (response?.success) {
      // }
      datestarted = new Date().toString();
      starTimeOfQuest = new Date();
      starTimeOfQuest.setMinutes(starTimeOfQuest.getMinutes() + questTime);
      timeToEndQuest = starTimeOfQuest.getTime();
    } else {
      starTimeOfQuest = new Date(datestarted);
      starTimeOfQuest.setMinutes(starTimeOfQuest.getMinutes() + questTime);
      timeToEndQuest = starTimeOfQuest.getTime();
    }
    //Set Start Time
    await setQuestRunning(true);
    if (xRef.current) {
      clearInterval(xRef.current);
    }
    dispatch(setOpenLoader(false));
    // Update the count down every 1 second
    xRef.current = setInterval(async function () {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      //const online = useSelector((state: RootState) => state.general.online);
      var now = new Date().getTime();
      // Find the distance between now and the count down date
      var distance = timeToEndQuest - now;
      remainingTimeOfQuest.current = distance;
      // Time calculations for days, hours, minutes and seconds
      days = Math.floor(distance / (1000 * 60 * 60 * 24));
      hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      seconds = Math.floor((distance % (1000 * 60)) / 1000);
      let timer = document.getElementById("timer");
      if (timer) {
        timer.innerText = "" + hours + ":" + minutes + ":" + seconds;
      }
      if (distance <= 0) {
        toast("Quest completed!", { theme: "dark" });
        clearInterval(xRef.current != null ? xRef.current : "");
        await setQuestRunning(false);
        await setQuestCompleted(true);
        localStorage.removeItem("questData");
        RandomRewards(1);
        try {
          dispatch(setOpenLoader(true));
          await new Promise((resolve) => setTimeout(resolve, 3000));
          await StopQuest({
            walletAddress: address,
            _toChain: toChain,
            nftTokenId: nftTokenId,
            startHash: starthash,
          });
          dispatch(setOpenLoader(false));
        } catch (error) {
          console.log(error);
        }
        await ApiService.QuestsApi.setQuestClaimed({
          walletAddress: address,
          nftTokenId: nftTokenId,
          starthash: starthash,
          no: questNo
        });
        updateQuests();
        // document.getElementById("demo").innerHTML = "EXPIRED";
      } else {
        localStorage.removeItem("questData");
        localStorage.setItem(
          "questData",
          JSON.stringify({
            questId: nftTokenId,
            datestarted: datestarted,
            questTime: questTime,
            toChain: toChain,
            starthash: starthash,
          }),
        );
        // if (!isOnlineref.current) {
        //   clearInterval(xRef.current != null ? xRef.current : "");
        //   setQuestRunning(false);
        //   setQuestCompleted(false);
        //   localStorage.setItem(
        //     "questData",
        //     JSON.stringify({
        //       questId: "",
        //       remainingTime,
        //     })
        //   );
        // } else {
        //   localStorage.removeItem("questData");
        // }
      }
    }, 1000);
  };
  /**
   * Function to handle scenario user Ends quest before timer running out
   * @param totalTime 
   * @param questStarted 
   * @returns 
   */
  const EscapeInHurry = async (totalTime: number, questStarted: string) => {
    totalTime *= 1000 * 60;
    console.log(remainingTimeOfQuest.current);
    let passedTime = (totalTime - remainingTimeOfQuest.current);
    localStorage.removeItem("questData");
    localStorage.removeItem("questIndex");
    console.log(
      "Escape in Hurry Called Total time = " +
      totalTime +
      "passed Time = " +
      passedTime,
    );
    await setQuestRunning(false);
    await setQuestCompleted(true);
    clearInterval(xRef.current != null ? xRef.current : "");
    if (questStarted == null || questStarted == undefined) {
      return;
    }
    let multiplier: number = 1;
    let percentageCompleted = Math.floor((passedTime / totalTime) * 100);
    percentageCompleted = 100 - percentageCompleted;
    if (percentageCompleted < 3) {
      return;
    }
    //console.log("Percentage Completed = " + percentageCompleted);
    if (percentageCompleted > 0 && percentageCompleted <= 10) {
      multiplier = 0.1;
    }
    if (percentageCompleted > 10 && percentageCompleted <= 20) {
      multiplier = 0.2;
    }
    if (percentageCompleted > 20 && percentageCompleted <= 30) {
      multiplier = 0.3;
    }
    if (percentageCompleted > 30 && percentageCompleted <= 40) {
      multiplier = 0.4;
    }
    if (percentageCompleted > 40 && percentageCompleted <= 50) {
      multiplier = 0.5;
    }
    RandomRewards(multiplier);
  };
  //const [contractO, setContract0] = useState<any>(null);
  let contractO: any;
  useEffect(() => {
    selectedImageRef.current = selectedImage;
    addressRef.current = address;
  }, [address, selectedImage]);
  useEffect(() => {
    ApiService.ContractsApi.getOne({
      forWhat: ContractsEnum.CHARACTERS,
    }).then((contract) => {
      //setContract0(contract);
      contractO = contract;
      //console.log("contract Val" + contractO?.contract?.CONTRACT);
    });
  }, []);
  /**
   * Random rewards for Quest
   * @param multiplier 
   * @returns 
   */
  const RandomRewards = async (multiplier: number) => {
    let randomReward = await _.sample(listOfRewards);
    //console.log("" + contractO?.contract?.CONTRACT);
    if (
      contractO?.contract?.CONTRACT == null ||
      contractO?.contract?.CONTRACT == undefined
    ) {
      //console.log("Incomplete data for setting rewards");
      return;
    }
    switch (randomReward) {
      case "Chest16": {
        ApiService.ChestsApi.drop({
          walletAddress: addressRef.current,
          characterId: contractO?.contract?.CONTRACT + selectedImageRef.current,
          time: 16,
          multiplier: multiplier,
          characterTokenId: selectedImageRef.current,
          rarity: "common",
        });
        break;
      }
      case "Chest30": {
        ApiService.ChestsApi.drop({
          walletAddress: addressRef.current,
          characterId: contractO?.contract?.CONTRACT + selectedImageRef.current,
          time: 30,
          multiplier: multiplier,
          characterTokenId: selectedImageRef.current,
          rarity: "common",
        });
        break;
      }
      case "Chest1h": {
        ApiService.ChestsApi.drop({
          walletAddress: addressRef.current,
          characterId: contractO?.contract?.CONTRACT + selectedImageRef.current,
          time: 60,
          multiplier: multiplier,
          characterTokenId: selectedImageRef.current,
          rarity: "common",
        });
        break;
      }
      case "Chest3h": {
        ApiService.ChestsApi.drop({
          walletAddress: addressRef.current,
          characterId: contractO?.contract?.CONTRACT + selectedImageRef.current,
          time: 180,
          multiplier: multiplier,
          characterTokenId: selectedImageRef.current,
          rarity: "common",
        });
        break;
      }
      case "Chest6h": {
        ApiService.ChestsApi.drop({
          walletAddress: addressRef.current,
          characterId: contractO?.contract?.CONTRACT + selectedImageRef.current,
          time: 180,
          multiplier: multiplier,
          characterTokenId: selectedImageRef.current,
          rarity: "common",
        });
        break;
      }
      case "xps": {
        ApiService.CharactersApi.addCharacterReward(
          {
            characterId:
              contractO?.contract?.CONTRACT + selectedImageRef.current,
          },
          { xps: 10 },
        );
        break;
      }
      case "experience": {
        ApiService.CharactersApi.addCharacterReward(
          {
            characterId:
              contractO?.contract?.CONTRACT + selectedImageRef.current,
          },
          { experience: 10 },
        );
        break;
      }
    }
  };

  const updateScreenWidth = () => {
    setIsMobile(window.innerWidth < 900);
  };
  /**
   * Switch Slider between Desktop and mobile Views
   */
  useEffect(() => {
    if (window.innerWidth < 900) {
      setIsMobile(true);
    }
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  return (
    <div className="w-100">
      {window.innerWidth <= 900 ? (
        <QuestSliderMobile
          quests={quests}
          questRunning={questRunning}
          escape={EscapeInHurry}
          questCompleted={questCompleted}
          timer={timerFunction}
          updateQuests={updateQuests}
        />
      ) : window.innerWidth > 900 ? (
        <QuestSliderDesktop
          quests={quests}
          escape={EscapeInHurry}
          timer={timerFunction}
          questRunning={questRunning}
          questCompleted={questCompleted}
          updateQuests={updateQuests}
        />
      ) : null}
    </div>
  );
};
