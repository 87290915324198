import { useEffect, useRef } from 'react';


const capitalizeString = (str: string) => {
  if (!str || typeof str !== "string") {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const useDidMountEffect = (func: Function, deps: any[]) => {
  const didMount = useRef(false);
  useEffect(() => {
      if (didMount.current) func();
      else didMount.current = true;
  }, deps);
};

export { capitalizeString, useDidMountEffect };
