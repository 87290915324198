import React from "react";
import { capitalizeString } from "../../../../../utils/functions.utils";
import { imageAssigner } from "../../../helpers";
import { Chest } from "../../../../../utils/types";
import "./ChestListItem.css";
import { RaritiesEnum } from "../../../../../utils/enums";

type Props = {
  chests: Array<Chest>;
  rarity: RaritiesEnum;
  setSelected: any;
};

export default function ChestListItem({ chests, rarity, setSelected }: Props) {
  const handleSelect = (element: Chest) => {
    setSelected(element);
  };
  return (
    <div className="d-flex flex-row justify-content-between align-items-center my-2 w-100 bg-item p-3">
      <div className="d-flex flex-row gap-4 align-items-center w-100">
        <div className="border p-3 w-50">
          <img src={imageAssigner(rarity)} alt="img" width={50} height={50} />
          <p className="myfont-white m-0 p-0">{capitalizeString(rarity)}</p>
        </div>

        <div className="d-flex flex-row justify-content-between align-items-center w-100">
          <div className="d-flex flex-row justify-content-start flex-wrap gap-2">
            {chests.map((element: Chest, index) => {
              return (
                <div
                  key={index}
                  className="cursor-pointer"
                  onClick={() => {
                    handleSelect(element);
                  }}
                >
                  <img
                    src={imageAssigner(element.rarity)}
                    alt="img"
                    width={32}
                    height={32}
                  />
                </div>
              );
            })}
          </div>
          <div>
            <p className="myfont-white m-0 p-0">Total: {chests.length}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
