import React, { useEffect, useRef, useState } from "react";
import "./ItemDetailModal.css";
import {
  smallmeta,
  levelUpButton,
  transferButton,
  cross,
  defaultitem,
} from "../../services/assets.service";
import { RootState, store } from "../../store/store";
import { Button } from "react-bootstrap";
import {
  setDataRefetch,
  setOpenLoader,
  setSelectedItem,
  setShowItemDetailModal,
} from "../../store/slices/general.slice";
import { Rating2 } from "../Rating2/Rating2";
import { upgradeItem, getrequiredxpnetforupgrade } from "../../utils/API";
import { EquipItem } from "../../pages/Inventory/equipitem";
import { ApiService } from "../../services/apis.service";
// import { extractItemAttributesHeroku } from "./helpers";
import { ContractsEnum, ItemsEnum } from "../../utils/enums";
import _ from "lodash";
import { EquipmentsApi } from "../../apis/equipments.api";
import { useDispatch, useSelector } from "react-redux";
import { UpgradeItemXP } from "../../pages/Inventory/mintNewSword";
import { UnEquipItem } from "../../pages/Inventory/unequipitem";
import { GET } from "../../utils/axios.http";
import axios from "axios";
import { toast } from "react-toastify";

type Props = {
  // item: any;
  // setShow: any;
};

export default function ItemDetailModal({ }: Props) {
  const dispatch = useDispatch();
  const dataRefetch = useSelector((state: RootState) => state.general.dataRefetch);
  const { selectedItem, selectedItemData } = store.getState().general;
  const { selectedCharacter } = store.getState().nftCharacter;

  const [equipped, setEquipped] = useState<boolean>();
  const [item, setItem] = useState<any>(undefined);
  const [itemDetails, SetItemDetails] = useState<any>();
  const [level, SetLevel] = useState(1);
  const [imageUrl, SetImageUrl] = useState<string>("");
  const address = useSelector((state: RootState) => state.user.address);
  let currentlevel: any = 1;
  let itemrarity: any = "";
  let tokenId: number;
  const listOfItems: Array<ItemsEnum> = Object.values(ItemsEnum);
  const UpgradeItem = async () => {
    dispatch(setOpenLoader(true));
    if (selectedCharacter == null || selectedCharacter == undefined) {
      //console.log("Please select a charcter brfore upgrading item");
      toast("Select a charcter before proceeding!", { theme: "dark" });
      dispatch(setOpenLoader(false));
      return;
    }
    if (itemDetails == null || itemDetails == undefined) {
      toast("Unable to fetch Item details!", { theme: "dark" });
      //console.log("No Item Details are currently present");
      dispatch(setOpenLoader(false));
      return;
    }
    const itemName: string =
      (await _.find(listOfItems, (x) =>
        String(selectedItem.name).toLowerCase().includes(x),
      )) ?? "nan";
    if (itemName == null || itemName == undefined) {
      toast("Unable to Resolve Item details!", { theme: "dark" });
      dispatch(setOpenLoader(false));
      return;
    }
    const number = selectedItem.name.match(/\d+/g);
    const shortcode = itemName.toLowerCase().slice(0, 2) + number;
    //console.log(shortcode);
    const xpnetResponse = await getrequiredxpnetforupgrade({
      walletaddress: address,
      level: itemDetails.level,
      name: itemName,
      rarity: itemDetails?.rarity?.toLowerCase(),
    });
    if (xpnetResponse.xpnets == undefined || xpnetResponse.xpnets == null) {
      toast("Error in calculating required xpnets! Try again", { theme: "dark" });
      //console.log("failed to get required xpnets");
      dispatch(setOpenLoader(false));
      return;
    }
    //console.log(xpnetResponse);
    const contract = await ApiService.ContractsApi.getOne({
      forWhat: ContractsEnum.CHARACTERS,
    });
    if (contract == null || contract == undefined) {
      toast("Error in getting character token!", { theme: "dark" });
      //console.log("Contract is Unavailable");
      dispatch(setOpenLoader(false));
      return;
    }
    if (selectedCharacter == null || selectedCharacter == undefined) {
      toast("Select a charcter before proceeding!", { theme: "dark" });
      //console.log("CHarcter is not selected");
      dispatch(setOpenLoader(false));
      return;
    }
    //console.log(xpnetResponse.xpnets);
    const characterId =
      String(contract?.contract?.CONTRACT) + String(selectedCharacter);
    const upgrade = await UpgradeItemXP(
      itemDetails?.rarity?.toLowerCase,
      Number(itemDetails.level),
      itemName,
      tokenId,
      xpnetResponse.xpnets,
      address,
      characterId,
      shortcode,
    );
    // const upgradeResponce = await EquipmentsApi.upgradeItem_1({
    //   walletaddress: address,
    //   name: itemDetails.name,
    //   xpnet: xpnetResponse.xpnets,
    //   characterId: String(contract?.contract?.CONTRACT) + String(selectedCharacter)
    // });
    //console.log(upgrade);
    await SetLevel(upgrade.data.level);
    dispatch(setOpenLoader(false));
    RefetchData();
    //upgradeItem({ type: "", tokenId: 1, level: 1, rarity: "", requiredXpnetForNextLevel: 1, from: "" })
  };

  useEffect(() => {
    const FetchItemData = async () => {
      const itemName: string =
        (await _.find(listOfItems, (x) =>
          String(selectedItem.name).toLowerCase().includes(x),
        )) ?? "nan";
      //console.log(itemName);
      const number = selectedItem.name.match(/\d+/g);
      const shortcode = String(selectedItem.name).toLowerCase().slice(0, 2) + number;
      if (number.length >= 1) {
        tokenId = number[0];
      }
      if (tokenId == null || tokenId < 0 || tokenId == undefined) {
        toast("No item is selected!", { theme: "dark" });
        return;
      }
      if (address == null || address == undefined) {
        toast("Unable to resolve Wallet adress! Connect again.", { theme: "dark" });
        //console.log("Addrress is undefined");
        return;
      }
      let itemDetails: any;
      try {
        itemDetails = await EquipmentsApi.itemDetails({
          walletaddress: address,
          shortname: shortcode,
          tokenId: tokenId,
        });
        if (!itemDetails) {
          toast("Error in Finding Item Details!", { theme: "dark" });
          //console.log("Unable to Fetch Details of items");
          return;
        }
        await SetLevel(
          itemDetails?.data?.itemDetails?.level == null || undefined
            ? 1
            : itemDetails?.data?.itemDetails?.level,
        );
        await setEquipped(
          itemDetails?.data?.itemDetails?.equipped == true
            ? true
            : false,
        );
        try {
          const urlFixed = String(itemDetails?.data?.itemDetails?.uri).replace("https:nf", "https://nf");
          const { data } = await axios.get(urlFixed);
          //console.log(meta);
          if (data != null || data != undefined) {
            await SetImageUrl(data.image);
          }
        } catch (error) {

        }
      } catch (error) {
        //console.log(error);
      }
      //console.log("Details from database are ", itemDetails);
      if (itemDetails?.data?.itemDetails == null || itemDetails?.data?.itemDetails == null) {
        //console.log("Failed to get item record from database");
        toast("Could not fetch item details!", { theme: "dark" });
        return;
      }
      await SetItemDetails(itemDetails?.data?.itemDetails);
      console.log(itemDetails?.data?.itemDetails);
      if (selectedCharacter == null || selectedCharacter == undefined) {
        console.log("No Character is selected");
        console.log(itemDetails?.data?.itemDetails);
      }
      //Set data here;
    };
    FetchItemData();
    // //console.log({ selectedItem });
    // //console.log({ selectedItemData });
    // ApiService.EquipmentsApi.getAwsItem("sw", selectedItem).then((response) => {
    //   if (response) {
    //     setItem(response);
    //     ApiService.EquipmentsApi.getAwsItem("sw", selectedItem).then(
    //       (response) => {
    //         if (response) {
    //           setItem(response);
    //           //console.log("item details" + { response });
    //           currentlevel = response.attributes[0];
    //           itemrarity = response.attributes[1].toString();
    //           UpgradeItem();
    //         }
    //       }
    //     );

    //     //console.log("item details" + { response });
    //     currentlevel = response.attributes[0];
    //     itemrarity = response.attributes[1].toString();
    //     UpgradeItem();
    //   }
    // });

    // ApiService.EquipmentsApi.getAwsItem("sw", selectedItem).then((response) => {
    //   if (response) {
    //     setItem(response);
    //     //console.log("item details" + { response });
    //     currentlevel = (response?.attributes).find(
    //       (element: any) => element?.trait_type === "Level"
    //     ).value;
    //     itemrarity = (response?.attributes as Array<any>).find(
    //       (element) => element?.trait_type === "Rarity"
    //     ).value;
    //     UpgradeItem();
    //   }
    // });
  }, [dataRefetch]);
  const TransferNFT = () => {
    //get all 
    //console.log("Transfer Nft Is called");
  };
  const RefetchData = () => {
    dispatch(setDataRefetch());
  }
  // const itemAttributes = extractItemAttributesHeroku(selectedItem.attributes);
  return (
    <>
      {/* {item ? ( */}
      <div
      // style={{
      //   pointerEvents: "none",
      //   zIndex: -10,
      // }}
      >
        <div className="modal-background m-0 p-0">
          <div className="modal-body-m d-flex flex-column m-0 p-4 ">
            {/* title component */}
            <div className="d-flex flex-row justify-content-between align-items-start mx-3 my-2">
              <h1 className="myfont-white m-0 p-0">
                {selectedItem?.name ? selectedItem?.name : 0}
              </h1>
              <img
                src={cross}
                width={25}
                height={25}
                alt=""
                className="cursor-pointer"
                // onClick={() => setShow(false)}
                onClick={() => {
                  store.dispatch(setShowItemDetailModal(false));
                  store.dispatch(setSelectedItem(null));
                }}
              />
            </div>
            <div className="model-content-m">
              {/* body component */}
              <div className="d-flex flex-row flex-wrap  justify-content-between align-items-center w-100 m-0 p-0">
                {/* image component */}
                <div className="d-flex flex-column w-100 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="item-image d-flex flex-row justify-content-center align-items-center col-sm-12 col-md-12 col-lg-6 w-100 h-100 m-0 p-0 mb-2">
                    <img
                      src={imageUrl}
                      alt=""
                      width={"60%"}
                      height={"100%"}
                    />
                  </div>
                  <p className="myfont">{selectedItem.description}</p>
                </div>
                {/* details component */}
                <div className="d-flex container-fluid flex-column gap-2 col-sm-12 col-md-12 col-lg-6 px-3 ">
                  <div className="d-flex flex-column align-items-start section-container w-100">
                    <div className="d-flex flex-row justify-content-between w-100">
                      <p className="myfont">Level</p>
                      <div>
                        <Button variant="outline-light" onClick={UpgradeItem}>
                          Upgrade
                        </Button>
                        <img src={levelUpButton} alt="" />
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-start align-items-center gap-2">
                      <Rating2
                        number={level}
                        startWidth={22}
                        startHeight={22}
                      />
                      <p className="myfont-white m-0 p-0">Common</p>
                    </div>
                  </div>
                  <div className="d-flex flex-row justify-content-between gap-2">
                    <div className="d-flex flex-column justify-content-evenly align-items-start section-container w-100">
                      <p className="myfont m-0">Class</p>
                      <p className="myfont-white">Body</p>
                    </div>
                    <div className="d-flex flex-column justify-content-evenly align-items-start section-container w-100">
                      <p className="myfont m-0">Attribute</p>
                      <p className="myfont-white">Defence</p>
                    </div>
                  </div>
                  <div className="d-flex flex-row justify-content-between gap-2">
                    <div className="d-flex flex-column justify-content-evenly align-items-start section-container w-100">
                      <p className="myfont m-0">Color</p>
                      <p className="myfont-white">Gold</p>
                    </div>
                    <div className="d-flex flex-column justify-content-evenly align-items-start section-container w-100">
                      <p className="myfont m-0">Price</p>
                      <p className="myfont-white">7000 XPNET</p>
                    </div>
                  </div>
                  {/* <div className="d-flex flex-column align-items-start section-container w-100">
                    <div className="d-flex flex-row justify-content-between w-100">
                      <p className="myfont">Owner</p>
                      <div>
                        <Button variant="outline-light" onClick={TransferNFT}>
                          Transfer
                        </Button>
                        <img src={transferButton} alt="" />
                      </div>
                    </div>
                    <div className="adress-container d-flex fle-row justify-content-start align-items-center gap-2">
                      <img src={smallmeta} alt="" />
                      <p className="address w-50 myfont-white m-0 p-0">
                        {address}
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="py-3 d-flex flex-row justify-content-evenly">
                {/* <div className="col-lg-6 col-md-12 col-sm-12 d-flex align-items-center justify-content-center">
                  <p className="myfont-white p-0 m-0">
                    Item equipped on Dude #{selectedCharacter}
                  </p>
                </div> */}
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center">
                  {/* <button
                    className="mintButton"
                    onClick={async () => {
                      // EquipItem({
                      //   tokenId: selectedCharacter.toString(),
                      //   shortcode: await getShortcodeFromItemUri(
                      //     extractItemAttributesHeroku(selectedItem.attributes)
                      //       .originalUri
                      //   ),
                      //   _uri: extractItemAttributesHeroku(
                      //     selectedItem.attributes
                      //   ).originalUri,
                      //   _connectedwallet: address,
                      //   _selectedcharacter: selectedCharacter.toString(),
                      // });
                    }}
                  >
                    Equip
                  </button> */}
                  {/* <button className="mintButton">Unequip</button> */}
                  {/* </div> */}
                  {(equipped && selectedCharacter?.toString() == itemDetails?.equippedon) ? (
                    <>
                      <button
                        className="mintButton"
                        onClick={async () => {
                          if (!itemDetails || !selectedCharacter) {
                            //console.log("Item Data is lost... refresh it");
                            toast("No selected Character found! Select again", { theme: "dark" });
                            return;
                          }
                          store.dispatch(setOpenLoader(true));
                          const equipResponse = await UnEquipItem({
                            tokenId: itemDetails?.tokenId.toString(),
                            shortcode: itemDetails?.shortname,
                            _uri: itemDetails?.uri,
                            _connectedwallet: address,
                            _selectedcharacter: selectedCharacter.toString()
                          });
                          if (equipResponse?.status == 200) {
                            toast("UnEquiped Successfully", { theme: "dark" });
                            RefetchData();
                          }
                          else {
                            toast.error("Failed to unequip!", { theme: "dark" });
                          }
                          store.dispatch(setOpenLoader(false));
                        }}
                      >
                        Unequip
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="mintButton"
                        onClick={async () => {
                          store.dispatch(setOpenLoader(true));
                          if (!itemDetails || !selectedCharacter) {
                            store.dispatch(setOpenLoader(false));
                            toast("No selected Character found! Select again", { theme: "dark" });
                            return;
                          }
                          const equipResponse = await EquipItem({
                            tokenId: itemDetails?.tokenId.toString(),
                            shortcode: itemDetails?.shortname,
                            // await getShortcodeFromItemUri(
                            //   extractItemAttributesHeroku(selectedItem.attributes)
                            //     .originalUri
                            // ),
                            _uri: itemDetails?.uri,
                            // extractItemAttributesHeroku(
                            //   selectedItem.attributes
                            // ).originalUri,
                            _connectedwallet: address,
                            _selectedcharacter: selectedCharacter?.toString(),
                          });
                          if (equipResponse?.status == 200) {
                            toast("Equiped Successfully", { theme: "dark" });
                            RefetchData();
                          }
                          else {
                            toast.error("Failed to equip!", { theme: "dark" });
                          }
                          store.dispatch(setOpenLoader(false));
                        }}
                      >
                        Equip
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ) : (
        <></>
      )} */}
    </>
  );
}
