import { DELETE, GET, PATCH, POST } from "../utils/axios.http";
import { local as env } from "../lib/env";

type ApiProps = {
  url: ApiUrls;
};

export enum ApiUrls {
  Characters = "characters",
  Quests = "quests",
  Perks = "perks",
  Equipments = "equipments",
  Inventory = "inventory",
  Faqs = "faqs",
  Chests = "chests",
  Contracts = "contracts",
}
const baseUrl = env.HOST;
class AxiosService {
  apiUrl: string;

  constructor({ url }: ApiProps) {
    this.apiUrl = baseUrl + "/" + url;
  }

  async getList(params: any): Promise<any> {
    return await GET(this.apiUrl + "/list", params);
  }

  async getOne(params: any): Promise<any | null> {
    try {
      return await GET(this.apiUrl + "/get", params);
    } catch (error) {
      //console.log("error in getting contract " + error!);
      return null;
    }
  }

  async update(
    data: { name: string; type: string; level: string },
    params: any,
  ): Promise<any> {
    return await PATCH(this.apiUrl + "/list", data, params);
  }

  async create(data: any): Promise<any> {
    return await POST(this.apiUrl + "/create", data);
  }

  async remove(params: any): Promise<any> {
    return await DELETE(this.apiUrl + "/create", params);
  }
}

export { AxiosService };
