import { abi } from "../../../lib/MainNetTestNet/abi";
import { CONTRACTS } from "../../../utils/types";
import { typeToUrlMap } from "../../../lib/MainNetTestNet/values";
import { Web3Service } from "../../../services/web3.service";
import { ApiService } from "../../../services/apis.service";
import { ContractsEnum } from "../../../utils/enums";
import {
    ChainFactoryConfigs,
    ChainFactory,
    Chain,
    AppConfigs,
    Web3Helper,
    CHAIN_INFO,
    ChainType,
    ChainNonce,
} from "xp.network";
import contracts from "../../../lib/contracts";
import { GET } from "../../../utils/axios.http";
import axios from "axios";
import { SetListOfWrappedNft } from "../../../store/slices/nftCharacter.slice";
import { store } from "../../../store/store";
import { setOpenLoader } from "../../../store/slices/general.slice";

/**
 * Generates Globel list of wrapped nfts
 * @param connectedwallet
 * @returns list of nfts on contract
 */

function assertFulfilled<T>(
    item: PromiseSettledResult<T>
): item is PromiseFulfilledResult<T> {
    return item.status === "fulfilled";
}

const nftUriFilter = (uri: string) =>
    !["sw", "he"].some((t) => uri.includes(t));

const WNFT_PATTERN =
    /(wnfts.xp.network|nft.xp.network|staging-nft.xp.network|bridge-wnftapi)/;

export const usegetUserCharacters = async (
    connectedwallet: string
): Promise<any> => {
    store.dispatch(setOpenLoader(true));
    const web3Service = new Web3Service();
    const charactersjson: CONTRACTS = await ApiService.ContractsApi.getOne({
        forWhat: ContractsEnum.CHARACTERS,
    });
    const contractnumber: string | undefined =
        charactersjson?.contract.CONTRACT;

    const contract: any = web3Service.getContract({
        abi: abi,
        contractId: contractnumber,
        initializer: typeToUrlMap["CHARACTERS"][0],
    });

    console.log({ connectedwallet, contractnumber });
    //console.log("initial List Of Nfts", initialListOfNfts);
    ///Getting Quest Characters Back From This Wallet
    // var tonounce: number = 0;
    // for (let i = 0; i < contracts.length; i++) {
    //   if (contracts[i].NAME === "BSC") {
    //     tonounce = contracts[i].NOUNCE;
    //   }
    // }
    // //console.log("To Nounce", tonounce);
    // const ethers = require("ethers");

    const testnetConfig = await ChainFactoryConfigs.TestNet();
    const mainnetFactory = ChainFactory(AppConfigs.TestNet(), testnetConfig);
    let listOfWrappedNfts: any = [];
    store.dispatch(SetListOfWrappedNft([]));

    const factoies = Array.from(CHAIN_INFO.values())
        .filter((item) => item.type === ChainType.EVM)
        .map((item) => mainnetFactory.inner(item.nonce as ChainNonce));

    const chains = (await Promise.allSettled(factoies))
        .filter(assertFulfilled)
        .map((item) => item.value);

    const [initialListOfNfts] = await Promise.allSettled([
        contract.methods.getTokenIds(connectedwallet).call(),
        ...chains.map(async (chain) => {
            const nfts = await mainnetFactory
                .nftList(
                    chain, // The chain of interest
                    connectedwallet // The public key of the NFT owner in a web3 chain
                )
                .catch(() => []);

            if (!nfts.length) return;

            for (const nft of nfts) {
                if (WNFT_PATTERN.test(nft.uri)) {
                    const res = (
                        await axios
                            .get(nft.uri)
                            .catch(() => ({ data: undefined }))
                    ).data;

                    if (res?.wrapped?.original_uri) {
                        const { original_uri, tokenId, token_id, origin } =
                            res.wrapped;

                        if (nftUriFilter(original_uri))
                            listOfWrappedNfts.push({
                                Token: tokenId || token_id,
                                Chain: CHAIN_INFO.get(chain.getNonce())?.name,
                                Original_chain: origin,
                            });
                    }
                }
            }
        }),
    ]);

    /*for (const chn of listOfChains) {
        try {
            if (
                [
                    2, 9, 11, 14, 15, 17, 18, 6, 24, 26, 27, 28, 29, 34, 38, 39,
                ].includes((Chain as any)[chn])
            ) {
                continue;
            }
            const mchain: Web3Helper | undefined = await mainnetFactory.inner(
                (Chain as any)[chn]
            );
            console.log((Chain as any)[chn]);
            if (mchain) {
                const web3Nfts = await mainnetFactory.nftList(
                    mchain, // The chain of interest
                    connectedwallet // The public key of the NFT owner in a web3 chain
                );
                for (const nft of web3Nfts) {
                    if (
                        nft.native.uri &&
                        nft.native.uri.includes("herokuapp")
                    ) {
                        const wrappedResponse = await axios.get(nft.native.uri);
                        if (
                            wrappedResponse.data.wrapped &&
                            !["sw", "he"].some((t) =>
                                wrappedResponse.data.wrapped.original_uri.includes(
                                    t
                                )
                            )
                        ) {
                            const data = wrappedResponse.data.wrapped;
                            console.log(
                                "Token id",
                                data.tokenId + " Chain : ",
                                chn + " Original_chain ",
                                data.origin
                            );
                            listOfWrappedNfts.push({
                                Token: data.tokenId,
                                Chain: chn,
                                Original_chain: data.origin,
                            });
                        }
                    }
                }
                console.log((Chain as any)[chn] + " : ", web3Nfts);
            }
        } catch (error) {
            console.log(error);
        }
    }*/

    store.dispatch(setOpenLoader(false));

    console.log("Total Nfts discovered are : " + listOfWrappedNfts.length);
    store.dispatch(SetListOfWrappedNft(listOfWrappedNfts));

    //Query all chains :
    //Push all wrapped nfts to temp array :
    //maintain separate global list :

    // const signer = new ethers.providers.Web3Provider(window.ethereum).getSigner();
    // const mchain: Web3Helper | undefined = await mainnetFactory.inner(
    //   Chain.POLYGON,
    // );
    // const web3Nfts = await mainnetFactory.nftList(mchain, connectedwallet);
    // //console.log("List of Wrapped Nfts", web3Nfts);
    // let foundWrapped: any[] = [];
    // let promises: any[] = [];
    // for (const element of web3Nfts) {
    //   if (
    //     element.native.uri &&
    //     element.native.uri.includes("nftgame-characters")
    //   ) {
    //     //const unfreeze = await mchain.unfreezeWrappedNft();
    //   } else {
    //     continue;
    //   }
    //   ////console.log(element);
    //   const unfreeze = await mainnetFactory.isWrappedNft(element, tonounce);
    //   //console.log(unfreeze);
    // if (unfreeze.bool == true) {
    //   //console.log("Element", unfreeze.wrapped);
    //await GET(element.native.uri).then(async (result) => {
    // //console.log("Result", result);
    //foundWrapped.push(result.wrapped.source_token_id);
    // })
    // promises.push(unfreeze);
    //}
    //}
    // await Promise.all(promises);
    //console.log(foundWrapped.length);

    return initialListOfNfts.status === "fulfilled"
        ? initialListOfNfts.value
        : [];
};
