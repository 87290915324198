//Fantom
import {
  ChainFactoryConfigs,
  ChainFactory,
  Chain,
  AppConfigs,
} from "xp.network";
import { EquipItem } from "../equipitem";
import { UnEquipItem } from "../unequipitem";
import "./GetArmors.css";
import { GET } from "../../../utils/axios.http";
import { checkEquip } from "../../../utils/inventoryAPI";
import { store } from "../../../store/store";
export const GetArmors = async (connectedwallet: string) => {
  const armorsarray = new Array([]);
  const { selectedCharacter: selectedcharacter } =
    store.getState().nftCharacter;
  if (connectedwallet) {
    // //console.log("will get Armor nfts from bsc");
    const mainnetConfig = await ChainFactoryConfigs.TestNet();
    const mainnetFactory = ChainFactory(AppConfigs.TestNet(), mainnetConfig);
    const bsc = await mainnetFactory.inner(Chain.BSC);
    const web3Nfts = await mainnetFactory.nftList(bsc, connectedwallet);
    /////TO BE CONTINUED/////
    var equipped: number = 0;
    for (var ik = 0; ik < web3Nfts.length; ik++) {
      //Check for Any Equip and then dont add buttons other thn unequip
      const wrapped = await mainnetFactory.isWrappedNft(web3Nfts[ik], 7);
      if (!!wrapped) {
        const wrappeduri = await fetch(web3Nfts[ik].uri);

        const jsonwrappeduri = await wrappeduri.json();
        if (
          jsonwrappeduri.wrapped.original_uri.includes(
            "https://nftgame-characters.s3.amazonaws.com/armors-metadata",
          )
        ) {
          ////console.log(vj);
          const theorignaljson = await GET(jsonwrappeduri.wrapped.original_uri);
          const parts = theorignaljson.image.split("/");
          const withpngshortcode = parts[parts.length - 1];
          const shortcode = withpngshortcode.replace(".png", "");
          const jsoncheckeq = await checkEquip(shortcode);
          // else if (jsoncheckeq.nft != null) {
          if (
            jsoncheckeq.nft != null &&
            jsoncheckeq.nft.equippedon === selectedcharacter.toString()
          ) {
            // //console.log("OK ATLEAST ONE SWORD IS ACTIVE ON THIS CHARACTER OK");
            equipped++;
            // //console.log("Equipped Value is + " +equipped);
          }
        }
      }
    }
    for (var i = 0; i < web3Nfts.length; i++) {
      // //console.log("THE NFT IS      " + web3Nfts[i].native);
      ////console.log("THE NFT IS      " + web3Nfts[i].uri);
      var myButton: any = (
        <>
          <br></br> <p>CAN'T EQUIP MORE ARMORS</p>
        </>
      );

      const attrArray = new Array([]);
      const wrapped = await mainnetFactory.isWrappedNft(web3Nfts[i], 7);
      if (!!wrapped) {
        const wrappeduri = await fetch(web3Nfts[i].uri);

        const jsonwrappeduri = await wrappeduri.json();
        // //console.log(jsonwrappeduri);

        if (
          jsonwrappeduri.wrapped.original_uri.includes(
            "https://nftgame-characters.s3.amazonaws.com/armors-metadata",
          )
        ) {
          ////console.log(vj);
          const token = web3Nfts[i].native.tokenId;

          const theorignaljson = await GET(jsonwrappeduri.wrapped.original_uri);
          // //console.log(theorignaljson);

          // //console.log("The sword img " + theorignaljson.image);
          const parts = theorignaljson.image.split("/");
          const withpngshortcode = parts[parts.length - 1];
          const shortcode = withpngshortcode.replace(".png", "");
          // //console.log("shortcode  " + shortcode);
          // const theorignaljson = await orignaluri.json()
          for (var j = 0; j < theorignaljson.attributes.length; j++) {
            // //console.log(theorignaljson.attributes[j]);
            const temp2: any = (
              <div className="stat">
                {" "}
                <p>{theorignaljson.attributes[j].trait_type}</p>
              </div>
            );
            const temp3: any = (
              <div className="stat-value">
                {" "}
                <p>{theorignaljson.attributes[j].value}</p>
              </div>
            );
            const temp4: any = (
              <div className="one-third">
                {temp2}
                {temp3}
              </div>
            );
            attrArray.push(temp4);
          }

          const checkeq = await checkEquip(shortcode);
          if (checkeq.nft === null && equipped === 0) {
            //  //console.log("NO EQUIP YET");
            myButton = (
              <div className="MyButtonEQ" id="MyButtonEQ">
                <button
                  onClick={() => {
                    EquipItem({
                      tokenId: token,
                      _connectedwallet: connectedwallet,
                      _selectedcharacter: selectedcharacter.toString(),
                      _uri: jsonwrappeduri.wrapped.original_uri,
                      shortcode: shortcode,
                    });
                  }}
                >
                  Equip
                </button>
                <button
                  style={{
                    marginTop: "5px",
                    marginBottom: "1px",
                    fontSize: "1.2vh",
                  }}
                >
                  UPGRADE ITEM TO LEVEL 2 FOR 20 XPNETS
                </button>
              </div>
            );
            // swordsarray.push(myButton);
          }
          if (checkeq.nft != null) {
            if (checkeq.nft.equippedon === selectedcharacter.toString()) {
              //  //console.log(jsoncheckeq);
              myButton = (
                <div className="MyButtonEQ" id="MyButtonEQ">
                  <button
                    onClick={() => {
                      UnEquipItem({
                        tokenId: token,
                        shortcode: shortcode,
                        _uri: jsonwrappeduri.wrapped.original_uri,
                        _connectedwallet: connectedwallet,
                        _selectedcharacter: selectedcharacter.toString(),
                      });
                    }}
                  >
                    UnEquip
                  </button>
                </div>
              );
              // swordsarray.push(myButton);

              // //console.log("SOmeone is USing this ");
            }

            if (checkeq.nft.equippedon !== selectedcharacter.toString()) {
              /////console.log(jsoncheckeq);
              myButton = (
                <p className="SomeoneUsing" id="SomeoneUsing">
                  This Item is Currently Equipped on
                  {checkeq.nft.equippedon}
                </p>
              );
              // swordsarray.push(myButton);
              // //console.log("SOmeone is USing this ");
            }
          }
          const temp: any = (
            <>
              <div className={"swordcard" + shortcode}>
                <div className="swordmaincard">
                  <div className="swordImage">
                    <img
                      className="swordimage-"
                      src={theorignaljson.image}
                      alt={"swordimage"}
                    ></img>
                    <div className="clash-card__level">
                      {theorignaljson.name}
                    </div>
                  </div>
                  <div>
                    {theorignaljson.description}
                    {myButton}
                  </div>
                  <div className="clash-card__unit-stats">
                    <div className="atribulte">{attrArray}</div>
                  </div>
                </div>
              </div>
            </>
          );
          armorsarray.push(temp);
        }
      }
    }
  }
  return armorsarray;
};
