import { useEffect, useState } from "react";
import * as allChains from "@wagmi/chains";
import collapsedArrow from "../../../assets/collapsedArrow.png";
// import { GetSwords } from "../GetSwords/GetSwords";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { GetArmors } from "../GetArmors/GetArmors";
import "./DropdownMenu.css";
import ItemContainer from "../../../components/ItemContainer/ItemContainer";
import { getFilteredItems } from "../helpers";
import { BridgeService } from "../../../services/bridge.service";
import { AxiosService } from "../../../services/axios.service";
import { GET } from "../../../utils/axios.http";
import axios from "axios";
import { Chain } from "xp.network";
import { getChainId } from "web3/lib/commonjs/eth.exports";
import { getChain } from "../../../utils/handleschains";
import { Web3Service } from "../../../services/web3.service";
import _ from "lodash";
import { EquipmentsApi } from "../../../apis/equipments.api";

interface Props {
  text: "Weapon" | "Armor";
  // setSelectedArmory: React.Dispatch<React.SetStateAction<any>>;
  setShowSelected: React.Dispatch<React.SetStateAction<boolean>>;
  selectedArmory: any;
}

export const DropDownMenu: React.FC<Props> = ({
  text,
  setShowSelected,
  selectedArmory,
}) => {
  let url: string;
  const metaData = useSelector(
    (state: RootState) => state.nftCharacter.metaData,
  );
  const dataRefetch = useSelector((state: RootState) => state.general.dataRefetch);
  const address = useSelector((state: RootState) => state.user.address);
  const [displayDrodpwnHead, setDisplayDropdownHead] = useState(true);
  const [displayDrodpwnUpper, setDisplayDropdownUpper] = useState(true);
  const [displayDrodpwnLower, setDisplayDropdownLower] = useState(true);
  const [weapons, setWeapons] = useState<any | null>(null);
  const [headItems, setHeadItems] = useState<Array<any>>([]);
  const [upperBodyItems, setUpperBodyItems] = useState<Array<any>>([]);
  const [lowerBodyItems, setLowerBodyItems] = useState<Array<any>>([]);

  const [allEquipments, SetAllEquipments] = useState<Array<any>>([]);

  const FetchWeapons = async () => {
    try {
      if (text === "Weapon") {
        // GetSwords(address).then((response) => {
        //   // //console.log("swords response: ", response);
        //   setWeapons(response);
        // });
      } else {
        GetArmors(address).then((response) => {
          //console.log(response);
          setWeapons(response);
        });
      }
    } catch (error) {
      console.error("Error in Fetch Weapons :", error);
    }
  };
  useEffect(() => {
    FetchWeapons();
  }, [address]);

  useEffect(() => {
    // if (metaData?.attributes[12] !== undefined) {
    //   let ubItems: Array<any> = [];
    //   let lbItems: Array<any> = [];
    //   let hItems: Array<any> = [];
    //   //console.log(metaData);
    // head items array
    // getFilteredItems(metaData?.attributes[12].value, "he").forEach(
    //   (element) => {
    //     hItems.push(element);
    //   }
    // );
    // setHeadItems(getFilteredItems(metaData?.attributes[12].value, "he"));
    // //console.log("head items ", hItems);
    // upper body items array
    // BridgeService.getNftList({
    //   walletAddress: address,
    //   filterString: "armor",
    // }).then((response) => {
    //   response.forEach((element) => {
    //     ubItems.push(element);
    //   });
    //   //console.log({ ubItems });
    // });
    // BridgeService.getNftList({
    //   walletAddress: address,
    //   filterString: "sword",
    // }).then((response) => {
    //   response.forEach((element) => {
    //     ubItems.push(element);
    //   });
    //   //console.log({ ubItems });
    // });
    // getFilteredItems(metaData?.attributes[12].value, "ar").forEach(
    //   (element) => {
    //     ubItems.push(element);
    //   }
    // );
    // getFilteredItems(metaData?.attributes[12].value, "sw").forEach(
    //   (element) => {
    //     ubItems.push(element);
    //   }
    // );
    // setUpperBodyItems(ubItems);
    // lower body items array
    //setLowerBodyItems(lbItems);
    //}
  }, []);

  const getNfts = async () => {
    const web3Service = new Web3Service();
    const currentChainId = await web3Service.getCurrentChainId();
    let chain;

    if (currentChainId === allChains.polygonMumbai.id) {
      chain = Chain.POLYGON;
    } else {
      chain = Chain.BSC;
    }
    const allItems = await EquipmentsApi.allItems({ walletAddress: address });
    if (allItems == null || allItems == undefined) {
      //console.log("Unable to fetch items List")
      return;
    }
    SetAllEquipments(allItems);
    // await BridgeService.getNftList({
    //   walletAddress: address,
    //   chain: Chain.POLYGON,
    // }).then(async (response) => {
    //   //console.log("received list : ", response);
    //   const promises = [];
    //   for (const element of response) {
    //     try {
    //       if (element.native.uri.includes("sw")) {
    //         const promise = axios.get(element.native.uri)
    //           .then((herokuResp) => herokuResp.data)
    //           .catch(error => {
    //             //console.log("Error fetching data:", error);
    //             return { error };
    //           });
    //         promises.push(promise);
    //       }
    //     } catch (error) {
    //       //console.log(error);
    //     }
    //   }
    //   try {
    //     const arr = await Promise.all(promises);
    //     const successfulResponses = arr.filter(result => !result.error);
    //     await SetAllEquipments(successfulResponses);
    //     //console.log("Final is :", successfulResponses);
    //   } catch (error) {
    //     //console.log("Error in fetching Metadata", error);
    //   }
    // setUpperBodyItems(arr);
    // setUpperBodyItems(response);
    // //console.log(upperBodyItems);
    //});
  };
  useEffect(() => {
    const Run = async () => {
      //console.log("All Equipments =", allEquipments);
      const headEquipment = await _.filter(allEquipments, (equipment) =>
        String(equipment.name).toLowerCase().includes("he"),
      );
      await setHeadItems(headEquipment);
      //console.log(headItems);
      const upperBodyEquipment = await _.filter(allEquipments, (equipment) =>
        String(equipment.name).toLowerCase().includes("sw"),
      );
      await setUpperBodyItems(upperBodyEquipment);
      const lowerBodyEquipment = await _.filter(allEquipments, (equipment) =>
        String(equipment.name).toLowerCase().includes("leg"),
      );
      await setLowerBodyItems(lowerBodyEquipment);
    };
    Run();
  }, [allEquipments]);
  useEffect(() => {
    //console.log("triggering function");
    getNfts();
  }, [dataRefetch]);

  useEffect(() => {
    //console.log({ upperBodyItems });
  }, [upperBodyItems]);

  return (
    <>
      <div className="d-flex flex-column gap-2 justify-content-center align-items-start w-100 mt-3">
        <div className="d-flex flex-column align-items-center w-100 gap-2">
          <div className=" title-section d-flex flex-row justify-content-center align-items-center w-100 gap-2"
            onClick={() =>
              setDisplayDropdownHead((prevValue) => {
                // //console.log(!prevValue);
                return !prevValue;
              })
            }
          >
            <p className="dropdown-text myfont m-0 p-0">Head</p>
            <img
              className={displayDrodpwnHead ? "dropbtn_invert" : "dropbtn"}
              src={collapsedArrow}
              alt="downArrow"

            />
          </div>
        </div>

        <div
          id={`myDropdown-${text}`}
          className="dropdown-content justify-content-center d-flex image-grid-container w-100 gap-3"
        >
          {headItems.length > 0 ? (
            headItems?.map((element: any, index: number) => {
              return (
                <div key={index}>
                  {displayDrodpwnHead ? (
                    <ItemContainer
                      element={element}
                      height={"80px"}
                      width={"80px"}
                      key={index}
                      url={element.image}
                      setShowSelected={setShowSelected}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* Upper Body */}
      <div className="d-flex flex-column gap-2 justify-content-center align-items-start w-100 mt-3">
        <div className="d-flex flex-column align-items-center w-100 gap-2">
          <div className=" title-section d-flex flex-row justify-content-center align-items-center w-100 gap-2"
            onClick={() =>
              setDisplayDropdownUpper((prevValue) => {
                // //console.log("clicked");
                // //console.log(!prevValue);
                return !prevValue;
              })
            }
          >
            <p className="dropdown-text myfont m-0 p-0">Upper Body</p>
            <img
              className={displayDrodpwnUpper ? "dropbtn_invert" : "dropbtn"}
              src={collapsedArrow}
              alt="downArrow"

            />
          </div>
        </div>

        <div
          id={`myDropdown-${text}`}
          className="dropdown-content justify-content-center d-flex image-grid-container w-100 gap-3"
        >
          {upperBodyItems.length > 0 ? (
            upperBodyItems?.map((element, index) => {
              // metaData?.attributes[12] !== undefined ? (
              //   metaData?.attributes[12].value.map((element: any, index: any) => {
              //     if (element.includes("sw")) {
              //       url = `https://nftgame-characters.s3.amazonaws.com/swords-images/`;
              //     } else if (metaData?.attributes[12].value[index].includes("he")) {
              //       url = `https://nftgame-characters.s3.amazonaws.com/helmets-images/`;
              //     } else if (metaData?.attributes[12].value[index].includes("ar")) {
              //       url = `https://nftgame-characters.s3.amazonaws.com/armors-images/`;
              //     }
              return (
                // <img src={element?.image} alt="" width={100} height={100} />
                displayDrodpwnUpper ? (
                  <ItemContainer
                    key={index}
                    height={"80px"}
                    width={"80px"}
                    url={element.thumbnailUri}
                    element={element}
                    setShowSelected={setShowSelected}
                  />
                ) : (
                  <></>
                )
              );
            })
          ) : (
            <></>
          )}
          {/* {weaponsTemp?.map((element: any, index: number) => {
            if (element.name == "upper body") {
              return (
                <div key={index}>
                  {displayDrodpwnUpper ? (
                    <ItemContainer
                      element={element}
                      height={"80px"}
                      width={"80px"}
                      setShowSelected={setShowSelected}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              );
            }
          })} */}
        </div>
      </div>
      {/* Lower Body */}
      <div className="d-flex flex-column gap-2 justify-content-center align-items-start w-100 mt-3">
        <div className="d-flex flex-column align-items-center w-100 gap-2">
          <div className=" title-section d-flex flex-row justify-content-center align-items-center w-100 gap-2"
            onClick={() =>
              setDisplayDropdownLower((prevValue) => {
                // //console.log("clicked");
                // //console.log(!prevValue);
                return !prevValue;
              })
            }
          >
            <p className="dropdown-text myfont m-0 p-0">Lower Body</p>
            <img
              className={displayDrodpwnLower ? "dropbtn_invert" : "dropbtn"}
              src={collapsedArrow}
              alt="downArrow"

            />
          </div>
        </div>

        <div
          id={`myDropdown-${text}`}
          className="dropdown-content justify-content-center d-flex image-grid-container w-100 gap-3"
        >
          {lowerBodyItems.length > 0 ? (
            lowerBodyItems?.map((element: any, index: number) => {
              if (element.name == "lower body") {
                return (
                  <div key={index}>
                    {displayDrodpwnLower ? (
                      <ItemContainer
                        element={element}
                        height={"80px"}
                        width={"80px"}
                        url={element.thumbnailUri}
                        setShowSelected={setShowSelected}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                );
              }
            })
          ) : (
            <></>
          )}
        </div>
      </div>{" "}
    </>
  );
};
