import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAddress } from "../../store/slices/user.slice";
import { RootState } from "../../store/store";
import { LoginScreen } from "../LoginScreen/LoginScreen";
import { Navbar } from "../../components/Navbar/Navbar";
import "./Hometab.css";
import { RectangleHeader } from "../RectangleHeader";
import { usegetUserCharacters } from "./utils/usegetUserCharacters";
import { Character, Item } from "../../utils/types";
import { MintNewCharacter } from "./utils/MintNewCharacter";
import {
    setSelectedCharacter,
    setCharacters,
    setMetadata,
    setSelectedCharacterData,
} from "../../store/slices/nftCharacter.slice";
import {
    MintNFT,
    characterLoading,
    defaultimage,
    expIcon,
} from "../../services/assets.service";
import { ProgressBar } from "react-bootstrap";
import { QuestListItem } from "./QuestListItem/QuestListItem";
import { useNavigate } from "react-router-dom";
import CharacterTile from "../../components/CharacterTile/CharacterTile";
import { ApiService } from "../../services/apis.service";
import PerkTile from "./Components/PerkTile/PerkTile";
import { useQuery } from "react-query";
import LoaderHOC from "../../components/Loader/LoaderHOC";
import { Loader } from "../../components/Loader/Loader";
import { ContractsEnum } from "../../utils/enums";
import { getXPNETTokensBalance } from "../../utils/API";
import {
    setDataRefetch,
    setOpenLoader,
} from "../../store/slices/general.slice";
import { resolve } from "path";
import { toast } from "react-toastify";
import ReactDOM from "react-dom";
import { StopQuest } from "../Quests/helpers";
import _ from "lodash";
import { QuestsApi } from "../../apis/quests.api";
import { dark } from "@mui/material/styles/createPalette";

export const Hometab: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { status, error, data, isLoading } = useQuery({
        queryKey: "getPerks",
        onSuccess: () => {},
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState(true);
    const [value, setValue] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [perks, setPerks] = useState<Array<any>>([]);
    const [quests, setQuests] = useState<Array<any>>([]);
    const [imageUrl, SetImageUrl] = useState<string>();
    const imageRef = useRef(0);

    const address = useSelector((state: RootState) => state.user.address);
    const characters = useSelector(
        (state: RootState) => state.nftCharacter.characters
    );
    const selectedCharacter = useSelector(
        (state: RootState) => state.nftCharacter.selectedCharacter
    );
    const selectedCharacterData = useSelector(
        (state: RootState) => state.nftCharacter.selectedCharacterData
    );
    const listOfWrappedNfts = useSelector(
        (state: RootState) => state.nftCharacter.listOfWrappedNfts
    );
    const dataRefetch = useSelector(
        (state: RootState) => state.general.dataRefetch
    );
    const [newCharacters, setNewCharacters] = useState<Array<any>>();
    useEffect(() => {
        //console.log({ selectedCharacterData });
    }, [selectedCharacterData]);
    let metadata = useSelector(
        (state: RootState) => state.nftCharacter.metaData
    );
    // let metadata = useSelector((state: RootState) => state.nftCharacter.selectedCharacterData);

    const [percentage, setPercentage] = useState(65);
    const [level, setLevel]: any = useState();
    /**
     * Set Selected Character data to show in details
     * @param data
     */
    const PopulateCharacterData = (data: Character) => {
        setPercentage(data.experience);
        setLevel(data.level);
    };
    const incrementValue = () => {
        setValue(value + 1);
    };
    const decrementValue = async () => {
        if (value > 1) {
            await setValue(value - 1);
        }
    };
    /**
     * Fetch all Nfts on Contract
     * Show list of Charcters
     */
    const setNftCharacter = async () => {
        try {
            // //console.log("in set");
            const usercharacters: number[] =
                await usegetUserCharacters(address);
            usercharacters.length &&
                dispatch(
                    setCharacters(usercharacters.map((item) => Number(item)))
                );
        } catch (error) {
            console.error(error);
        }
    };

    //useEffect(() => {
    // try {
    // //console.log("IN the first useEffect");
    //setNftCharacter();
    //  } catch (error) {
    //console.log(error);
    //   }
    // }, []);
    /**
     * Refresh Charcters Data for any change of address,dispach call or seting refresh
     */
    useEffect(() => {
        if (address) {
            setNftCharacter();
        } else {
            dispatch(setAddress(""));
        }
    }, [address, refresh, dataRefetch]);
    /**
     * Reset variables after wallet change
     */
    useEffect(() => {
        dispatch(setSelectedCharacter(undefined));
        dispatch(setSelectedCharacterData(undefined));
        dispatch(setOpenLoader(false));
    }, [address]);
    /**
     * Generate Wrapped Characters Component based on all gathered Data from querying all chains
     * Get Them back by unfreeze
     */
    useEffect(() => {
        let tempArray = [];
        for (const nft of listOfWrappedNfts) {
            tempArray.push(
                <div
                    className="image-wrapper-home d-flex flex-row"
                    style={{
                        position: "relative",
                    }}
                >
                    <CharacterTile
                        character={Number(nft["Token"])}
                        handleClick={UnWrapNft}
                        selectedImage={selectedCharacter}
                    />
                    <div
                        className="d-flex flex-grow-1 h-100 w-100 myfont-white justify-content-center align-items-center"
                        style={{
                            position: "absolute",
                            left: "0PX",
                            top: "0px",
                            zIndex: "100",
                            pointerEvents: "none",
                        }}
                    >
                        <h6>
                            {Object.values(nft)[1]}
                            <br />
                            <br />
                            Claim!
                        </h6>
                    </div>
                </div>
            );
            console.log(Object.values(nft)[0]);
        }
        setNewCharacters(tempArray);
    }, [listOfWrappedNfts]);
    /**
     * Start Getting Character back from Chain i.e Quest flow
     * @param token
     */
    const UnWrapNft = async (token: number) => {
        const record =
            listOfWrappedNfts.find((x) => x["Token"] == token.toString())
                ?.Chain ?? "";
        console.log(record);
        const isInQuest = await CheckQuestOver(token, record);
        console.log("3");
        if (isInQuest) {
            return;
        }
        console.log("4");
        const questStop = await new Promise(async (resolve, reject) => {
            const result = await StopQuest({
                walletAddress: address,
                nftTokenId: token.toString(),
                _toChain: record,
                startHash: "",
            });
            resolve(result);
        });
        await new Promise((resolve) => setTimeout(resolve, 2000));
        dispatch(setDataRefetch());
        console.log("To Do");
    };
    /**
     * Check if requested quest timer has completed
     * @param token
     */
    const CheckQuestOver = async (token: number, chainD: string) => {
        try {
            let questData, starTimeOfQuest, timeToEndQuest;
            const quest = await QuestsApi.checkQuestComplete({
                address: address,
                tokenId: token,
                chain: chainD.toUpperCase(),
            });
            questData = quest?.data?.quest;
            if (questData == null) {
                return false;
                console.log("1");
            } else {
                console.log("2");
                starTimeOfQuest = new Date(questData?.datestarted);
                starTimeOfQuest.setMinutes(
                    starTimeOfQuest.getMinutes() + questData.requiredtime
                );
                timeToEndQuest = starTimeOfQuest.getTime();
                var now = new Date().getTime();
                var distance = timeToEndQuest - now;
                if (distance > 0) {
                    toast("Quest Still Running ! Wait..", { theme: "dark" });
                    return true;
                } else {
                    return false;
                }
            }
        } catch (error) {
            toast("error in getting Quest Data", { theme: "dark" });
            return true;
        }
    };
    /**
     * Handles Selection and Unselection of Chaaracter
     * @param tokenId
     * @returns
     */
    const handleCharacterSelect = async (tokenId: any) => {
        //console.log("clicked On Charcter" + tokenId);
        dispatch(setOpenLoader(true));
        try {
            // //console.log(tokenId);
            if (selectedCharacter === tokenId) {
                dispatch(setSelectedCharacter(null));
                dispatch(setSelectedCharacterData(undefined));
                dispatch(setOpenLoader(false));
                return;
            } else {
                // //console.log("selected", tokenId);
                try {
                    const meta: Item =
                        await ApiService.CharactersApi.getCharacterMetaData({
                            tokenId: tokenId,
                        });
                    if (meta !== undefined) {
                        dispatch(setMetadata(meta));
                        dispatch(setSelectedCharacter(tokenId));
                        await SetImageUrl(characterLoading);
                        await new Promise((resolve) =>
                            setTimeout(resolve, 100)
                        );
                        const uniqueId = Math.random()
                            .toString(36)
                            .substring(30);
                        // await SetImageUrl(`${meta.image}?cache=${uniqueId}`);
                        //await new Promise((resolve) => setTimeout(resolve, 100));
                        const imageUrl = meta.image + "?cache=" + uniqueId;
                        // Create a new Request object with cache set to 'no-cache'
                        // Create a new Request object with cache set to 'no-cache'
                        const request = new Request(imageUrl, {
                            cache: "no-cache",
                        });
                        // Fetch the image with the cache set to 'no-cache'
                        await fetch(request)
                            .then(async (response) => {
                                if (response.ok) {
                                    // If the request is successful, set the image source to the fetched URL
                                    await SetImageUrl(imageUrl);
                                }
                            })
                            .catch((error) => {
                                console.error("Error fetching image:", error);
                            });
                        imageRef.current += 1;
                        console.log("incrementing key", imageRef.current);
                        dispatch(setOpenLoader(false));
                        //console.log("tokenid after meta dispatch Is " + tokenId);
                    } else {
                        return;
                    }
                    const contract = await ApiService.ContractsApi.getOne({
                        forWhat: ContractsEnum.CHARACTERS,
                    });
                    if (contract == null || contract == undefined) {
                        dispatch(setOpenLoader(false));
                        return;
                    }
                    //console.log("Contract for clicked charcter is ");
                    //console.log({ contract });
                    if (
                        contract != null &&
                        contract != undefined &&
                        tokenId != null &&
                        tokenId != undefined
                    ) {
                        console.log(
                            "getting data for characterID" +
                                contract?.contract?.CONTRACT +
                                tokenId
                        );
                        try {
                            await ApiService.CharactersApi.getOne({
                                tokenId: tokenId,
                                characterId:
                                    contract?.contract?.CONTRACT + tokenId,
                            }).then((response) => {
                                // //console.log(response);
                                if (response) PopulateCharacterData(response);
                                dispatch(setSelectedCharacterData(response));
                            });
                        } catch (error) {
                            dispatch(setOpenLoader(false));
                            //console.log(error);
                        }
                    }
                    await ApiService.PerksApi.getList({}).then((response) => {
                        setLoading(true);
                        setTimeout(() => {
                            setPerks(response);
                            setLoading(false);
                        }, 3000);
                    });
                    ApiService.QuestsApi.getList({
                        walletAddress: address,
                        nftTokenId: tokenId,
                    }).then((response) => {
                        setQuests(response);
                    });
                } catch (error) {
                    //console.log("error in hometab: ", error);
                }
            }
        } catch (error) {
            //console.log(error);
        }
        dispatch(setOpenLoader(false));
    };
    /**
     * Open Mint Character Modal
     */
    function openModal() {
        setShowModal(true);
    }
    /**
     * Close Mint Character Modal
     */
    function closeModal() {
        setShowModal(false);
        setValue(1);
        setMinting(false);
    }

    const [minting, setMinting] = useState<boolean>(false);
    const handleMint = async () => {
        dispatch(setOpenLoader(true));
        dispatch(setOpenLoader(false));
        try {
            let loop = value;
            while (loop >= 1) {
                setMinting(true);
                await MintNewCharacter(address);
                await new Promise((resolve) => setTimeout(resolve, 200));
                console.log(loop);
                toast("Character Minted Successfully", { theme: "dark" });
                if (loop == 1) {
                    console.log("terminating");
                    break;
                } else {
                    loop -= 1;
                    console.log("decrimenting");
                    await new Promise((resolve) => setTimeout(resolve, 200));
                }
            }
            // show a success message and navigate to the character details page
        } catch (e) {
            dispatch(setOpenLoader(false));
            toast("Failed to mint Character", { theme: "dark" });
            // show an error message
            //console.log("minting error: ", e);
        } finally {
            setTimeout(() => {
                // closeModal();
                setMinting(false);
                // minting();
                //toast("Character Minted Successfully ", { theme: "dark" });
                setRefresh(!refresh);
                closeModal();
            }, 15000);
            dispatch(setOpenLoader(false));
        }
    };
    return (
        <>
            {address ? (
                //IF CONNECTED
                <>
                    <div className="fightersPage">
                        <Navbar />
                        <div className="d-flex flex-row justify-content-between align-items-center flex-wrap px-lg-5 px-md-5 px-sm-2 p-top">
                            <div
                                className="col-lg-6 col-md-12 col-sm-12 p-lg-5 p-md-5 p-sm-2"
                                style={{ minWidth: "40vw" }}
                            >
                                <div className="d-flex flex-column justify-content-center align-items-center mx-auto">
                                    <RectangleHeader text="Your Fighters" />
                                    <div className="containerR px-2">
                                        <div className="image-collection">
                                            <div
                                                className="d-flex flex-row pt-5 justify-content-center flex-wrap image-gallery-home"
                                                id="characterContainer"
                                            >
                                                {newCharacters}
                                                {characters?.map(
                                                    (
                                                        element: any,
                                                        index: number
                                                    ) => (
                                                        <CharacterTile
                                                            character={element}
                                                            handleClick={
                                                                handleCharacterSelect
                                                            }
                                                            selectedImage={
                                                                selectedCharacter
                                                            }
                                                        />
                                                    )
                                                )}
                                                <img
                                                    className="mintnft"
                                                    alt="MINTNFTMODAL"
                                                    src={MintNFT}
                                                    onClick={openModal}
                                                ></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-6 col-md-12 col-sm-12 px-lg-5 px-md-5 px-sm-2 d-flex"
                                style={{ height: "fit-content" }}
                            >
                                <div
                                    className="d-flex flex-column w-100"
                                    style={{ height: "fit-content" }}
                                >
                                    <RectangleHeader
                                        text={
                                            selectedCharacter
                                                ? "Dude # " +
                                                  selectedCharacter?.toString()
                                                : "Select Fighter"
                                        }
                                    />
                                    <div
                                        className="containerR d-flex flex-column flex-grow-1 justify-content-center align-items-start px-2"
                                        style={{
                                            height: "fit-content",
                                            minHeight: "600px",
                                        }}
                                    >
                                        {selectedCharacter != undefined &&
                                        selectedCharacter >= 0 ? (
                                            <div className="flag d-flex flex-column justify-content-center align-items-center">
                                                <div className="d-flex flex-column flex-grow-1 justify-content-start align-items-center my-3">
                                                    <p className="flag-value m-0 p-0 myfont">
                                                        {level}
                                                    </p>
                                                    <p className="myfont">
                                                        Level
                                                    </p>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}

                                        {selectedCharacter >= 0 &&
                                        selectedCharacter != null ? (
                                            <div className="col-6 d-flex flex-column justify-content-around w-100 align-items-center px-4 m-0 pb-4">
                                                <div className="row chPeWrap d-flex  justify-content-center align-items-center">
                                                    <div className="characterIcon col-sm-12 col-md-12 col-lg-6 d-flex flex-grow-1">
                                                        <img
                                                            src={imageUrl}
                                                            alt={"meta"}
                                                            key={
                                                                Date.now() +
                                                                imageRef.current
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-sm-12 col-md-12  col-lg-6 nftDesc d-flex flex-grow-1 flex-column gap-3 justify-content-center align-items-center">
                                                        <div className="row charExp d-flex flex-row justify-content-center align-items-center border-gray w-100">
                                                            <div className="w-25">
                                                                <img
                                                                    src={
                                                                        expIcon
                                                                    }
                                                                    alt="exp-icon"
                                                                    width={25}
                                                                    height={25}
                                                                />
                                                            </div>

                                                            <div className="col-8">
                                                                <div className="row d-flex flex-row justify-content-between">
                                                                    <p className="col-4 p-0 myfont mytext">
                                                                        Experience
                                                                    </p>
                                                                    <p className="col-4 myfont mytext">
                                                                        {/* {metadata?.attributes[1]?.value
                                      ? metadata?.attributes[1]?.value
                                      : 0} */}
                                                                        {
                                                                            selectedCharacterData?.experience
                                                                        }
                                                                        %
                                                                    </p>
                                                                </div>
                                                                <div className="row expBar">
                                                                    <div className="progressBarCh">
                                                                        <ProgressBar
                                                                            // now={
                                                                            //   metadata?.attributes[1]?.value
                                                                            //     ? metadata?.attributes[1]?.value
                                                                            //     : 0
                                                                            // }
                                                                            now={
                                                                                selectedCharacterData?.experience
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row perks image-grid-container justify-content-center border-gray w-100">
                                                            <div className="perksText d-flex justify-content-start">
                                                                <p className="myfont">
                                                                    Perks
                                                                </p>
                                                            </div>
                                                            <LoaderHOC
                                                                loading={
                                                                    loading
                                                                }
                                                            >
                                                                <div className="d-flex flex-row justify-content-center">
                                                                    {perks?.map(
                                                                        (
                                                                            item,
                                                                            index
                                                                        ) => (
                                                                            <PerkTile
                                                                                key={
                                                                                    index
                                                                                }
                                                                                data={
                                                                                    item
                                                                                }
                                                                            />
                                                                        )
                                                                    )}
                                                                </div>
                                                            </LoaderHOC>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-2  w-100">
                                                    {quests?.length > 0 ? (
                                                        <>
                                                            <div className="d-flex flex-row justify-content-between w-100">
                                                                <p className="text-start myfont">
                                                                    Quests
                                                                </p>
                                                                <p
                                                                    className="myfont cursor-pointer"
                                                                    onClick={() =>
                                                                        navigate(
                                                                            "/quests"
                                                                        )
                                                                    }
                                                                >
                                                                    View All
                                                                </p>
                                                            </div>
                                                            <div className="questWraperClass d-flex flex-row justify-content-start gap-2 flex-grow-1 ">
                                                                {quests?.map(
                                                                    (
                                                                        element,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    index
                                                                                }
                                                                                className="quest-list-item"
                                                                            >
                                                                                <QuestListItem
                                                                                    index={
                                                                                        index
                                                                                    }
                                                                                    quest={
                                                                                        element
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div className="d-flex flex-row justify-content-center align-items-center p-0 m-0">
                                                            <p className="myfont p-0 m-0">
                                                                No Quests Found
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="d-flex flex-col justify-content-center align-items-center w-100 h-75">
                                                <p className="myfont nocntentText">
                                                    Please Select Character to
                                                    Begin
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {showModal ? (
                        <div className="modalPopup m-0 p-0">
                            <div className="d-flex flex-column justify-content-start align-items-center m-0 p-0">
                                <div className="mintNftHeader d-flex flex-row justify-content-center">
                                    <div className="mintNftModalHeader">
                                        <RectangleHeader text="Mint Your Fighter" />
                                    </div>
                                </div>

                                {/* Body */}

                                <div className="modal-content-m  d-flex flex-column justify-content-start align-items-center">
                                    <div
                                        className="d-flex flex-row justify-content-between w-100 px-4"
                                        style={{
                                            width: "200px",
                                            maxHeight: "50px",
                                        }}
                                    >
                                        <div className="modal-banner"></div>
                                        <span
                                            className="close"
                                            onClick={closeModal}
                                        >
                                            &times;
                                        </span>
                                    </div>

                                    {minting === false ? (
                                        <div
                                            style={{
                                                padding: "100px 0px",
                                                minHeight: "500px",
                                                height: "100%",
                                            }}
                                        >
                                            <img
                                                src={defaultimage}
                                                alt="characterimg"
                                                width={"30%"}
                                                height={"60%"}
                                                style={{ minWidth: "300px" }}
                                            />

                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <div className="d-flex flex-row justify-content-center align-items-center ">
                                                    <button
                                                        className="incDec"
                                                        onClick={decrementValue}
                                                    >
                                                        -
                                                    </button>
                                                    <p className="IncDec">
                                                        {value}
                                                    </p>
                                                    <button
                                                        className="incDec"
                                                        onClick={incrementValue}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                                <button
                                                    className="mintButton"
                                                    onClick={() => handleMint()}
                                                >
                                                    Mint NFT
                                                </button>
                                            </div>
                                        </div>
                                    ) : null}

                                    {minting === true ? (
                                        <div
                                            className="w-100 h-100 d-flex flex-column justify-content-center align-items-center"
                                            style={{ minHeight: "500px" }}
                                        >
                                            <Loader variant="warning" />
                                        </div>
                                    ) : null}

                                    {/* </LoaderHOC> */}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <>
                    <LoginScreen />
                </>
            )}
        </>
    );
};
