import { getChain } from "../../../utils/handleschains";
import { abi } from "../../../lib/MainNetTestNet/abi";
import { CONTRACTS, TransactionReceipt } from "../../../utils/types";
import { updateCharacterQuests } from "../../../utils/API";
import { Contract } from "web3-eth-contract";
import { paymentForMint } from "../../../lib/MainNetTestNet/values";
import { mintNewCharacter } from "../../../utils/API";
import { Web3Service, injected } from "../../../services/web3.service";
import { ApiService } from "../../../services/apis.service";
import { ChainIdEnum, ContractsEnum } from "../../../utils/enums";
import Web3, { Transaction } from "web3";
import { toast } from "react-toastify";

export const MintNewCharacter = async (address: string) => {
  const web3Service = new Web3Service();
  if (!window.ethereum) {
    //console.log("cancelling");
    return false;
  }
  const charactersjson: CONTRACTS = await ApiService.ContractsApi.getOne({
    forWhat: ContractsEnum.CHARACTERS,
  });
  //console.log({ charactersjson });
  const contractnumber: string | undefined = charactersjson?.contract.CONTRACT;
  const contractchain: any = charactersjson?.contract.CHAIN;
  try {
    await window.ethereum.request({ method: "eth_requestAccounts" });
  } catch (error) {
    //console.log("error here -> ", error);
  }
  let currentChain: number = 0;
  const web3: Web3 = new Web3(window.ethereum);
  try {
    currentChain = await web3.utils.toNumber(
      await web3.eth.getChainId(),
    ) as number;
  } catch (error) {
    //console.log(error);
  }
  // const currentchain: any = await web3Service.getChainId({
  //   initializer: window.ethereum,
  // });
  const chainIdValues = Object.values(ChainIdEnum);
  const contractChainId: number = Number(ChainIdEnum[contractchain]);/*await Number(chainIdValues.find((x) => x == contractchain)) ?? 0;*/
  //console.log({ currentChain }, { contractChainId });
  await new Promise(async (resolve, reject) => {
    if (Number(currentChain) !== contractChainId/*(ChainIdEnum[contractchain])*/) {
      console.log("Chains are not equal");
      console.log("Converting to chain ", contractchain);
      getChain(contractchain).then(async () => {
        const result = await Mint().then(() => {
        });
        resolve(result);
      });
    } else if (Number(currentChain) === contractChainId/* Number(ChainIdEnum[contractchain])*/) {
      //console.log("Chains Are Equal");
      const result = await Mint().then(() => { });
      resolve(result);
    }
  })
  async function Mint() {
    let myContract: any;
    //console.log("Before Getting Contract");
    try {
      myContract = web3Service.getContract({
        abi: abi,
        contractId: contractnumber,
      });
    } catch (error) {
      //console.log(error);
    }
    //console.log("My Contract", myContract);
    const encoded: string = await myContract.methods
      .safeMint(address)
      .encodeABI();
    const strEther: number = paymentForMint;
    var tx: Transaction = {
      from: address,
      value: strEther,
      to: contractnumber,
      data: encoded,
    };

    //console.log({ encoded });

    try {
      const mygas: number | undefined = await web3Service.estimateGas({
        transaction: tx,
      });
      var txf: Transaction = { ...tx, gas: mygas };
      const receipt: TransactionReceipt = await web3Service.sendTransaction({
        transaction: txf,
      });

      if (receipt.status) {
        //console.log({ receipt });
        await mintNewCharacter(receipt.transactionHash);
        // return await updateCharacterQuests(sendHash.transactionHash);
      }
    } catch (e) {
      toast("Failed to mint Character", { theme: "dark" });
      //console.log("payment fail!", e);
    }
  }
};
