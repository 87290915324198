import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { store } from "../../store/store";
import { LoginScreen } from "../LoginScreen/LoginScreen";
import "./Market.css";
import { Navbar } from "../../components/Navbar/Navbar";
import { defaultitem } from "../../services/assets.service";
import Dropdown from "./Dropdown/Dropdown";
import { Loader } from "../../components/Loader/Loader";

type Props = {};

export default function Market({ }: Props) {
  const { address } = store.getState().user;

  const [minPrice, setMinPrice] = useState<number | undefined>(undefined);
  const [maxPrice, setMaxPrice] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const handleSeach = () => {
    setLoading(true);
    setShow(true);
    setTimeout(() => {
      setLoading(false);
      setShow(false);
    }, 2000);
    const data = {
      minPrice: minPrice,
      maxPrice: maxPrice,
    };
    //console.log({ data });

    //API CALL
  };

  const arr: Array<{ image: string; name: string; tokenId: string }> = [
    {
      image: "",
      name: "Nft 1",
      tokenId: "1",
    },
    {
      image: "",
      name: "Nft 1",
      tokenId: "1",
    },
    {
      image: "",
      name: "Nft 1",
      tokenId: "1",
    },
    {
      image: "",
      name: "Nft 1",
      tokenId: "1",
    },
    {
      image: "",
      name: "Nft 1",
      tokenId: "1",
    },
    {
      image: "",
      name: "Nft 1",
      tokenId: "1",
    },
    {
      image: "",
      name: "Nft 1",
      tokenId: "1",
    },
    {
      image: "",
      name: "Nft 1",
      tokenId: "1",
    },
    {
      image: "",
      name: "Nft 1",
      tokenId: "1",
    },
    {
      image: "",
      name: "Nft 1",
      tokenId: "1",
    },
    {
      image: "",
      name: "Nft 1",
      tokenId: "1",
    },
    {
      image: "",
      name: "Nft 1",
      tokenId: "1",
    },
  ];
  return (
    <>
      {address ? (
        <div className="page">
          <Navbar />
          <div
            className="pt-5 container"
            style={{ marginTop: "100px", height: "70vh" }}
          >
            <h3 className="w-100 myfont-white">Market Place</h3>
            <div
              className="d-flex flex-row p-3 flex-wrap"
              style={{
                height: "70vh",
              }}
            >
              {/* sidebar */}
              <div
                className="d-flex flex-column justify-content-between align-items-end col-3"
                style={{
                  borderRight: "2px solid darkgrey",
                  height: "70vh",
                  overflowY: "auto",
                }}
              >
                <div className="d-flex flex-column mb-auto gap-2 w-100 px-4">
                  <div className="my-3">
                    <div className="sidebar-option">
                      <h3 className="myfont-white m-0 p-0 text-start">
                        Fighters
                      </h3>
                    </div>
                  </div>

                  <div className="my-3">
                    <div className="sidebar-option">
                      <h3 className="myfont-white m-0 p-0 text-start">
                        Attack
                      </h3>
                    </div>
                  </div>

                  <div className="my-3">
                    <div className="sidebar-option">
                      <h3 className="myfont-white m-0 p-0 text-start">
                        Defence
                      </h3>
                    </div>
                  </div>

                  <div className="my-3">
                    <Dropdown title="Price">
                      <div className="d-flex flex-row justify-content-between gap-2">
                        <input
                          type="number"
                          placeholder="Min"
                          min={0}
                          max={100000}
                          className="w-50 input-field myfont-white"
                          value={minPrice}
                          onChange={(e) => {
                            setMinPrice(+e.target.value);
                          }}
                        />
                        <input
                          type="number"
                          placeholder="Max"
                          min={0}
                          max={100000}
                          className="w-50 input-field myfont-white"
                          value={maxPrice}
                          onChange={(e) => {
                            setMaxPrice(+e.target.value);
                          }}
                        />
                      </div>
                    </Dropdown>

                    <Dropdown title="Example">
                      <div className="d-flex flex-row justify-content-between gap-2">
                        <input
                          type="number"
                          placeholder="Min"
                          min={0}
                          max={100000}
                          className="w-50 input-field myfont-white"
                          value={minPrice}
                          onChange={(e) => {
                            setMinPrice(+e.target.value);
                          }}
                        />
                        <input
                          type="number"
                          placeholder="Max"
                          min={0}
                          max={100000}
                          className="w-50 input-field myfont-white"
                          value={maxPrice}
                          onChange={(e) => {
                            setMaxPrice(+e.target.value);
                          }}
                        />
                      </div>
                    </Dropdown>
                  </div>
                </div>

                <div className="submit-btn" onClick={handleSeach}>
                  <button>Search</button>
                </div>
              </div>

              {/* grid */}

              {loading ? (
                <div className="d-flex flex-row w-100 justify-content-center align-items-center">
                  <Loader variant="warning" />
                </div>
              ) : (
                <div
                  style={{
                    height: "70vh",
                    overflowY: "auto",
                  }}
                  className="d-flex flex-row flex-wrap justify-content-start w-75 p-3 gap-4"
                >
                  {arr.map((element, index) => {
                    return (
                      <div
                        key={index}
                        className="d-flex flex-column list-item align-items-center col-sm-12 col-md-6 col-lg-3"
                      >
                        <img
                          src={defaultitem}
                          width={"100px"}
                          height={"auto"}
                        />
                        <div className="w-100 d-flex flex-column justify-content-start p-3">
                          <p className="myfont p-0 m-0 text-start">
                            #{element.tokenId}
                          </p>
                          <p className="myfont-white p-0 m-0 text-start">
                            {element.name}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <LoginScreen />
      )}
    </>
  );
}
