import React from "react";
import "../../../Inventory/showEquipmentModal.css";
import "./ChestList.css";
import { Chest } from "../../../../utils/types";
import ChestListItem from "./ChestListItem/ChestListItem";
import { RaritiesEnum } from "../../../../utils/enums";
type Props = {
  setSelected: any;
  chests: Array<Chest>;
  rarity: RaritiesEnum;
};
export const ChestList: React.FC<Props> = ({ setSelected, rarity, chests }) => {
  return (
    <div className="d-flex flex-column align-items-center p-3 w-100">
      {chests.length > 0 ? (
        <ChestListItem
          chests={chests}
          rarity={rarity}
          setSelected={setSelected}
        />
      ) : // (
      //   <>
      //     {chests.map((element: Chest, index: number) => {
      //       return (
      //         <>
      //           <ChestListItem
      //             chests={chests}
      //             element={element}
      //             key={index}
      //             setSelected={setSelected}
      //           />
      //         </>
      //       );
      //     })}
      //   </>
      // )
      null}
    </div>
  );
};
