import { GET, PATCH, POST } from "../utils/axios.http";
import { ApiUrls, AxiosService } from "../services/axios.service";
import { local as env, local } from "../lib/env";
import { ItemsEnum } from "../utils/enums";
class EquipmentsApiClient extends AxiosService {
  filters: any;
  constructor() {
    super({ url: ApiUrls.Equipments });
    this.filters = {
      he: "helmets",
      ar: "armors",
      sw: "swords",
    };
  }

  async upgradeItem(params: any): Promise<any> {
    try {
      return await PATCH(
        env.HOST + "/" + ApiUrls.Equipments + "/payment-confirmend",
        params,
      );
    } catch (error) {
      //console.log(error);
      return null;
    }
  }
  async allItems(params: any): Promise<any> {
    try {
      return await GET(env.HOST + "/" + ApiUrls.Equipments + "/allItems", params);
    } catch (error) {
      //console.log(error);
      return null;
    }
  }
  async allEquipedItems(params: any): Promise<any> {
    try {
      return await PATCH(env.HOST + "/" + ApiUrls.Equipments + "/getAllEquipedItems", params);
    } catch (error) {
      //console.log(error);
    }
  }
  async itemDetails(params: any): Promise<any> {
    try {
      return await PATCH(
        env.HOST + "/" + ApiUrls.Equipments + "/itemDetails",
        params,
      );
    } catch (error) {
      //console.log(error);
      return null;
    }
  }
  async upgradeItem_1(params: any): Promise<any> {
    try {
      return await PATCH(
        env.HOST + "/" + ApiUrls.Equipments + "/upgradeItem",
        params,
      );
    } catch (error) {
      //console.log(error);
      return null;
    }
  }
  async getAwsItem(filter: "he" | "ar" | "sw", itemNumber: string) {
    itemNumber = itemNumber.toString();
    let itemNumberString = itemNumber.toString();
    //console.log("itemNumberString : " + { itemNumberString });
    if (!itemNumber || itemNumber == undefined) {
      return;
    }
    itemNumber = itemNumber.replace("sw", "");
    itemNumber = itemNumber.replace("ar", "");
    itemNumber = itemNumber.replace("he", "");
    itemNumber.trim();
    const baseUrl = "https://nftgame-characters.s3.amazonaws.com/";
    let url: string =
      baseUrl + this.filters[filter] + "-metadata/" + itemNumber;
    //console.log({ url });
    const response = await GET(url);
    return await response;
  }

  async sign(params: any): Promise<any> {
    return GET(local.HOST + "/" + ApiUrls.Equipments + "/sign", params);
  }

  async mint(data: { type: ItemsEnum; hash: string }): Promise<any> {
    //console.log({ ...data });
    return POST(local.HOST + "/" + ApiUrls.Equipments + "/mint", data);
  }

  // async function mintNewArmorAPI(transactionHash: string): Promise<string> {
  //   return await GetDataAxios(env.HOST + "/items/mintNewItem/armor/" + transactionHash);
  // }
  // export async function mintNewSwordAPI(transactionHash: string): Promise<string> {
  //   return await GetDataAxios(env.HOST + "/items/mintNewItem/sword/" + transactionHash);
  // }
  // export async function mintNewHelmetAPI(
  //   transactionHash: string
  // ): Promise<string> {
  //   return await GetDataAxios(
  //     env.HOST + "/items/mintNewItem/helmet/" + transactionHash
  //   );
  // }
}

const EquipmentsApi = new EquipmentsApiClient();
export { EquipmentsApi };
