import React from "react";
import {
  MintNFT,
  armorQ,
  attack,
  weaponQ,
} from "../../../../services/assets.service";
import "./PerkTile.css";
import { LoaderProps } from "../../../../components/Loader/Loader";

type Props = {
  data: any;
};

export default function PerkTile({ data }: Props) {
  let itemsIcons = [attack, weaponQ, armorQ];

  return (
    <div className="perkItem p-0 m-0 cursor-pointer">
      {itemsIcons[0] == null ? (
        <img src={MintNFT} alt="" />
      ) : (
        <div className="perkIconBorder d-flex w-100 h-100 align-items-center justify-content-center">
          <img src={itemsIcons[0]} alt="" />
          <div className="perkLevel d-flex w-100 h-100 justify-content-end">
            <p className="myfont">{data?.level}</p>
          </div>
        </div>
      )}
    </div>
  );
}
