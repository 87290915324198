import { GET, PATCH, POST } from "../utils/axios.http";
import { local as env, local } from "../lib/env";
import { ApiUrls, AxiosService } from "../services/axios.service";

class QuestsApiClient extends AxiosService {
  constructor() {
    super({ url: ApiUrls.Quests });
  }

  async start({
    nftTokenId,
    walletAddress,
    toChain,
    requiredTime,
    startHash,
  }: {
    nftTokenId: string;
    walletAddress: string;
    startHash: string;
    toChain: string;
    requiredTime: any;
  }): Promise<any> {
    try {
      return await PATCH(env.HOST + "/start", {
        nftTokenId,
        walletAddress,
        startHash: startHash,
        toChain: toChain.toUpperCase(),
        requiredTime,
      });
    } catch (error) {
      //console.log(error);
    }
  }
  async setStartTime(params: any): Promise<any> {
    try {
      return await PATCH(local.HOST + "/quests" + "/setStartTime", params);
    } catch (error) {
      //console.log(error);
    }
  }
  async setQuestClaimed(params: any): Promise<any> {
    try {
      return await PATCH(local.HOST + "/quests" + "/setQuestClaimed", params);
    } catch (error) {
      //console.log(error);
    }
  }
  async checkQuestComplete(params: any): Promise<any> {
    try {
      return await PATCH(local.HOST + "/quests" + "/checkQuestComplete", params)
    } catch (error) {
      console.log(error);
    }
  }
  async end({
    nftTokenId,
    walletAddress,
    startHash,
  }: {
    nftTokenId: string;
    walletAddress: string;
    startHash: string;
  }): Promise<any> {
    try {
      return await await PATCH(env.HOST + "/end", {
        nftTokenId,
        walletAddress,
        startHash: startHash,
      });
    } catch (error) {
      //console.log(error);
    }
  }

  async todayQuests(params: any): Promise<any> {
    return GET(local.HOST + "/todayquests", params);
  }
}

const QuestsApi = new QuestsApiClient();
export { QuestsApi };
