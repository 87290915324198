import React, { useState } from "react";
import "../../Inventory/showEquipmentModal.css";
import "./ChestModal.css";
import { ApiService } from "../../../services/apis.service";
import {
  cross,
  coins,
  attSymbol,
  weapon,
  xpcoin,
  expIcon,
} from "../../../services/assets.service";
import { Chest } from "../../../utils/types";
import { useDispatch, useSelector } from "react-redux";
import { setRefreshChests } from "../../../store/slices/refresh.slice";
import { ChestListComponent } from "./ChestListComponent/ChestListComponent";
import { RootState } from "../../../store/store";
import { ContractsEnum } from "../../../utils/enums";
import { toast } from "react-toastify";
type Props = {
  setShowSelected: React.Dispatch<React.SetStateAction<boolean>>;
  chests: any;
};
export const ChestModal: React.FC<Props> = ({ setShowSelected, chests }) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState<Chest | undefined>(undefined);

  const selectedCharacter = useSelector(
    (state: RootState) => state.nftCharacter.selectedCharacter,
  );

  const handleClick = async () => {
    //console.log("here");
    const contract = await ApiService.ContractsApi.getOne({
      forWhat: ContractsEnum.CHARACTERS,
    });

    if (
      selectedCharacter == null ||
      selectedCharacter == undefined ||
      contract == null ||
      contract == undefined
    ) {
      //console.log("Missing Some Parameters To Get Current Level");
      toast("Missing Some Parameters To Get Current Level", { theme: "dark" })
      return;
    }
    var response = await ApiService.CharactersApi.getOne({
      tokenId: selectedCharacter,
      characterId: contract?.contract?.CONTRACT + selectedCharacter,
    });
    //console.log(response["level"]);
    var nextLevelPreReqs = await ApiService.CharactersApi.nextLevelPreReq({
      currentLevel: response["level"],
    });
    //console.log(nextLevelPreReqs);

    if (
      ConditionsMet(
        nextLevelPreReqs["Attack"],
        nextLevelPreReqs["Defence"],
        nextLevelPreReqs["XP"],
        response["xpnet"],
        response["defence"],
        response["attack"],
      )
    ) {
      var incementLevel =
        await ApiService.CharactersApi.incrementCharacterLevel({
          characterId: contract?.contract?.CONTRACT + selectedCharacter,
          level: response["level"] + 1,
        });
      toast.success("Level incremented successfully", { theme: "dark" });
      //console.log(incementLevel);
    }
    else {
      toast("Requirements not fulfilled for chest open", { theme: "dark" });
      return;
    }
    dispatch(setRefreshChests((prev: any) => prev++));
    try {
      await ApiService.ChestsApi.open({ _id: selected?._id }).then((response) => {
        //console.log({ response });
        dispatch(setRefreshChests((prev: any) => prev++));
      });
      setShowSelected(false);
    } catch (error) {
      toast.error("Error in opening chest", { theme: "dark" });
      //console.log(error);
    }
  };
  const ConditionsMet = (
    requiredAttack: number,
    requiredDefence: number,
    requiredXP: number,
    currentXP: number,
    currentDefence: number,
    currentAttack: number,
  ) => {
    const attackCheck: boolean = currentAttack >= requiredAttack ? true : false;
    const defenceCheck: boolean =
      currentDefence >= requiredDefence ? true : false;
    const xpCheck: boolean =
      (currentXP + (selected?.rewards.xpnet ?? 0)) >= requiredXP ? true : false;
    if (attackCheck && defenceCheck && xpCheck) {
      return true;
    } else {
      if (!attackCheck) {
        toast("You don't have enough attack points for upgarde!", { theme: "dark" });
        return false;
      }
      if (!defenceCheck) {
        toast("You don't have enough defence points for upgarde!", { theme: "dark" });
        return false;
      }
      if (!currentXP) {
        toast("You don't have enough xpnets for upgarde!", { theme: "dark" });
        return false;
      }
    }
  };
  return (
    <>
      <div className="chest-modal-backdrop d-flex flex-row justify-content-center align-items-center">
        <div className="chest-modal-container d-flex flex-column justify-content-start">
          <div className="top-section d-flex flex-column align-items-center w-100">
            <p className="chest-modal-heading">Chests</p>
            <div
              className="d-flex flex-row justify-content-end w-75"
              onClick={() => {
                setShowSelected(false);
              }}
            >
              <img src={cross} alt="" className="cursor-pointer mt-3" />
            </div>
          </div>

          <div className="chest-modal-body d-flex flex-row justify-content-center flex-wrap w-100">
            {selected ? (
              <>
                <div className="section w-100">
                  <p className="section-title">Rewards</p>
                  <div className="d-flex flex-row justify-content-evenly flex-wrap align-items-center">
                    <div className="section-item">
                      <img src={expIcon} alt="coins" />
                      <div>
                        <p className="myfont-white">
                          Experience: {selected.rewards.experience}
                        </p>
                      </div>
                    </div>
                    <div className="section-item">
                      <img src={xpcoin} alt="coins" />
                      <div>
                        <p className="myfont-white">
                          XP Nets: {selected.rewards.xpnet}
                        </p>
                      </div>
                    </div>
                    <div className="section-item">
                      <div>
                        <img src={coins} alt="coins" />
                      </div>
                      <div>
                        <p className="myfont-white">
                          Item:
                          {/* {selected.rewards.item} */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="section w-100">
                  <p className="section-title">Upgrades</p>
                  <div className="d-flex flex-row justify-content-evenly align-items-center">
                    <div className="section-item">
                      <img
                        src={attSymbol}
                        alt="coins"
                        width="100px"
                        height="auto"
                      />
                      <p>+48</p>
                    </div>
                    <div className="section-item">
                      <img src={weapon} alt="coins" />
                    </div>
                  </div>
                </div>

                <div className="section w-100">
                  <button className="collectButton" onClick={handleClick}>
                    Collect
                  </button>
                </div>
              </>
            ) : (
              // <ChestList chests={chests} setSelected={setSelected} />
              <ChestListComponent chests={chests} setSelected={setSelected} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
