import React from "react";
import { filledStar, unfilledStar } from "../../../services/assets.service";
interface Props {
  number: number;
  startHeight?: number;
  startWidth?: number;
}

export const Rating: React.FC<Props> = ({
  number,
  startHeight,
  startWidth,
}) => {
  let arr: Array<number> = [];
  if (number) {
    for (let i = 1; i <= number; i++) {
      arr.push(1);
    }
    while (arr.length <= 4) {
      arr.push(0);
    }
  }
  return (
    <div className="flex flex-row align-items-center px-2">
      {arr.map((element: number, index: number) => {
        return (
          <img
            key={index}
            alt=""
            src={element === 1 ? filledStar : unfilledStar}
            style={{ width: startHeight, height: startWidth }}
          />
        );
      })}
    </div>
  );
};
