import Web3, { Transaction, TransactionReceipt } from "web3";
import { local as env } from "../../lib/env";
import { abi } from "../../lib/MainNetTestNet/abi";
import { getChain } from "../../utils/handleschains";
import { CONTRACTS } from "../../utils/types";
import { paymentForMint } from "../../lib/MainNetTestNet/values";
import { ApiService } from "../../services/apis.service";
import { ChainIdEnum, ContractsEnum, ItemsEnum } from "../../utils/enums";
import { Web3Service } from "../../services/web3.service";
import axios from "axios";
import _ from "lodash";
import { GET, POST } from "../../utils/axios.http";
import { Contracts } from "../../services/contstants.service";
import { Abis } from "../../services/abis.service";
import { EquipmentsApi } from "../../apis/equipments.api";
import { toast } from "react-toastify";
import { setOpenLoader } from "../../store/slices/general.slice";
import { useDispatch } from "react-redux";
import { store } from "../../store/store";
export const mintNewSword = async (
  address: string,
  item: string,
  rarity: string,
) => {
  // const swordjson:CONTRACTS = await getSwordsContract();
  const selectedContract = await _.find(Object.values(ContractsEnum), (value) =>
    value.includes(item.toUpperCase()),
  );
  if (selectedContract == undefined || selectedContract == null) {
    return;
  }
  const swordjson: CONTRACTS = await ApiService.ContractsApi.getOne({
    forWhat: selectedContract,
  });

  const contractnumber: string = swordjson.contract.CONTRACT;
  const contractChain: string = swordjson.contract.CHAIN.toString();
  const web3: Web3 = new Web3(window.ethereum);
  const currentChain: number = web3.utils.toNumber(
    await web3.eth.getChainId(),
  ) as number;
  // const web3Instance = new Web3Service().getWeb3Instance({
  //   initializer: window.ethereum,
  // });
  //   const myContract: Contract = new web3.eth.Contract(abi, contractnumber);
  const myContract: any = await new Web3Service().getContract({
    initializer: window.ethereum,
    abi: abi,
    contractId: contractnumber,
  });
  const web3Service = new Web3Service();
  //   const swordchainid: number = chainid[contractchain];
  return new Promise(async (resolve, reject) => {
    if (currentChain === +contractChain) {
      //console.log("hhh");
      const result = await Mint();
      resolve(result)
      // .finally(() => {
      //   window.dispatchEvent(new Event("changesmade"));
      // });
    } else if (currentChain !== +contractChain) {
      getChain(contractChain).then(async () => {
        //console.log("hhh");
        const result = await Mint();
        resolve(result);
        //   .finally(() => {
        //     window.dispatchEvent(new Event("changesmade"));
        //   });
      });
    }
  });
  // async function Mint() {
  //   // const { address } = store.getState().user;
  //   const response = await myContract.methods.safeMint(address);
  //   //console.log("contract response -> ", response);
  //   const encoded: string = await response.encodeABI();
  //   const strEther: number = paymentForMint;
  //   var tx: Transaction = {
  //     from: address,
  //     value: strEther,
  //     to: contractnumber,
  //     data: response,
  //   };
  //   try {
  //     //   const mygas: number = await web3.eth.estimateGas(tx);

  //     try {
  //       const mygas: number = web3.utils.toNumber(
  //         await new Web3Service()
  //           .getWeb3Instance({ initializer: window.ethereum })
  //           .eth.estimateGas(tx)
  //       ) as number;
  //       var txf: Transaction = {
  //         from: address,
  //         gas: await mygas,
  //         value: strEther,
  //         to: contractnumber,
  //         data: encoded,
  //       };

  //       try {
  //         const instance = new Web3Service();
  //         const receipt: TransactionReceipt = await instance.sendTransaction({
  //           // initializer: window.ethereum,
  //           transaction: txf,
  //         });
  //         // const sendHash: TransactionReceipt = await  sendTransaction(
  //         //   txf
  //         // );
  //         if (await receipt?.status) {
  //           // mintNewSwordAPI(sendHash.transactionHash).then((response: any) => {});
  //           axios
  //             .post(
  //               "http://localhost:8000/equipments/mint",
  //               { type: ItemsEnum.sword, hash: receipt.transactionHash }
  //               // { data: { type: ItemsEnum.sword, hash: sendHash.transactionHash } }
  //             )
  //             .then((response) => {
  //               //console.log(response.status);
  //             });
  //           // ApiService.EquipmentsApi.mint({
  //           //   type: ItemsEnum.sword,
  //           //   hash: sendHash.transactionHash.toString(),
  //           // });
  //         }
  //       } catch (error) {
  //         //console.log("error 1: ", error);
  //       }
  //     } catch (error) {
  //       //console.log("error 2: ", error);
  //     }
  //   } catch (e) {
  //     //console.log("payment fail!: ", e);
  //     // //console.log(e);
  //   }
  // }

  async function Mint() {
    //console.log("gggg");
    let data = null;
    const myContract: any = web3Service.getContract({
      abi: abi,
      contractId: contractnumber,
    });
    const encoded: string = await myContract.methods
      .safeMint(address)
      .encodeABI();
    const strEther: number = paymentForMint;
    var tx: Transaction = {
      from: address,
      value: strEther,
      to: contractnumber,
      data: encoded,
    };

    //console.log({ encoded });

    try {
      const mygas: number | undefined = await web3Service.estimateGas({
        transaction: tx,
      });
      var txf: Transaction = { ...tx, gas: mygas };
      const receipt: TransactionReceipt = await web3Service.sendTransaction({
        transaction: txf,
      });
      if (Number(receipt.status) == 1) {
        //console.log(receipt.status);
        // await mintNewCharacter(receipt.transactionHash);

        // return await updateCharacterQuests(sendHash.transactionHash); //IRRELEVANT
        try {
          const response = await POST(
            //"http://localhost:8000/equipments/mint",
            "https://tools.xp.network/game/equipments/mint",
            {
              type: item,
              selectedRarity: rarity,
              walletAddress: address,
              hash: receipt.transactionHash,
            },
            // { data: { type: ItemsEnum.sword, hash: sendHash.transactionHash } }
          )
          //console.log(response);
          data = response;
        } catch (error) {
          console.log(error);
          store.dispatch(setOpenLoader(false));
          data = null;
        }
      }
      else {
        console.log("not minted : ", item);
      }
    } catch (e) {
      //console.log("payment fail!", e);
      store.dispatch(setOpenLoader(false));
      data = null;
    }
    return data;
  };
};
export const UpgradeItemXP = async (
  rarity: string,
  level: number,
  type: string,
  tokenId: number,
  requiredXpnetForNextLevel: number,
  from: string,
  characterId: string,
  shortname: string,
) => {
  const web3Service = new Web3Service();
  let upgradeResponse: any;
  const xpnettokens: string = await GET(
    env.HOST + "/contracts/getxprewardscontract",
  );
  let booleanrewards: Number = 0;
  let confirmedhash: string;
  const web3c = web3Service.getWeb3Instance({});
  const currentchain = await web3c.eth.getChainId();

  if (Number(currentchain) === ChainIdEnum.BSC) {
    console.log(
      "Calling XPNET CONTRACT TO ALLOW XPREWARDS CONTRACT TO GET XPNET TOKENS",
    );
    const increasedAllowance = await getAllowance(
      xpnettokens,
      requiredXpnetForNextLevel,
      from,
    );
    if (increasedAllowance === 1) {
      //console.log("its true");
      console.log("GOT THE ALLOWANCE NOW CALLING XPREWARDS CONTRACT");
      const functioncall = await upgradeitemXPrewards(
        xpnettokens,
        requiredXpnetForNextLevel,
        from,
        type,
        level,
        rarity,
      );
      booleanrewards = functioncall[0];
      if (booleanrewards === 1) {
        confirmedhash = functioncall[1];
        console.log(
          "YUP THE CONTRACT GOT THE REQUIRED XPNETS FOR ITEM UPGRADE ",
        );
        upgradeResponse = await EquipmentsApi.upgradeItem_1({
          walletaddress: from,
          shortname: shortname,
          xpnet: requiredXpnetForNextLevel,
          characterId: characterId,
        });
        //console.log(upgradeResponse);
      } else {
        console.log(
          "SOME ERROR CONTRACT DIDNOT GET REQUIRED TOKENS FOR ITEM UPGRADE",
        );
        toast("Error /Not Enough Currency to Upgrade!", { theme: "dark" });
        store.dispatch(setOpenLoader(false));
      }
    } else {
      //console.log("its false");
      store.dispatch(setOpenLoader(false));
    }
  } else if (Number(currentchain) !== ChainIdEnum.BSC) {
    await getChain("BSC").then(async () => {
      console.log(
        "Calling XPNET CONTRACT TO ALLOW XPREWARDS CONTRACT TO GET XPNET TOKENS",
      );
      const increasedAllowance = await getAllowance(
        xpnettokens,
        requiredXpnetForNextLevel,
        from,
      );
      if (increasedAllowance === 1) {
        //console.log("its true");
        //console.log("GOT THE ALLOWANCE NOW CALLING XPREWARDS CONTRACT");
        const functioncall = await upgradeitemXPrewards(
          xpnettokens,
          requiredXpnetForNextLevel,
          from,
          type,
          level,
          rarity,
        );
        booleanrewards = functioncall[0];
        if (booleanrewards === 1) {
          confirmedhash = functioncall[1];
          console.log(
            "YUP THE CONTRACT GOT THE REQUIRED XPNETS FOR ITEM UPGRADE ",
          );
          upgradeResponse = await EquipmentsApi.upgradeItem_1({
            walletaddress: from,
            shortname: shortname,
            xpnet: requiredXpnetForNextLevel,
            characterId: characterId,
          });
          //console.log(upgradeResponse);
        } else {
          console.log(
            "SOME ERROR CONTRACT DIDNOT GET REQUIRED TOKENS FOR ITEM UPGRADE",
          );
          toast("Error /Not Enough Currency to Upgrade!", { theme: "dark" });
          store.dispatch(setOpenLoader(false));
        }
      } else {
        store.dispatch(setOpenLoader(false));
        //console.log("its false");
      }
    });
  }
  return upgradeResponse;
};
async function getAllowance(
  xpnettokens: string,
  requiredxpnetfornextlevel: number,
  from: string,
): Promise<any> {
  // const web3 = web3Service.getWeb3Instance({});
  try {

    const web3Service = new Web3Service();
    const tokenscontract: any = web3Service.getContract({
      initializer: window.ethereum,
      abi: Abis.XPNET_TOKENS,
      contractId: Contracts.XPNET_TOKENS,
    });
    //tokenscontract.methods.increaseAllowance("");

    const encoded: string = await tokenscontract.methods
      .increaseAllowance(
        xpnettokens,
        web3Service
          .getWeb3Instance(window.ethereum)
          .utils.toWei(
            web3Service
              .getWeb3Instance(window.ethereum)
              .utils.toNumber(requiredxpnetfornextlevel),
            "Gwei",
          ),
      )
      .encodeABI();
    var tx = {
      from: from,
      data: encoded,
      to: Contracts.XPNET_TOKENS,
    };
    const mygas = await Promise.resolve(web3Service.estimateGas({ transaction: tx }));
    var txf = { gas: mygas, ...tx };
    const booleanv = await Promise.resolve(web3Service.sendTransaction({ transaction: txf }));
    return Number(booleanv.status);
  } catch (error) {
    store.dispatch(setOpenLoader(false));
  }
}
async function upgradeitemXPrewards(
  xpnettokens: string,
  requiredxpnetfornextlevel: number,
  from: string,
  type: string,
  level: number,
  rarity: string,
): Promise<[Number, string]> {
  try {

    const web3Service = new Web3Service();
    const selectedContract = _.find(Object.values(ContractsEnum), (value) =>
      value.includes(type.toUpperCase()),
    );
    if (selectedContract == undefined || selectedContract == null) {
      return [0, ""];
    }
    const swordjson: CONTRACTS = await ApiService.ContractsApi.getOne({
      forWhat: selectedContract,
    });
    //console.log(swordjson?.contract?.CONTRACT);
    const contractnumber: string = swordjson?.contract?.CONTRACT;
    const contractChain: string = swordjson?.contract?.CHAIN.toString();
    const web3: Web3 = new Web3(window.ethereum);
    const currentChain: number = web3.utils.toNumber(
      await web3.eth.getChainId(),
    ) as number;
    await getChain(contractChain).then(async () => {
      //   .finally(() => {
      //     window.dispatchEvent(new Event("changesmade"));
      //   });
    });
    const contract: any = await web3Service.getContract({
      initializer: window.ethereum,
      abi: Abis.XP_REWARDS,
      contractId: xpnettokens,
    });
    console.log(contract);
    const signedhash = await ApiService.EquipmentsApi.sign({
      level,
      type,
      rarity,
      xpnet: requiredxpnetfornextlevel,
    });
    const hash = signedhash.messagehash;
    const signature = signedhash.signature;
    const s = {
      hash,
      signature,
    };
    //console.log(signedhash);
    const encoded: string = await contract.methods
      .upgradeItem(requiredxpnetfornextlevel, s)
      .encodeABI();
    const tx: Transaction = {
      from: from,
      data: encoded,
      to: xpnettokens,
    };
    console.log(from, xpnettokens);
    //console.log(tx);
    try {

      const booleanv = await web3Service.sendTransaction({ transaction: tx });
      //console.log(booleanv);
      const confirmhash = booleanv?.transactionHash;
      //console.log(booleanv.status);
      return [Number(booleanv.status), confirmhash];
    } catch (error) {
      store.dispatch(setOpenLoader(false));
      return [0, ""];
    }
  } catch (error) {
    store.dispatch(setOpenLoader(false));
    return [0, ""];
  }
}

