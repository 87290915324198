import React, { useEffect, useState } from "react";
import "./Notice.css";
import { BsInfoCircleFill } from "react-icons/bs";

type Props = {
  text: string;
};

export default function Notice({ text }: Props) {
  const [show, setShow] = useState<boolean>(false);
  useEffect(() => {
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 1000);
  }, []);
  return (
    <>
      {show ? (
        <div className="notice d-flex flex-row justify-content-start align-items-center">
          <BsInfoCircleFill color="white" size={25} />
          <p
            className="myfont-white m-0 p-0 px-3"
            style={{ whiteSpace: "nowrap" }}
          >
            {text}
          </p>
        </div>
      ) : null}
    </>
  );
}
